export const METRIC = "metric";
export const IMPERIAL = "US";
export const SYSTEMS = [IMPERIAL, METRIC];

// We may get more specific later...
export type UnitLabel = string;

export enum SystemOfMeasure {
  Metric = "metric",
  Imperial = "us"
};

export interface AtomicUnit {
  isProviderPreferred?: boolean;
  isTuple?: false;
  unit: UnitLabel;
  unitLong: string;
  default?: number;
  conversionFactor: number;
  max?: number;
  min?: number;
  listExhaustively?: boolean;
};

export interface SubdividedUnit {
  isProviderPreferred?: boolean;
  isTuple: true;
  unit: UnitLabel[];
  unitLong: string[];
  default?: number[];
  conversionFactor: number[];
  max?: number[];
  min?: number[];
  listExhaustively?: boolean[];
};

export type Unit = AtomicUnit | SubdividedUnit;

export function toAtomArray (unit : Unit) : AtomicUnit[] {
  if (unit.isTuple) {
    return unit.unit.map((unitLabel, i) => {
      return ({
        unit: unitLabel,
        unitLong: unit.unitLong?.[i],
        max: unit.max?.[i],
        min: unit.min?.[i],
        listExhaustively: unit.listExhaustively?.[i],
        conversionFactor: unit.conversionFactor?.[i]
      });
    })
  } else {
    return [{...unit}];
  }
}

export type Dimension = {
  [METRIC]?: Unit,
  [IMPERIAL]?: Unit,
}

export interface NumberAndUnitType {
  value: number;
  unit: UnitLabel;
  index: number;
};

import React, { useMemo } from "react";
import { toggleChoice, isSelected} from "../../../../models/choices";
import { ImageWithFallback } from "../../../UI-old/ImageWithFallback";
import { JSObjectDump } from "../../../../utils";
import { Card } from "../../../UI/Card/Card";

function SqrImgCard({ item, handleClick, handleIconClick, isSelected, style = {} }) {
  return (
    <Card isSelected={isSelected} onClick={() => handleClick(item, isSelected)} art={item.imgUrl} title={item.label} description={item.subtext || item.description} />
  );
}

export default function GridResponse({
  questionDefinition,
  answer,
  updateAnswer,
  hiddenChoices = [],
  showTrackingInfo = false,
  exclusionaryAnswersInline = true,
  displayInfoModal
}) {

  function handleClick(item, isCurrentlySelected) {
    console.log({ isCurrentlySelected });
    if (Array.isArray(questionDefinition?.exclusionaryChoices) && 
      questionDefinition.exclusionaryChoices.includes(item)) {
        return handleExclusionary(item);
      }
    const updatedAnswerObj = toggleChoice(
      item,
      isCurrentlySelected,
      questionDefinition.choices,
      questionDefinition.isMulti,
      answer,
      {id: questionDefinition.id}
    );
    updateAnswer(updatedAnswerObj);
  }

  function handleExclusionary (item) {
    const updatedAnswerObj = toggleChoice(
      item,
      (answer.value?.value === item.value),
      questionDefinition.exclusionaryChoices,
      false,
      answer,
      {
        id: questionDefinition.id,
        isExclusionary: true
      });
    updateAnswer(updatedAnswerObj);
  }

  const anyHasDisplayLogic = questionDefinition.choices.some(c => c.displayWhen || c.skipWhen);

  const shownChoices = useMemo(() => {
    if (!questionDefinition) {
      return [];
    }
    if (showTrackingInfo) {
      return questionDefinition.choices;
    }
    return questionDefinition.choices.filter((choice, index) => !hiddenChoices.includes(index) || isSelected(index, questionDefinition, answer));
  }, [questionDefinition, hiddenChoices, answer, showTrackingInfo])

  const responses = shownChoices.map((choice, index) => (
    <>
    <Card
      key={choice.label}
      isSelected={isSelected(index, questionDefinition, answer)}
      onClick={() => handleClick(choice, isSelected)}
      art={choice.imgUrl}
      title={choice.label}
      description={choice.subtext || choice.description} 
      style={hiddenChoices.includes(index) ? {opacity: 0.3} : {}}
    />
    {/* handleIconClick={() => displayInfoModal(choice)} */}
    {showTrackingInfo && anyHasDisplayLogic ? 
      <div>
      {hiddenChoices.includes(index) ? <span className="badge badge-danger mr-1">hidden</span> : <span className="badge badge-success mr-1">shown</span>}
      {choice.displayWhen ? <><label>#{index} displayWhen: </label><JSObjectDump obj={choice.displayWhen} style={{maxHeight: "300px"}}/></> : null}
      {choice.skipWhen ? <><label>#{index} skipWhen: </label><JSObjectDump obj={choice.skipWhen} style={{maxHeight: "300px"}}/></> : null}
      {(!choice.displayWhen && !choice.skipWhen) ? <em>#{index} (no display logic)</em> : null}
      </div>
    : null}
    </>
  ));

  const exclusionaries = (exclusionaryAnswersInline && Array.isArray(questionDefinition?.exclusionaryChoices) ? 
    questionDefinition.exclusionaryChoices.map((choice, index) => (
      <>
      <SqrImgCard
        key={choice.label}
        item={choice}
        handleClick={handleExclusionary}
        handleIconClick={() => displayInfoModal(choice)}
        isSelected={answer?.value?.value === choice.value}
        style={{}}
      />
      {showTrackingInfo && anyHasDisplayLogic ?
      <div><span className="badge badge-dark mr-1">exclusionary</span> #{index}</div> : null}
    </>))
  : null);

  const cardCount = useMemo(() => {
    return shownChoices.length + (Array.isArray(questionDefinition?.exclusionaryChoices) ? questionDefinition.exclusionaryChoices.length : 0);
  }, [shownChoices, questionDefinition])

  return <div className="main-column" style={{margin: "auto"}}>
    <div className={`grid-of-cards count-${cardCount}`}>{responses}{exclusionaries}</div>
  </div>;
}
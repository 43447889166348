export const COMPLEX_UI_TYPES = [""]
export const AUTO_ADVANCE_TYPES = ["cards", "list", "stack"];
// const AUTO_ADVANCE_ENABLED = false;
export const AUTO_ADVANCE_TIMEOUT = 1200;
export const ALLOW_BACKDROP_TO_CLOSE_MODAL = true;
export const KNOWN_PAGE_TYPES = [
  "loading",
  "error",
  "debug",
  "title",
  "question",
  "question-modal",
  "question-unique",
  // "loop_summary",
  "review",
  "success",
  "kiosk_start",
  "kiosk_consent",
  "language-selector",
];


export const EMPTY_ANSWER = {};
Object.preventExtensions(EMPTY_ANSWER);


export const CONSENT_MODAL = `**Project Title:**  A Novel Approach to Addressing Emergency Department Disparities Among Limited English Proficiency Patients.
 
 
**Valleywise Health IRB #** ____

**Principal Investigator:** Lilya Kraynov
 
 
# Introduction
You are invited to take part in a research study.  This study is aims to look at a better way to gather health information prior to seeing your doctor through the use of a software known as LiteraSeed. Often, it is difficult and time consuming to convey your reason for coming in today through an interpreter.   You are being asked to take part because we are interested in a new solution to gathering your information in your native language.  This research is being done by Dr. Lilya Kraynov, from the emergency department at Valleywise Health Medical Center.

## What is LiteraSeed?
LiteraSeed is a software that enables patients to interact in their native language through audio and visual assistance to complete a form to inform the doctor they are about to see as to the reason they have come in to the emergency department today. We hope this software will enable you to express your concerns to the doctor with more ease and efficiency.

## Why is this study being done?
The purpose of this research study is to understand  the accuracy of the LiterSeed software in capturing health information.  The study also wants to understand the effect of LiteraSeed on treatment and health outcomes.  About ___ women and men will take part in this study at Valleywise.  The Valleywise Emergency Department will use this information to better understand the application of this software and its potential benefits.

## What are the study procedures? What will I be asked to do?
If you agree to take part in this study, you will go through normal nursing triage, but also complete the questions in LiteraSeed.  The questionnaires will ask similar questions to what your nurse will ask you.  The information gathered through LiteraSeed will only be accessed by your doctor and research staff.   

You will be asked questions regarding the condition that brought you to the emergency department today, your medical history, and your social history

## What other options are there?
You can choose not to take part in the study.

## What are the risks or inconveniences of the study?
The information we collect will include your medical record number but not your name or birthday. After nursing information is collected from your chart, your medical record number will be removed from our database.  To the best of our ability, we will be sure the information that we collected does not get shared with others.  The information will be kept on computers that require a password.  

You can also choose to stop taking part in the study at any time.

## What are the benefits of the study?
You may not directly benefit from this research.  We hope the study will help improve the experience and outcomes of non-english speaking patients that are treated in our emergency department. 

## Are there costs to take part in this study?  Will I receive payment for participation?  
There will be no costs to you to take part in the study.  You will not be paid for being in this study.

##How will my personal information be protected?
The following procedures will be used to protect your information:
The researchers will keep all study records on a computer in a secure office.
Minimal personal identifiers will be collected. 
All electronic research records will be password protected and kept in a secure location.  Only members of the research staff will have access to the passwords.
Researchers may publish their findings.  Information will be presented in a summary and you will not be identified in any publications or presentations.
VH Institutional Review Board (IRB) may review study records as part of its auditing program.  This review will focus on the researchers and not on your responses or your involvement in this study.  The IRB is a group of people who review research studies to protect the rights and welfare of people who take part in research studies.

## Can I stop being in the study and what are my rights?
You do not have to be in this study if you do not want.  If you agree to be in the study, but later change your mind, you may stop at any time.  Being part of this study is your choice.  You do not have to answer any question that you do not want to answer.

## Whom do I contact if I have questions about the study?
Take as long as you like before you make a decision.  We will be happy to answer any questions you have about this study.  If you have further questions about this study, you may contact the study doctor, Dr. _____.  If you have any questions about your rights as a research participant, you may contact VH Institutional Review Board at 602 344-5881.
`;

/**
 * These are the custom configurations for the question-modal type pages
 * that help them enable special behavior
 */
export const MODAL_Q_CONFIGS = {
  "hospitalizations": {
    subpages: 2,
    questionsByPage: [[0, 1], []],
    finishCondition: "continue-question",
    finishParameter: "has more hospitalizations",
    q_hasMore: {
      type: "stack",
      text: "Have you been hospitalized at any other times?",
      choices: [
        {
          value: "Yes",
          label: "Yes",
          labelAudioFile: "R_GEN002",
          imgUrl: "/images/yes_o.png"
        },
        {
          value: "No",
          label: "No",
          labelAudioFile: "R_GEN003",
          imgUrl: "/images/no_x.png"
        }
      ]
    }
  },
  "surgeries": {
    subpages: 2,
    questionsByPage: [[0], [1]],
    prefix: "",
    getEffectiveSubPage: (num) => [(num < 2 ? num : 1), (num > 0 ? num - 1 : null)],
    finishCondition: "iterate-responses",
    finishParameter: ["has surgerical hx", "other surgeries"],
    q_freeAnswer: {
      type: "short answer",
      text: "Other surgeries",
      subtext: "(Enter separated by commas)",
      placeholder: "Operation 1, operation 2, etc",
      exclusionaryChoices: [] // forces appearance to be stack-like
    }
  },
  "drug use": {
    subpages: 2,
    questionsByPage: [[0], [1]],
    prefix: "",
    getEffectiveSubPage: (num) => [(num < 2 ? num : 1), (num > 0 ? num - 1 : null)],
    finishCondition: "iterate-responses",
    finishParameter: ["current or past drug use", "other drugs"],
    q_freeAnswer: {
      type: "short answer",
      text: "Other drugs",
      subtext: "(Enter separated by commas)",
      placeholder: "Drug A, drug B, etc",
      exclusionaryChoices: [] // forces appearance to be stack-like
    }
  },
  "prescriptions": {
    subpages: 1,
    questionsByPage: [[0, 1], []],
    finishCondition: "fixed"
  },    
  "vitamins supplements and probiotics": {
    subpages: 1,
    questionsByPage: [[0, 1, 2], []],
    finishCondition: "fixed"
  },
  "allergies": {
    subpages: 2,
    questionsByPage: [[0, 1, 2], []],
    finishCondition: "continue-question",
    finishParameter: "has more allergies to report",
    q_hasMore: {
      type: "stack",
      text: "Do you have any other allergies?",
      choices: [
        {
          value: "Yes",
          label: "Yes",
          labelAudioFile: "R_GEN002",
          imgUrl: "/images/yes_o.png"
        },
        {
          value: "No",
          label: "No",
          labelAudioFile: "R_GEN003",
          imgUrl: "/images/no_x.png"
        }
      ]
    }
  },
}
import { ordinals } from "../../../../utils";
import { CTA } from "../../../UI/buttons/CTA";

export const TitlePage = ({title, description, art, callToAction, actOnCTA, style, carousel, children}) => {
  function CarouselParts () {
    if (Array.isArray(carousel)) {
      const dots = ordinals(+carousel[1]);
      const c = +carousel[0];
      return <>{dots.map((d, i) => <div style={{padding: 5}}>{i+1 === c ? "⦿" : "•"}</div>)}</>;
    } else {
      return null;
    }
  }

  return (<div className="viewer main-columm" style={{minHeight: "100vh", justifyContent: "center"}}>
    <img className="title-img" src={art} />
    <h1 className="h1 text-center" style={{fontWeight: "bold"}}>{title}</h1>
    <p className="text-center" style={{maxWidth: "60%", fontSize: "20px"}}>
      {description}
      {children}
    </p>
    {carousel ? <div style={{position: "absolute", bottom: 20, width: "100%", display: "flex", justifyContent: "center", fontSize: "3rem", color: "white"}}>
      <CarouselParts/>
    </div> : null}
    {callToAction ? <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
      <CTA light={style !== 'light'} onClick={actOnCTA}>{callToAction}</CTA>
    </div> : null}
  </div>);
}

/* {minWidth: "280px", background: style === 'light' ? "#1fe191" : "", fontWeight: "bold"} */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

export const SuccessPage = ({userId, currentReport = {}}) => {
  const dispatch = useDispatch();
  const [feedbackFreeText, setFeedbackFreeText] = useState("");
  const [feedback, setFeedback] = useState();
  const [feedbackId, setFeedbackId] = useState(null); // used to display followup modal
  const feedbackHandler = (value) => {
    setFeedback(value);
  };
  const addFeedbackFollowup = () => {
    // dispatch(updateFeedback({id: feedbackId, freeText: feedbackFreeText})).then(() => {
    //   setFeedbackId(null);
    // });
  }

  const FEEDBACK_COLORS = {
    "Satisfied": "#1fe291",
    "Neutral": "rgb(32, 213, 236)",
    "Dissatisfied": "rgb(230, 121, 98)"
  };

  return  <>
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <img className="title-img" src="/images/Success_Page_2.png" />
      <h1 className="h1 text-center" style={{fontWeight: "bold"}}>Success</h1>
      <p className="text-center">Thank you for taking part in this research study. Please hand the device back. If you feel more sick, please let a nurse know.</p>
      <br/>
      </div>
      <div style={{ width: "100vw", /* position: "absolute", bottom: 0, , height: "", */ background: "white", borderRadius: "10px" }}>
      {feedback ? (<><p className="h3 text-center p-4" onDoubleClick={() => feedbackHandler(null)}>
          Thanks for your feedback!
    </p>
    {/* <Card style={{maxWidth: "30%", margin: "0px auto 20px auto", borderRadius: "12px", background: FEEDBACK_COLORS[feedback] ?? ""}}>
      <CardImg style={{width: "100%", maxWidth: "70px"}} src={`/images/Feedback_${(['Satisfied', 'Neutral', 'Dissatisfied'].indexOf(feedback) + 1)}.png`}/>
    </Card> */}
  </>) : (
      <>
      <p className="h3 text-center p-4">
        How would you rate your experience?
        {/* {" "}<PlayAudio filename={audioFileName} /> */}
      </p>
      <div style={{display: "inline-flex"}}>
        {/* <Card
        style={{margin: "24px", borderRadius: "12px", background: "#1fe291"}} 
            onClick={() => feedbackHandler("Satisfied")}
          >
          <CardImg style={{width: "100%", maxWidth: "70px"}} src="/images/Feedback_1.png" alt="smile"/>
        </Card>
        <Card
        style={{margin: "24px", borderRadius: "12px", background: "rgb(32, 213, 236)"}}
          onClick={() => feedbackHandler("Neutral")}
        >
          <CardImg style={{width: "100%", maxWidth: "70px"}} src="/images/Feedback_2.png" alt="meh"/>
        </Card>
        <Card
        style={{margin: "24px", borderRadius: "12px", background: "rgb(230, 121, 98)"}}
          onClick={() => feedbackHandler("Dissatisfied")}
        >
        <CardImg style={{width: "100%", maxWidth: "70px"}} src="/images/Feedback_3.png" alt="frown"/>
        </Card> */}
      </div>
      </>
    )}
    </div>

    <Modal show={!!feedbackId} onHide={() => setFeedbackId(null)} size="md">
      <Modal.Header closeButton style={{border: "none"}}>
        <Modal.Title className="text-dark-blue">Feedback Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr className="modal-card-hr"/>
        <br />
        <p className="mb-3">Would you like to add any comments to your feedback?</p>
        <textarea className="form-control" value={feedbackFreeText} onChange={e => setFeedbackFreeText(e.target.value)}></textarea>
      </Modal.Body>
      <Modal.Footer style={{border: "none"}}>
        <button className="btn btn-pink" onClick={() => addFeedbackFollowup()}>Submit</button>
      </Modal.Footer>
    </Modal>
  </>;

/* <Modal show={kioskWrapupModal} onHide={() => setKioskWrapupModal(false)} size="md">
    <Modal.Header closeButton>
      <Modal.Title className="text-dark-blue">Finish Kiosk Enrollment</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3>
        Local ID: 
        <span className={`mx-2 badge ${currentReport?.localId ? "badge-info" : "badge-danger"}`} style={{fontSize: "1.25rem"}}>
          {currentReport?.localId ?? "«not yet available...»"}
        </span>
        <FaUndo onClick={kioskContinue} title="Reload"/>
      </h3>
      <p className="mb-3">Please record the local ID above. When you are finished, use the button below to clear this device and restart.</p>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-success" onClick={restartKiosk}>Clear Data and Restart</button>
    </Modal.Footer>
  </Modal> */

// {activeModules.length > 0 ?
//   <Row className="mx-4 mt-3">
//   <h3>Prepare for Your Appointment</h3>
//     {activeModules.map(m =>
//     <Card
//       style={{cursor: "pointer"}}
//       className="card-horizontal"
//       onClick={() => goToModule(m)}>
//       {m.cardImage ? <img
//         src={m.cardImage}
//         className="col-md-4"
//         alt={m.cardAltText}
//       ></img> : <FaBook></FaBook>}
//       <div className="card-body col-md-8">
//         <h4 className="card-title mb-0 heading3 text-viridian-green">
//           {m.title}
//         </h4>
//         {/* <p className="text-muted small">{newsData.byline}</p> */}
//         <p>{m.description}</p>
//       </div>
//     </Card>)}
// </Row>
// : console.log("No educational content found for this user.")}
}
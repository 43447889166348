import { Switch } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Language, LanguageList, dualName, generateQuestionnaireKind } from "../../constants/locales";
import { AssumedV2Support, DevAssignable, ScreenerNames } from "../../constants/screenings";
import { useKeyboardEvents, useQuery } from "../../utils/hooks";
import { RedesignedQuestionnaire } from "./QuestionnaireRedesign/Questionnaire";
import { generateFakeAnswers, loadOrLocalQuestionnaire, randomText, requestQuestionnaire } from "../../store/slices/questionnaire-old.js";
import { alertActions } from "../../store/slices/alerts";
import moment from "moment";

export function loopIndexedAnswerId (baseId, index) {
  if (index === null) {
    return baseId;
  } else {
    return `${baseId}§${index}`;
  }
}

export const PrototypeDemoBase = () => {
  const dispatch = useDispatch();

  const query = useQuery();

  const POSITIVES = ["y", "yes", "true", "1"];
  const NEGATIVES = ["n", "no", "false", "0"];
  const SPECIAL_QUERY = {
    "assetcheck": {
      "tracking": true,
      "navlogic": false,
      "autoprogress": false,
      "nodisableprogress": true,
      "duallang": true
    }
  };
  function queryBoolean (key, fallback) {
    for (let [k, v] of Array.from(query.entries())) {
      if (k.toLocaleLowerCase() === key.toLocaleLowerCase()) {
        if (POSITIVES.includes(v.toLocaleLowerCase())) {
          return true;
        }
        if (NEGATIVES.includes(v.toLocaleLowerCase())) {
          return false;
        }
        console.warn(`Found matching key '${k}' but value '${v}' isn't boolean-like`);
        return fallback;
      } else {
        const sq = SPECIAL_QUERY[k.toLocaleLowerCase()];
        if (sq && key in sq) {
          return sq[key];
        }
      }
    }
    return fallback;
  }
  function queryLang (fallback) {
    for (let [k, v] of Array.from(query.entries())) {
      if (k.toLocaleLowerCase() === "lang") {
        if (LanguageList.includes(v.toLocaleLowerCase())) {
          return v.toLocaleLowerCase();
        }
        break;
      }
    }
    return fallback;
  }

  const kioskInjector = (pageArray, fullDfn) => {
    const addedLangAndBody = [
      {type: "language-selector"},
      {type: "kiosk_consent", "title": "Patient Consent", callToAction: "I Agree", description: "I know I'm in a research study and I agree to take part. I've told the Valleywise Health research team that I understand and want to be in the study."},
      ...pageArray
    ];
    if (simulateFullProvider) {
      return [
          {
            type: "debug",
            debugType: "login",
            style: "cool"
          },
          {
            type: "debug",
            debugType: "dashboard",
            style: "cool"
          },
          {
            type: "kiosk_start",
            title: "Start the LiteraSeed Questionnaire",
            style: "cool",
            description: "Click the button and pass the device to the patient. After they have completed it, they will be instructed to return the device to you.",
            art: "/images/vaccination.png"
          },
          ...addedLangAndBody,
          {
            type: "review",
            audience: "provider",
            reportType: "ED phase 1 summary"
          }
        ];
    }
    return addedLangAndBody;
  }

  function loadQuestionnaire (t, lg = language) {
    return dispatch(requestQuestionnaire({type: t, locale: {language: lg}, transformers: (simulateKiosk ? {pages: kioskInjector} : {})})).then((loadResult) => {
      if (loadResult.meta.requestStatus !== "fulfilled") {
        dispatch(alertActions.createAlert({
          title: `Error loading questionnaire`,
          message: JSON.stringify(loadResult.error)}));
        console.error({result: loadResult});
        return false;
      } else {
        // successful load, don't do anything else
        return true;
      }
    });
  }

  const [chosenType, setChosenType] = useState(null)
  const [chosenKind, setChosenKind] = useState(null)
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  useKeyboardEvents(["F4", () => setShowQuestionnaire(false)]);

  const [language, setLanguage] = useState(queryLang(Language.English));
  const [dualLanguage, setDualLanguage] = useState(queryBoolean("duallang", false));
  const [simulateKiosk, setSimulateKiosk] = useState(queryBoolean("kiosk", true));
  const [simulateFullProvider, setSimulateFullProvider] = useState(queryBoolean("pokm", true));
  const [debugHighlighting, setDebugHighlighting] = useState(queryBoolean("highlighting", false));
  const [trackingInfo, setTrackingInfo] = useState(queryBoolean("tracking", false));
  const [autoProgressScalars, setAutoProgressScalars] = useState(queryBoolean("autoprogress", false));
  const [noDisableProgress, setNoDisableProgress] = useState(queryBoolean("nodisableprogress", true));
  const [navLogicEnabled, setNavLogicEnabled] = useState(queryBoolean("navlogic", true));
  const [overrides, setOverrides] = useState({_bodyType: "female"});

  const available = DevAssignable.slice().sort((a, b) => a.localeCompare(b));
  const [showOutdatedTypes, setShowOutdatedTypes] = useState(false);

  const loadedQuestionnaires = useSelector(s => s.questionnaire.questionnaires.entities);
  const loadedIds = useSelector(s => s.questionnaire.questionnaires.ids);
  const loadedByType = useMemo(() => {
    if (!loadedIds) {
      return {};
    }
    const out = {};
    for (let t of DevAssignable) {
      const found = []
      for (let kind of loadedIds.filter(id => id.startsWith(t))) {
        if (kind in loadedQuestionnaires) {
          found.push(loadedQuestionnaires[kind]);
        } else {
          console.error(`${kind} not found in entities list!`);
        }
      }
      out[t] = found;
    }
    return out;
  }, [loadedIds, loadedQuestionnaires])

  function returnToOptions () {
    setShowQuestionnaire(false);
  }

  function generateAnswers () {
    dispatch(generateFakeAnswers(chosenKind))
  }

  const currentIsLoaded = useMemo(() => {
    return chosenKind && !!loadedIds?.includes(chosenKind);
  }, [chosenKind, loadedIds])

  useEffect(() => {
    console.warn("attempting autoload")
    const autoload = queryBoolean("autoload", false);
    if (autoload) {
      let screener;
      for (let [k, v] of Array.from(query.entries())) {
        if (k.toLocaleLowerCase() === "type" || k.toLocaleLowerCase() === "screener") {
          screener = v.toLocaleLowerCase();
          break;
        }
      }
      if (screener) {
        if (DevAssignable.includes(screener)) {
          setChosenType(screener);
          setChosenKind(generateQuestionnaireKind({screener, locale: {language}}))
          loadQuestionnaire(screener, language).then(success => {
            if (success) {
              setShowQuestionnaire(true);
            }
          })
        }
      }
    }
  }, []);

  return <>
    {showQuestionnaire ?
      <RedesignedQuestionnaire
        reportType={chosenType}
        language={language}
        debugHighlighting={debugHighlighting}
        showTrackingInfo={trackingInfo}
        useNavLogic={navLogicEnabled}
        autoProgression={autoProgressScalars}
        noDisableProgress={noDisableProgress}
        answerOverrides={overrides}
        dualLanguage={(language !== Language.English) && dualLanguage}
        quitFn={returnToOptions}/>
   : <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%", height: "100%", justifyContent: "space-evenly", margin: "0 auto", marginTop: "3em", maxWidth: "1500px"}}>
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", padding: "20px", backgroundColor: "#f0f0f0", borderRadius: "8px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", color: "#333", marginBottom: "3em"}}>
    <h2>Options</h2>
    <div className="mb-5" style={{opacity: 0.7}}>
      <h3>Navigation & Flow</h3>
      <label><Switch checked={navLogicEnabled} onChange={(e, v) => setNavLogicEnabled(v)}/>Use Skip/Display Logic</label><br/>
      <label><Switch checked={noDisableProgress} onChange={(e, v) => setNoDisableProgress(v)}/>Enable Unconditional Progress</label><br/>
      <label><Switch checked={autoProgressScalars} onChange={(e, v) => setAutoProgressScalars(v)}/>Auto-advance on Single-Response</label><br/>
      <h3 className="mt-2">Load Settings</h3>
      <label>Target Language</label>
      <select className="form-control" onChange={ev => setLanguage(ev.target.value)} style={{border: "1px rgba(0,0,0,0.2) solid", background: "rgba(256, 256, 256, 0.2)", borderRadius: "4px"}}>
        {LanguageList.map(l => <option value={l}>{dualName(l)}</option>)}
      </select>
      <label><Switch checked={dualLanguage} onChange={(e, v) => setDualLanguage(v)} disabled={language === Language.English} />Display English Simultaneously</label><br/>
      <label><Switch checked={simulateKiosk} onChange={(e, v) => setSimulateKiosk(v)}/>Simulate Kiosk Mode</label><br/>
      <label><Switch checked={simulateFullProvider} onChange={(e, v) => setSimulateFullProvider(v)} disabled={!simulateKiosk}/>Include Provider-Only Pages</label><br/>
      <h3 className="mt-2">Visual Rendering</h3>
      <label><Switch checked={debugHighlighting} onChange={(e, v) => setDebugHighlighting(v)}/>Show Container Outlines</label><br/>
      <label><Switch checked={trackingInfo} onChange={(e, v) => setTrackingInfo(v)}/>Show Tracking Info</label><br/>
      <label><Switch checked={overrides._bodyType === ""} onChange={(e, v) => setOverrides({...overrides, _bodyType: v ? "female" : "male"})}/>Set Female Body Type</label>
    </div>
    </div>
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", padding: "10px"}}>
      <h2 className="pb-0">
        Select Questionnaire
      </h2>
      <label style={{fontSize: "0.75rem"}}><Switch checked={showOutdatedTypes} onChange={(e, v) => setShowOutdatedTypes(v)}/>Show v1 Types</label>
      <ul className="list mt-4" style={{listStyle: "none", minWidth: "640px"}}>
        {available.map(algType => 
          <li
            key={algType}
            className={" " + (AssumedV2Support.includes(algType) ? "" : (showOutdatedTypes ? "debug-box" : "zz-display-none"))}
            style={{paddingLeft: "6px", borderRadius: "4px"}}>
              <span className="badge badge-light" style={{padding: "8px", backgroundColor: "#ff9c9c"}}>{algType}</span>
              {" "}{ScreenerNames[algType]}
              <button className="btn btn-warning btn-sm mx-1 float-right" onClick={() => loadQuestionnaire(algType)}>Load <small><span className="badge">{language}</span></small></button>
              <ul className="list mb-0 mt-2">
              {loadedByType[algType] && <small style={{ marginLeft: "-2rem"}}>Available subtypes:</small>}
                {loadedByType[algType]?.map(q =>
                <li key={q.id} className="hover-highlight" onClick={() => {setChosenKind(q.id); setChosenType(algType);}} style={{backgroundColor: (chosenKind === q.id ? "rgba(255, 200, 200, 0.5)" : ""), padding: "24px", borderRadius: "8px"}}>
                  <span className="badge badge-dark mx-1">{q.id}</span>
                  <span className="badge badge-light mx-1">schema v{q.schema_version}</span>
                  <span className="badge badge-light mx-1">v{q.version || "0"}</span><br/>
                  <tt>{q.readFrom}</tt><br/>
                  <strong>loaded:</strong> {moment(q.readTime).format('LTS')}<br/>
                  <strong>pages: </strong> {q.pages?.length}<br/>
                  <strong>questions: </strong> {Object.values(q.questions).length}<br/>
                  {q.base ? <span>based on {q.base}</span> : null}
                  {q.extends ? <span>based on {q.extends.url} as {q.extends.role}</span> : null}

                </li>)}
                {loadedByType[algType]?.length ? null : <li><em>None loaded</em></li>}
              </ul>
            </li>
        )}
      </ul>
      {chosenKind && currentIsLoaded ? <button className="btn btn-warning w-100 mb-2" onClick={() => generateAnswers()}>Seed Answers</button> : null}
      <button className="btn btn-info w-100 mb-5" disabled={!currentIsLoaded} onClick={() => setShowQuestionnaire(true)}>
        {chosenKind ? (currentIsLoaded ? "Launch" : "Your selected type is not loaded") : "First select a type above"}
      </button>
    </div>
    <em style={{position: "absolute", bottom: "-100px", maxWidth: "650px", textAlign: "center", backgroundColor: "#ffecb3", padding: "12px", borderRadius: "8px"}}><strong>Note: </strong>you can return to this screen at any time by pressing <kbd>F4</kbd>, although some types of crashes may still require a reload.</em>
    </div>}
  </>
};
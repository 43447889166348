import { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import rehypeRaw from "rehype-raw";
import { EnglishNames, Language, LanguageEndonym, LanguageList, SelectorAssets } from "../../../constants/locales";
import { alertActions } from "../../../store/slices/alerts";
import { emptyQuestionnaire, getQuestionnaireByTypeAndKind } from "../../../store/slices/questionnaire-old.js";
import { assignOnly, ordinals, safeStringify, shortOrdinal } from "../../../utils";
import { evaluator } from "../../../utils/evaluator";
import { useKeyboardEvents } from "../../../utils/hooks";
import { loopIndexedAnswerId } from "../RedesignPrototypes";
import { NavigationalOverlay } from "./Partials/NavigationalOverlay";
import QuestionResponse from "./Partials/QuestionResponse";
import { QuestionPage } from "./Pages/QuestionPage";
import { AtomicAnswerReportEntry, RedesignedReport, TextOnlyReportEntry } from "./Pages/RedesignedReport";
import { SuccessPage } from "./Pages/SuccessPage";
import { ImageWithFallback } from "../../UI-old/ImageWithFallback";
import { LanguageSelector } from "./Pages/LanguageSelector";
import { CountdownTimer } from "./CountdownTimer";
import moment from "moment";

import { ALLOW_BACKDROP_TO_CLOSE_MODAL, AUTO_ADVANCE_TIMEOUT, AUTO_ADVANCE_TYPES, COMPLEX_UI_TYPES, KNOWN_PAGE_TYPES, CONSENT_MODAL, EMPTY_ANSWER, MODAL_Q_CONFIGS } from "./constants_to_move";
import { LoadingPage } from "./Pages/LoadingPage";
import { StandardModalDialog } from "./Partials/StandardModalDialog";
import { TitlePage } from "./Pages/TitlePage";
import SingleQuestion from "./Partials/SingleQuestion";
import { DebugPage } from "./Pages/DebugPage";
import { CTA } from "../../UI/buttons/CTA";

// TODO once we have an evaluator service, this should live there
let universalFormulaCount = 0;

export const RedesignedQuestionnaire = ({
  reportType,
  debugHighlighting,
  showTrackingInfo,
  useNavLogic,
  autoProgression,
  noDisableProgress,
  answerOverrides,
  language,
  quitFn
}) => {
  function locale () {
    return {language};
  }

  const dispatch = useDispatch();
  const questionnaire = useSelector(s => getQuestionnaireByTypeAndKind(s, reportType, locale()) ?? emptyQuestionnaire());
  const globalAnswers = useSelector(s => s.questionnaire.answers ?? {});
  // const scrollContainer = useRef();
  const [pageNumber, setPageNumber_raw] = useState(0);
  const [subPageNumber, setSubPageNumber] = useState(-1);
  const [loopIndex, setLoopIndex_raw] = useState(null);
  const [loopLengths, setLoopLengths] = useState({"_": 1});
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(transformedOverrides);
  const [questionHidden, setQuestionHidden] = useState({});
  const [manuallyShown, setManuallyShown] = useState([]);

  const [fullPageModal, setFullPageModal] = useState(null);
  function closeModal () {
    setFullPageModal(null);
  }


  function transformedOverrides () {
    if (answerOverrides) {
      let out = {};
      for (let k in answerOverrides) {
        out[k] = Array.isArray(answerOverrides[k]) ? 
          {isMulti: true, values: answerOverrides[k].map(v => ({value: v}))} :
          {isMulti: false, value: {value: answerOverrides[k]}};
      }
      return {...globalAnswers, ...out};
    }
    return globalAnswers;
  }

  function setLoopIndex (i) {
    setLoopIndex_raw(i);
  }

  useEffect(() => {
    console.warn({questionnaire});
    // if (Array.isArray(questionnaire?.introduction)) {
    //   setIntroductionPage(0);
    //   setFullPageModal({type: "introduction", ...questionnaire.introduction[0]})
    // } 
  }, [questionnaire]);

  const LOADING_STUB = { type: "loading"};
  const currentPage = useMemo(() => {
    if (questionnaire.loading) {
      return ({
        type: "loading"
      });
    }
    if (Array.isArray(questionnaire?.pages) && pageNumber > -1 && pageNumber < questionnaire.pages.length) {
      // console.warn({page: `number ${pageNumber}, q1: ${questionnaire.pages[pageNumber]?.questions?.[0]}`})
      return questionnaire.pages[pageNumber];
    } else {
      console.error({pageNav: pageNumber, pages: questionnaire?.pages})
      return (LOADING_STUB);
      // return ({
      //   type: "error",
      //   error: `Could not load page ${pageNumber} from ${questionnaire?.pages?.length ?? "??"}-page list`,
      //   trace: `n/a`
      // });
    }
  }, [questionnaire, pageNumber]);

  function setLoopLength (id, len) {
    console.warn({newLoopLength: `${id}: ${len}`});
    setLoopLengths({...loopLengths, [id]: len});
  }
  const loopLength = useMemo(() => {
    return loopLengths[currentPage?.loopId ?? "_"];
  }, [loopLengths, currentPage]);

  function getIndexAndLengthForNewLoop (loopId) {
    if (typeof loopId === "string") {
      if (loopId in loopLengths) return [0, loopLengths[loopId]];
      const loopDef = questionnaire.loops[loopId];
      switch (loopDef.type) {
        case "fixed-length":
          return[0, loopDef.length];
          break;
          case "user-extensible":
          return[0, loopDef.initialLength ?? 1];
          break;
        case "answer-as-quantity":
          const dependentAnswer = answers[loopDef.determinedBy];
          const dependentValue = dependentAnswer?.value?.value;
          const numericValue = Number.parseInt(10, dependentValue);
          if (!dependentValue || !Number.isSafeInteger(numericValue) || numericValue <= 0) {
            console.warn(`Answer for loop quantity "${loopDef.determinedBy}" was null, zero, or nonnumeric (${typeof dependentValue} ${dependentValue})`);
            return [0, 0];
          }
          return [0, numericValue];
        case "answer-as-iterable": // TODO!
        default:
          return [0, 1]
      }
    } else {
      return [null, 1];
    }
  }

  function evaluateDisplayLogic (displayable, answerContext, valueCache, label = `ad-hoc formula ${universalFormulaCount++}`, showFailReason = false) {
    if (!displayable) {
      debugger;
      console.error("Attempted to evaluate display logic for null object");
      return false;
    }
    if (!useNavLogic) return true;
    if (Object.keys(answerContext)?.length) {
      console.warn({answerContext});
    }
    const expandedValues = {
      ...valueCache,
      ...answers
    };
    const expandedContext = {
      ...questionnaire,
      _currentRow: loopIndex
    }
    if (Array.isArray(displayable.skipWhen)) {
      const result = !evaluator(displayable.skipWhen, expandedValues, expandedContext, true, false, label + ".skipWhen");
      return result ? true : (showFailReason ? "question skipWhen evaluated to true" : false);
    }
    if (Array.isArray(displayable.displayWhen)) {
      const result = evaluator(displayable.displayWhen, expandedValues, expandedContext, true, false, label + ".displayWhen");
      return result ? true : (showFailReason ? "question displayWhen evaluated to false" : false);
    }
    return true;
  }

  function reevaluateQuestionVisibility (questions) {
    const hideMapping = {};
    questions.forEach(q => {
      // if (pageNumber > 100) debugger;
      const showOrFailReason = evaluateDisplayLogic(questionnaire.questions?.[q], {}, {}, q, true);
      if (typeof showOrFailReason === "string") {
        hideMapping[q] = showOrFailReason;
      }
    });
    setQuestionHidden(hideMapping);
  }
  useEffect(() => {
    reevaluateQuestionVisibility(currentPage?.questions || []);
  }, [currentPage, answers, manuallyShown])
  useEffect(() => {
    console.log({questionHidden});
  }, [questionHidden])
  
  function setPageNumber (n, refresh = false, forward) {
    recordActivityForTimeout("page_navigation");
    if (n === pageNumber & !refresh) return;
    const newPage = questionnaire?.pages?.[n];
    const questionsOnPage = newPage?.questions;
    setActiveQuestionIndex((forward ?? (n > pageNumber)) ? 0 : (questionsOnPage?.length || 1) - 1);
    if (newPage?.type === "question") {
      reevaluateQuestionVisibility(questionsOnPage || []);
    }
    if (newPage?.type === "question-modal") {
      if (newPage.modalType in MODAL_Q_CONFIGS) {
        const config = MODAL_Q_CONFIGS[newPage.modalType];
        setSubPageNumber(forward ? 0 : config.subpages - 1);
      } else {
        dispatch(alertActions.createAlert({message: `Unknown modal type '${newPage.modalType}'`}));
      }
    }
    const nextLoop = newPage?.loopId;
    if (nextLoop !== currentPage.loopId) {
      const [nextIndex, nextLength] = getIndexAndLengthForNewLoop(nextLoop);
      setLoopIndex(nextIndex);
      setLoopLength(nextLoop, nextLength);
    }
    setPageNumber_raw(n);
  }

  const activeQuestionId = useMemo(() => {
    return currentPage.questions?.[activeQuestionIndex] ?? "";
  }, [activeQuestionIndex, currentPage]);
  const activeQuestion = useMemo(() => {
    return questionnaire?.questions?.[activeQuestionId] ?? {};
  }, [questionnaire, activeQuestionId]);
  const activeAnswer = useMemo(() => {
    return answers[loopIndexedAnswerId(activeQuestionId, loopIndex)];
  }, [answers, activeQuestionId]);


  function findNextValidPage (currentPageNumber, forward = true, ignore = {}, [min, max] = [0, questionnaire.pages.length]) {
    if (currentPageNumber < min || currentPageNumber >= max) {
      throw new Error(`Initial page num ${currentPageNumber} is outside bounds [${min}, ${max}]`);
    }
    const delta = forward ? 1 : -1;
    const valueCache = new Map();
    let targetPageNumber = currentPageNumber + delta;
    let currentPage = questionnaire.pages[currentPageNumber];
    // if (currentPage._multiplexOver) debugger;
    let targetPage = questionnaire.pages[targetPageNumber];
    function tryNextPage (specific = targetPageNumber + delta) {
      targetPageNumber = specific;
      targetPage = questionnaire.pages[targetPageNumber];
    }
    let targetLoopIndex = loopIndex;
    
    // first we test if we are in a loop, and advance within it as necessary
    if (currentPage.loopId) {
      const loop = questionnaire.loops[currentPage.loopId];
      const loopRestartIndex = forward ?
        questionnaire.pages.findIndex(p => p.loopId === currentPage.loopId) :
        questionnaire.pages.findLastIndex(p => p.loopId === currentPage.loopId);
      if (!loop) {
        console.error(`Could not find loop with ID ${currentPage.loopId}!`);
      } else {
        // evaluate loop pages until we hit one we've seen before
        const loopPagesSeen = new Set([]);
        while (!loopPagesSeen.has(targetPageNumber)) {
          if (targetPage.loopId !== currentPage.loopId) {
            // we'd be outside the loop, so go to the "first"/"last" page in the
            // loop instead IFF we can adjust the index appropriately
            targetLoopIndex += delta;
            if (targetLoopIndex < 0 || (ignore.loopLengthLimits !== true && targetLoopIndex >= loopLength)) {
              // fell off the "edge of the loop". LEAVE TARGET PAGE AS IT IS
              break;
            }
            // otherwise try the next iteration of the loop
            tryNextPage(loopRestartIndex);
            continue;
          }
          // target page is in the loop
          const [valid] = pageIsDisplayable(targetPage, currentPage, forward, valueCache, {index: targetLoopIndex, length: loopLength}, ignore);
          if (valid) {
            // we advanced within the loop
            if (targetPage.type === "loop_summary" && loop.type === "user-extensible") {
              setLoopIndex(forward ? loopLength - 1 : 0);
            } else {
              setLoopIndex(targetLoopIndex);
            }
            setPageNumber(targetPageNumber, true, forward);
            return;
          }
          tryNextPage();
        }
        // we hit a loop page we already saw, give up on loop
      }
    }

    while (targetPageNumber >= min && targetPageNumber < max) {
      let nextIndex, nextLength;
      if (targetPage.loopId) {
        if (targetPage.loopId === currentPage.loopId) {
          console.error(`Reentered disjoint page ${targetPageNumber} for original loop ${currentPage.loopId}! Ignoring`);
          tryNextPage();
          continue;
        } else {
          [nextIndex, nextLength] = getIndexAndLengthForNewLoop(targetPage.loopId);
          if (nextLength === 0 && !targetPage.showOnEmptyLoop) {
            tryNextPage();
            continue;
          }
        }
      }
      const [valid, displayedQuestions] = pageIsDisplayable(targetPage, currentPage, forward, valueCache, nextLength ? {index: nextIndex, length: nextLength} : undefined, ignore);
      if (valid) {
        setPageNumber(targetPageNumber, false, forward);
        return;
      }
      tryNextPage();
    }

    console.warn("Found no valid pages to advance to");
  }

  // return [boolean, Array<string>?]
  function pageIsDisplayable (targetPage, currentPage, forwards = true, valueCache = new Map(), loopSettings = {}, ignore = {}) {
    if (loopSettings) {
      if (loopSettings.length === 0 && !ignore.loopLengthLimits && !currentPage.showOnEmptyLoop) {
        return [false];
      }
    }

    const tempAnswers = {};

    const shouldDisplayPage = evaluateDisplayLogic(targetPage, tempAnswers, valueCache);
    if (!shouldDisplayPage) {
      return [false];
    }

    if (targetPage.type === "question-unique") {
      if (targetPage.uniqueType === "chief complaint followup") {
        const unrefinedCC = answers["chief complaint unrefined"];
        if (unrefinedCC && unrefinedCC.values?.length > 1) {
          return [true];
        } else {
          return [false];
        }
      } else if (targetPage.uniqueType === "region of problem followup") {
        const unrefinedCC = answers["region of problem unrefined"];
        if (unrefinedCC && unrefinedCC.values?.length > 1) {
          return [true];
        } else {
          return [false];
        }
      } else {
        console.error(`Displaying unknown unique question type ${targetPage.uniqueType}`, targetPage);
        return [true];
      }
    }

    if (targetPage.type === "question") {
      const activeQuestions = targetPage.questions.map(qId => evaluateDisplayLogic(questionnaire.questions[qId], tempAnswers, valueCache, qId));
      if (activeQuestions.every(x => x === false)) {
        return [false];
      } else {
        return [true, targetPage.questions.filter((q, i) => activeQuestions[i])];
      }
    }

    if (targetPage.type === "question-modal") {
      // TODO: modals need triggers too, revisit during logic work
    }

    return [true];
  }

  function goToNextPage (ignore) {
    findNextValidPage(pageNumber, true, ignore);
  }

  function goToPreviousPage (ignore) {
    findNextValidPage(pageNumber, false, ignore);
  }

  function addLoopEntry (advance = true) {
    const loopDef = questionnaire.loops[currentPage.loopId];
    if (loopDef?.type !== "user-extensible" || loopLength >= loopDef?.max) {
      console.error("Cannot extend loop, either not extensible or at max size already");
    } else {
      setLoopLength(currentPage.loopId, loopLength + 1);
      // setLoopIndex(loopLength);
      if (advance) {
        jumpLoopEntry(loopLength);
      }
    }
  }

  function removeLoopEntry (i) {
    const loopQuestions = Object.entries(questionnaire.questions).filter(([k, v]) => v.loopId === currentPage.loopId);
    const newAnswers = {...answers};
    // slide all later answers back one index
    for (let j = i; j < loopLength - 1; j++) {
      for (let [k, v] of loopQuestions) {
        newAnswers[`${k}§${j}`] = newAnswers[`${k}§${j+1}`];
      }
    }
    // always end with deleting the last item of the loop:
    for (let [k, v] of loopQuestions) {
      delete newAnswers[`${k}§${loopLength - 1}`];
    }
    setAnswers(newAnswers);
    setLoopLength(currentPage.loopId, loopLength - 1);
  }

  function jumpLoopEntry (i) {
    const target = questionnaire.pages?.findIndex((p, i) => {
      return i !== pageNumber && p.loopId === currentPage.loopId && pageIsDisplayable(p, currentPage, true, undefined, {length: loopLength, index: loopIndex}, {loopLengthLimits: true});
    })
    if (target !== -1) {
      setPageNumber(target, false, true);
      setLoopIndex(i);
    }
  }

  // const questionRefs = useMemo(() => {
  //   return ordinals(currentPage.questions?.length ?? 0).map(_ => createRef());
  // }, [currentPage])

  function activateByIndex (i) {
    setActiveQuestionIndex(i);
  }


  const HARDCODED_DERIVED_ANSWERS = {
    "chief complaint unrefined": (a) => {
      if (a.values?.length === 1) {
        return ({"chief complaint": {value: {...a.values[0]}, questionKey: "chief complaint unrefined"}});
      } else {
        return ({"chief complaint": {value: {value: null}}});
      }
    },
    "region of problem unrefined": (a) => {
      if (a.values?.length === 1) {
        return ({"region of problem": {value: {...a.values[0]}, questionKey: "region of problem unrefined"}});
      } else {
        return ({"region of problem": {value: {value: null}}});
      }
    },
  };

  function calculateDerivedAnswers (newAnswer, id) {
    if (typeof HARDCODED_DERIVED_ANSWERS[id] === "function") {
      return HARDCODED_DERIVED_ANSWERS[id](newAnswer);
    } else {
      return ({});
    }
  }

  function acceptAnswer (newAnswer, id) {
    recordActivityForTimeout("answer_change");
    const updatedAnswers = {
      ...answers,
      [id]: newAnswer,
      ...calculateDerivedAnswers(newAnswer, id)
    };
    console.log({updatedAnswers});
    setAnswers(updatedAnswers);
    const activeQuestion = questionnaire.questions?.[activeQuestionId];
    if (autoProgression && (AUTO_ADVANCE_TYPES.includes(activeQuestion?.type) || activeQuestion?.asList) && !activeQuestion?.isMulti) {
      setTimeout(() => {
        // TODO: make this check if this is still the same question once the time is up
        completeQuestion();
      }, AUTO_ADVANCE_TIMEOUT);
    }
  }

  function whenNoInputFocused (event, shiftCB, nonShiftCB) {
    const focused = event.target.getRootNode()?.activeElement || event.target;
    if (["INPUT", "TEXTAREA"].includes(focused.tagName)) {
      return;
    }
    if (event.shiftKey) {
      if (typeof shiftCB === "function") {
        shiftCB();
      }
    } else {
      if (typeof nonShiftCB === "function") {
        nonShiftCB();
      }
    }
  }

  function jumpToReview () {
    const r = questionnaire.pages?.findIndex(p => p.type === 'review');
    if (r !== -1) {
      setPageNumber(r, true, true);
    }
  }

  function showJumpOptions () {
    setFullPageModal({type: "jump dialog"});
  }

  function clearCurrentAnswer () {
    const aId = loopIndexedAnswerId(activeQuestionId, loopIndex);
    if (aId in answers) {
      console.warn(`Found '${aId}' in answers, deleting`);
      delete answers[aId];
      setAnswers({...answers});
    } else {
      console.log(`Did not find '${aId}' in answers list`);
    }
  }

  const [renderForcer, setRenderForcer] = useState(0);
  function forceRender () {
    setRenderForcer(i => i + 1);
  }

  useKeyboardEvents([
    "ArrowRight", e => whenNoInputFocused(e, goToNextPage, completeQuestion),
    "ArrowLeft", e => whenNoInputFocused(e, goToPreviousPage, navigateBack),
    "~", e => whenNoInputFocused(e, jumpToReview, jumpToReview),
    "J", e => whenNoInputFocused(e, showJumpOptions),
    "C", e => whenNoInputFocused(e, clearCurrentAnswer),
    "Escape", e => fullPageModal ? setFullPageModal(null) : forceRender()
  ], "keyup", undefined, [pageNumber, activeQuestionIndex, findNextValidPage, goToNextPage, goToPreviousPage]);

  useEffect(() => {
    const listener = beforeUnloadEvent => {
      console.error(beforeUnloadEvent);
      beforeUnloadEvent.returnValue = true;
    };
    const result = window.addEventListener("beforeunload", listener);
    console.warn(`attached before unload listener with result ${result}`);
    return () => {
      console.error(`removing before unload listener`);
      window.removeEventListener("beforeunload", listener);
    }
  }, []);

  const currentEffectiveSubpage = useMemo(() => {
    return currentPage?.type === "question-modal" ? effectiveSubpage(subPageNumber, MODAL_Q_CONFIGS[currentPage.modalType]) : -1;
  }, [subPageNumber, currentPage]);
  const currentEffectiveSubloop = useMemo(() => {
    return currentPage?.type === "question-modal" ? effectiveSubloop(subPageNumber, MODAL_Q_CONFIGS[currentPage.modalType]) : null;
  }, [subPageNumber, currentPage]);

  function effectiveSubpage(num, config) {
    if ((typeof config?.getEffectiveSubPage) === "function") {
      return config.getEffectiveSubPage(num)[0];
    } else {
      return num % (config?.subpages ?? 1);
    }
  }
  function effectiveSubloop(num, config) {
    if ((typeof config?.getEffectiveSubPage) === "function") {
      return config.getEffectiveSubPage(num)[1];
    } else {
      return Math.floor(num / (config?.subpages ?? 1));
    }
  }

  function modalIsDone(config) {
    switch (config.finishCondition) {
      case "continue-question":
        // the continue question's own updateAnswer handles "looping"
        return (effectiveSubpage(subPageNumber, config) === config.subpages - 1);
      case "iterate-responses":
        // finishParameter is an array 
        const length = config.finishParameter.map(ai => answers[ai]?.values?.length ?? 0).reduce((a,b) => a+b, 0);
        return subPageNumber >= length;
      default:
        return subPageNumber >= config.subpages - 1;
    }
  }

  function completeQuestion () {
    if (currentPage.type === "question-modal") {
      const config = MODAL_Q_CONFIGS[currentPage.modalType];
      // activeQuestionIndex === config.questionsByPage[subPageNumber].length - 1
      if (true) {
        if (modalIsDone(config)) {
          goToNextPage()
        } else {
          setSubPageNumber(subPageNumber + 1);
          activateByIndex(0);
        }
      } else {
        activateByIndex(activeQuestionIndex + 1);
      }
      return;
    }
    if (currentPage.type !== "question" || activeQuestionIndex === currentPage.questions.length - 1) {
      goToNextPage();
    } else {
      activateByIndex(activeQuestionIndex + 1);
    }
  }
  
  function updateSpecial_hosps (ans) {
    if (ans?.value?.value === "Yes") {
      setSubPageNumber(subPageNumber + 1);
    } else {
      completeQuestion();
    }
  }
  function updateSpecial_FA_surg (ans) {
    acceptAnswer({
      isMulti: true,
      values: ans?.value?.value?.split(",").map(s => ({value: s.trim()}))
    }, "other surgeries");
  }
  function updateSpecial_FA_drugs (ans) {
    acceptAnswer({
      isMulti: true,
      values: ans?.value?.value?.split(",").map(s => ({value: s.trim()}))
    }, "other drugs");
  }
  function rewrappedSubanswer (ao, i) {
    if (!ao || !(i in ao?.values)) return null;
    return ({
      isMulti: false,
      value: ao.values[i]
    });
  }
  function iteratingModalContext (num, mType) {
    const mainResponses = answers[MODAL_Q_CONFIGS[mType].finishParameter[0]]?.values?.length ?? 0;
    if (num < mainResponses) {
      return rewrappedSubanswer(answers[MODAL_Q_CONFIGS[mType].finishParameter[0]], num);
    } else {
      return rewrappedSubanswer(answers[MODAL_Q_CONFIGS[mType].finishParameter[1]], num - mainResponses);
    }
  }

  const [uploadedImages, setUploadedImages] = useState([]);
  function updateSpecial_medImg (e) {
    if (e?.target?.files?.length > 0) {
      const fr = new FileReader();
      fr.onload = e => {
        setUploadedImages([e.target.result]);
      }
      fr.readAsDataURL(e.target.files[0]);
    }
  }

  function navigateBack () {
    if (currentPage.type === "question") {
      if (activeQuestionIndex > 0) {
        activateByIndex(activeQuestionIndex - 1);
      } else {
        goToPreviousPage();
      }
    } else {
      // TODO: more logic here potentially
      goToPreviousPage();
    }
  }

    /** 
   * Determine the current display mode using cues from the current page and
   * question status.
   * 
   * @type {"backdrop" | "bubble" |"no-art"} 
   **/
    const headerMode = useMemo(() => {
      if (fullPageModal) {
        // when a modal is set
        return "no-art";
      }
      if (currentPage?.type === "language-selector") {
        return 'backdrop';
      }
      if (!currentPage || !activeQuestion) {
        // when there is nothing active, 
        return 'no-art';
      }
      if ((!activeQuestion?.art && !activeQuestion?.contextualArt) || COMPLEX_UI_TYPES.includes(activeQuestion.type)) {
        return 'no-art';
      }
      return (currentPage.questions?.indexOf(activeQuestion.id) > 0 ? 'bubble' : 'backdrop')
    }, [activeQuestion, currentPage, fullPageModal]);

    const headerButtonState = useMemo(() => {
      function nextButtonState () {
        // highest priority is to acknowledge errors
        if (currentPage.error || activeQuestion?.error || questionHidden[activeQuestionId]) {
          return ({
            visible: true,
            active: true,
            error: true
          });
        }

        // the last page never gets a next button
        if (pageNumber == questionnaire?.pages?.length - 1) {
          return ({
            visible: false,
            active: false
          });
        }

        // introduction modal is deprecated, but it uses standard next button
        if (fullPageModal?.type === "introduction") {
          return {active: true, visible: true};
        }
        // any page with a CTA
        if (currentPage?.type === "kiosk_start" 
            || currentPage?.callToAction
            || questionnaire?.pages?.[pageNumber + 1]?.type === "success") {
          return ({
            visible: false,
            active: false
          });
        }

        if (currentPage.type === "question") {
          if (activeQuestion.type === "anatomical area") {
            return {
              visible: false,
              active: false
            };
          }
          if (AUTO_ADVANCE_TYPES.includes(activeQuestion.type) && !activeQuestion.isMulti) {
            // we are on an auto-progression question
            if (!!(activeAnswer?.value?.value || activeAnswer?.values?.length)) {
              // but there is already an answer
              return {
                visible: true,
                active: true
              };
            } else {
              return {
                visible: !autoProgression,
                active: noDisableProgress || false
              };
            }
          } else {
            return {
              visible: true,
              // active: (activeAnswer.isAnswered)  // eventually?
              active: noDisableProgress || !!(activeAnswer?.value?.value || activeAnswer?.values?.length)
            }
          }
        } else {
          // for now we give every other kind of page a next button...
          return {
            visible: true,
            active: true
          };
        }
      }

      return {
        next: nextButtonState(),
        back: {
          visible: fullPageModal || pageNumber > 0 || activeQuestionIndex > 0,
          active: true,
          icon: (fullPageModal || currentPage?.type === "question-modal") ? "quit" : "back",
        },
        callToAction: (questionnaire?.pages?.[pageNumber + 1]?.type === "success") ? "I'm done" : null
      };
    }, [activeQuestion, activeAnswer, fullPageModal, pageNumber, autoProgression]);

  const hiddenChoices = useMemo(() => {
    if (currentPage?.type !== "question" || !currentPage.questions?.length || !(questionnaire?.questions)) return [];
    const hiddenByQuestion = {};
    currentPage.questions.forEach((qID, i) => {
      if (Array.isArray(questionnaire.questions[qID]?.choices)) {
        const hiddenForThis = [];
        questionnaire.questions[qID].choices.forEach((c, i) => {
          if (c.displayWhen || c.skipWhen) {
            if (!evaluateDisplayLogic(c, {}, {}, `${qID}.choices.${i}`)) {
              hiddenForThis.push(i);
            }
          }
        });
        hiddenByQuestion[qID] = hiddenForThis;
      } else {
        hiddenByQuestion[qID] = [];
      }
    })
    console.log({hiddenChoices: JSON.stringify(hiddenByQuestion)});
    return hiddenByQuestion;
  }, [currentPage]);

  const DEFAULT_BG_COLOR = "#1fe291";
  const COLOR_PAGES = ["title", "kiosk_consent", "success"];
  const STYLE_BGS = {
    "light": null,
    "cool": "rgb(32, 213, 224)"
  };
  const fullBackground = useMemo(() => {
    if (currentPage.style in STYLE_BGS) {
      return STYLE_BGS[currentPage.style];
    }
    if (COLOR_PAGES.includes(currentPage.type)) {
      return DEFAULT_BG_COLOR;
    }
    return null;
  }, [currentPage]);

  const contextualArt = useMemo(() => {
    if (!activeQuestion.contextualArt) return null;
    const givenAnswer = answers[activeQuestion.contextualArt];
    if (Number.isSafeInteger(givenAnswer?.value?.choiceIndex)) {
      const questionKey = givenAnswer.questionKey ?? activeQuestion.contextualArt;
      const contextualQuestion = questionnaire.questions[questionKey];
      if (contextualQuestion) {
        const choice = contextualQuestion.choices[givenAnswer.value.choiceIndex];
        console.log({contextualArtChoice: choice});
        return choice.imgUrl;
      }
      debugger;
      console.warn(`Found answer for contextualArt, but no matching question. Was there a mismatch?`);
      return null;
    }
    return null;
  }, [activeQuestion]);

  const [launchTimestamp] = useState(Date.now());
  const lastActionTimestamp = useRef(Date.now());
  const [lastActionType, setLastActionType] = useState("uninitialized");
  const [inactivityDialogTimerId, setInactivityDialogTimerId] = useState(-1);
  const [inactivityDialogActive, setInactivityDialogActive] = useState(false);
  const INACTIVITY_LIMIT = 30 * 60 * 1000;
  const INACTIVITY_WARNING_LENGTH = 1 * 60 * 1000;
  function inactiveDuration() {
    return Date.now() - lastActionTimestamp;
  }
  function idleLogoutTimestamp() {
    return lastActionTimestamp.current + INACTIVITY_LIMIT;
  }
  function displayIdleWarningTimestamp() {
    return lastActionTimestamp.current + INACTIVITY_LIMIT - INACTIVITY_WARNING_LENGTH;
  }
  function logoutForInactivity() {
    // probably should clear data, but we need answer stuff to be re-hoisted
    // first anyway, so not relevant to current prototype
    quitFn();
  }
  function showInactivityDialog () {
    if (Date.now() >= displayIdleWarningTimestamp() - 200) {
      setInactivityDialogActive(true);
      setTimeout(() => logoutForInactivity(), INACTIVITY_WARNING_LENGTH);
    }
  }
  function recordActivityForTimeout (activityType) {
    clearTimeout(inactivityDialogTimerId);
    setLastActionType(activityType);
    lastActionTimestamp.current = Date.now();
    const timerId = setTimeout(() => showInactivityDialog(), INACTIVITY_LIMIT - INACTIVITY_WARNING_LENGTH);
    setInactivityDialogTimerId(timerId);
    if (inactivityDialogActive) setInactivityDialogActive(false);
  }
  useEffect(() => {
    recordActivityForTimeout("first_render");
  }, []);

  const [showQuitDialog, setShowQuitDialog] = useState(false);
  function providerQuit (saveData = true) {
    setAnswers({});
    const pn = questionnaire?.pages?.findIndex(p => p.type === "kiosk_start" || p.type === "title") ?? 0;
    setPageNumber(pn);
    setShowQuitDialog(false);
  }
  function longPressHandler (event) {
    if (event?.target?.id === "backward-button") {
      setShowQuitDialog(true);
    } else {
      showJumpOptions();
    }
  }

  const uniques_chiefComplaintFollowup = useMemo(() => {
    const CCs_chosen = (Array.isArray(answers["chief complaint unrefined"]?.values)) ?
    answers["chief complaint unrefined"].values.map(
        a => questionnaire.questions["chief complaint unrefined"].choices[a.choiceIndex]) ?? []
        : [];
    return ({
      "type": "cards",
      "art": "/images/symptoms.png",
      "text": "You selected more than one choice. Which of these is your most important complaint?",
      // "textAudioFile": "Q_HPI001",
      // "subtext": "Select only one answer. This is the main reason you are seeing a doctor today.",
      "reportLabel": "Chief Complaint (Singular)",
      "choices": CCs_chosen
    });
  }, [answers]);

  const uniques_regionOfProblemFollowup = useMemo(() => {
    const CCs_chosen = (Array.isArray(answers["region of problem unrefined"]?.values)) ?
    answers["region of problem unrefined"].values.map(a => ({...a, label: a.value})) ?? []
        : [];
    return ({
      "type": "cards",
      "art": "/images/symptoms.png",
      "text": "You selected more than one region. Which of these has the worst problem/pain?",
      // "textAudioFile": "Q_HPI001",
      // "subtext": "Select only one answer. This is the main reason you are seeing a doctor today.",
      "reportLabel": "Problem Region (Singular)",
      "choices": CCs_chosen
    });
  }, [answers]);

  const KNOWN_UNIQUES = ["chief complaint followup", "region of problem followup"];

  // TEMP until navigation is reduxified
  function setPage_Overload (pageNum, subPageNum, activeQI) {
    if (pageNum !== undefined) {
      setPageNumber(pageNum);
    }
    if (subPageNum !== undefined) {
      setSubPageNumber(subPageNum);
    } 
    if (activeQI !== undefined) {
      setActiveQuestionIndex(activeQI);
    }
  }

  function StandardPage () {
    if (!KNOWN_PAGE_TYPES.includes(currentPage.type)) {
      return (
        <div className="viewer main-columm" style={{minHeight: "100vh", justifyContent: "center"}}>
          <h3 className="text-center text-warning">Unknown Page Type: {`${currentPage.type}`}</h3>
          <pre className="p-2 mb-0" style={{whiteSpace: "pre-wrap", background: "#E0E0FF", borderRadius: "8px"}}>{safeStringify(currentPage)}</pre>
        </div>
      );
    }

    switch (currentPage.type) {
      case "loading":
        return <LoadingPage/>;
      case "error": 
        return (
          <div className="viewer main-columm" style={{minHeight: "100vh", justifyContent: "center"}}>
            <h3 className="text-center text-warning">{currentPage.error}</h3>
            {currentPage.trace ? 
              <pre className="p-2 mb-0" style={{whiteSpace: "pre-wrap", background: "#E0E0FF", borderRadius: "8px"}}>{currentPage.trace}</pre>
            : null}
          </div>
        );
      case "debug":
        case "loading":
        return <DebugPage {...currentPage} advance={completeQuestion}/>
      case "title":
        return <TitlePage {...currentPage} actOnCTA={completeQuestion}/>;
      case "kiosk_start":
        return <TitlePage {...currentPage} callToAction="Start" actOnCTA={completeQuestion}/>;
      case "kiosk_consent":
        return (
          <TitlePage {...currentPage} actOnCTA={completeQuestion}>
            <a
              onClick={() => setFullPageModal({type: "markdown", title: "Consent Form", content: CONSENT_MODAL})}
              style={{display: "block", marginTop: "2em", textDecoration: "underline"}}>
                Learn more about the study
            </a>
          </TitlePage>
        );
      case "language-selector":
        return <LanguageSelector/>;
      case "review":
        return (
          <div className="viewer main-column" style={{minHeight: "100vh", justifyContent: "center"}}>
            <RedesignedReport questionnaireDefinition={questionnaire} answers={answers} user={{name: "Prototype User"}} acceptAnswer={acceptAnswer} {...currentPage}/>
          </div>
        );
      case "success":
        return (
          <div className="viewer main-column" style={{height: "100vh", justifyContent: "space-between", paddingBottom: 0}}>
            <hr style={{opacity: "0%"}}/>
            <SuccessPage/>
          </div>
        );
      case "question":
        return (
          <QuestionPage 
            {...currentPage}
            questionnaire={questionnaire}
            answers={answers}
            activeQuestionId={activeQuestionId}
            loopIndex={loopIndex}
            questionHidden={questionHidden}
            hiddenChoices={hiddenChoices}
            openModal={setFullPageModal}
            acceptAnswer={acceptAnswer}
            completeQuestion={completeQuestion}
            navigateBack={navigateBack} />
        );
      case "question-unique":
      case "question-modal":
        // do nothing, these are not yet standardized and have custom blocks below
        return null;
      default:
        // treat as unknown page type! but means we have a mistake in the code here...
        console.error(`Page type has no display case, but listed in KNOWN_TYPES: ${currentPage.type}`);
        return (<div className="viewer main-columm" style={{minHeight: "100vh", justifyContent: "center"}}>
          <h3 className="text-center text-warning">Unknown Page Type: {`${currentPage.type}`}</h3>
          <pre className="p-2 mb-0" style={{whiteSpace: "pre-wrap", background: "#E0E0FF", borderRadius: "8px"}}>{safeStringify(currentPage)}</pre>
        </div>);
    }
  }

  return (
  <div 
    className={`redesign page-${currentPage?.type} ${headerMode === "backdrop" ? "has-backdrop" : ""} ${debugHighlighting ? "debugHighlighting" : ""}`} 
    style={{background: fullBackground ?? "transparent"}}>
    <NavigationalOverlay
      page={currentPage}
      mode={headerMode}
      activeQuestion={activeQuestion}
      artOverride={currentPage?.type === "language-selector" ? "/images/AMA_md.png" : contextualArt}
      activeAnswer={activeAnswer}
      buttonState={headerButtonState}
      hideFade={!!fullPageModal}
      goBackExit={fullPageModal ? closeModal : navigateBack}
      goForward={completeQuestion}
      longPress={longPressHandler}
      useNeutralColor={fullBackground}/>
    {fullPageModal ? <StandardModalDialog {...fullPageModal} modalPage={subPageNumber} questionnaire={questionnaire} setPage={setPage_Overload} closeModal={closeModal} /> : null}
    
    {/* ============ STANDARD PAGE TYPES ============ */}
    <StandardPage />

    {/* ============ CUSTOM PAGE TYPES ============ */}
    {/* we will work to standardize these, but for now, here be dragons */}

    {currentPage.type === "question-unique" ?
      <div className="" style={{height: "100vh", width: "100%", overflowY: "hidden", paddingBottom: "33dvh"}}>
        {KNOWN_UNIQUES.includes(currentPage.uniqueType) ? null : <h3 className="text-danger">Unknown unique type: {safeStringify(currentPage.uniqueType)}</h3>}
        {currentPage.uniqueType === "chief complaint followup" ? 
          <div
            style={{width: "100%", height: "100vh"}}>
            <SingleQuestion
              page={currentPage}
              index={0}
              isActive={true}
              question={uniques_chiefComplaintFollowup}
              answer={answers["chief complaint"] ?? EMPTY_ANSWER}
              answerId={"chief complaint"}
              submitAnswerFor={(a, i) => acceptAnswer({...a, questionKey: "chief complaint unrefined"}, i)}
              complete={completeQuestion}
              // containerRef={questionRefs[i]}
              hiddenChoices={[]}
              trackingInfo={showTrackingInfo}
              keywordAction={(label, content) => setFullPageModal({type: "markdown", content})}
              displayInfoModal={(choice) => setFullPageModal({type: "markdown", content: choice.moreInfo, imgUrl: choice.imgUrl, title: choice.label ?? choice.value})}
              contextualResponse={null}/>
          </div>
        : null}
        {currentPage.uniqueType === "region of problem followup" ? 
          <div
            style={{width: "100%", height: "100vh"}}>
            <SingleQuestion
              page={currentPage}
              index={0}
              isActive={true}
              question={uniques_regionOfProblemFollowup}
              // textOverride={}
              answer={answers["region of problem"] ?? EMPTY_ANSWER}
              answerId={"region of problem"}
              submitAnswerFor={acceptAnswer}
              complete={completeQuestion}
              // containerRef={questionRefs[i]}
              hiddenChoices={[]}
              trackingInfo={showTrackingInfo}
              keywordAction={(label, content) => setFullPageModal({type: "markdown", content})}
              displayInfoModal={(choice) => setFullPageModal({type: "markdown", content: choice.moreInfo, imgUrl: choice.imgUrl, title: choice.label ?? choice.value})}
              contextualResponse={null}/>
          </div>
        : null}
      </div>
    : null}

    {currentPage.type === "question-modal" ?
      <div style={{width: "100vw", height: "100vh", position: "absolute", background: "#1fe191", backdropFilter: "blur(5px)", zIndex: 3}} onClick={e => ALLOW_BACKDROP_TO_CLOSE_MODAL ? setFullPageModal(null) : null}>
      <div style={{width: "calc(100vw - 20px)", height: "calc(100vh - 20px)", margin: "auto", background: "white", borderRadius: "8px", boxShadow: "", overflowY: "scroll", position: "absolute", top: "10px", left: "10px"}} onClick={e => e.stopPropagation()}>
        <div style={{margin: "60px 10px 10px 10px"}}>
          {MODAL_Q_CONFIGS[currentPage.modalType].prefix ? null : null}
          {MODAL_Q_CONFIGS[currentPage.modalType].questionsByPage[effectiveSubpage(subPageNumber, MODAL_Q_CONFIGS[currentPage.modalType])].map(spi => currentPage.questions[spi]).map((qID, i) => 
            <SingleQuestion
              key={loopIndexedAnswerId(qID, currentEffectiveSubloop)}
              page={currentPage}
              index={i}
              isActive={activeQuestionId === qID}
              question={questionnaire.questions[qID]}
              textOverride={null}
              answer={answers[loopIndexedAnswerId(qID, currentEffectiveSubloop)] ?? EMPTY_ANSWER}
              answerId={loopIndexedAnswerId(qID, currentEffectiveSubloop)}
              submitAnswerFor={acceptAnswer}
              complete={completeQuestion}
              // containerRef={questionRefs[i]}
              hiddenChoices={hiddenChoices[qID]}
              keywordAction={null}
              topPadding={false}
              containerStyles={currentEffectiveSubpage === 1 ? {minHeight: "50vh"} : null}
              contextualResponse={(MODAL_Q_CONFIGS[currentPage.modalType]?.finishCondition === "iterate-responses" && currentEffectiveSubpage === 1) ? iteratingModalContext(currentEffectiveSubloop, currentPage.modalType) : null}/>
            )}
          {(currentPage.modalType === "hospitalizations" && currentEffectiveSubpage === 1) ? <>
            <h3>Your hospitalizations:</h3>
              {ordinals(currentEffectiveSubloop + 1).map(li => <>
              <div style={{
                width: "calc(100% - 20px)",
                display: "grid",
                gridTemplateColumns: "[labels] 3fr [values] 4fr [annotations] 3fr [end]",
                gridTemplateAreas: `"header header header" "main main main"`,
                columnGap: "10px",
                rowGap: "5px",
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                margin: "10px"
              }}>
              <div style={{gridColumn: "labels / end"}}>{""}</div>
                <TextOnlyReportEntry 
                  label={`Hospitalization ${li+1}`}
                  usePrimaryColor={true}
                  customStyles={{paddingLeft: "15px"}}>
                  <button className="btn btn-inline float-right">Remove</button>
                  <button className="btn btn-inline float-right">Edit</button>
                </TextOnlyReportEntry>
                <AtomicAnswerReportEntry key={`${"reason for hospitalization"}§${li}`} answerId={`${"reason for hospitalization"}§${li}`} question={questionnaire.questions?.["reason for hospitalization"]} answer={answers[`${"reason for hospitalization"}§${li}`]}/>
                <AtomicAnswerReportEntry key={`${"date of hospitalization"}§${li}`} answerId={`${"date of hospitalization"}§${li}`} question={questionnaire.questions?.["date of hospitalization"]} answer={answers[`${"date of hospitalization"}§${li}`]}/>
              </div>
              </>)}
            <h3 className="mt-4">Have you been hospitalized at any other times?</h3>
            <QuestionResponse
              key={`special_has_more_hosps`}
              questionDefinition={MODAL_Q_CONFIGS.hospitalizations.q_hasMore}
              answer={{}}
              updateAnswer={updateSpecial_hosps}
              hiddenChoices={[]}
            />
          </> : null}

          {(currentPage.modalType === "surgeries" && currentEffectiveSubpage === 0) ? 
          <div className="viewer">
            <QuestionResponse
              key={`special_surgery_free_answer`}
              questionDefinition={MODAL_Q_CONFIGS.surgeries.q_freeAnswer}
              answer={{}}
              updateAnswer={updateSpecial_FA_surg}
              hiddenChoices={[]}/>
            </div> : null}
          {(currentPage.modalType === "drug use" && currentEffectiveSubpage === 0) ? 
          <div className="viewer">
            <QuestionResponse
              key={`special_drug_use_free_answer`}
              questionDefinition={MODAL_Q_CONFIGS['drug use'].q_freeAnswer}
              answer={{}}
              updateAnswer={updateSpecial_FA_drugs}
              hiddenChoices={[]}/>
            </div> : null}

            {(currentPage.modalType === "prescriptions" && currentEffectiveSubpage === 0) ? <div className="viewer main-column">
              <label className="btn" style={{width: "100%"}} for="image-upload"><img src="images/camera.png" style={{height: 24}} className="mx-2"/> Add a Picture</label>
              <input id="image-upload" type="file" style={{display: "none"}} accept="image/*" capture="environment" onChange={updateSpecial_medImg} />
              <div style={{display: "flex", flexWrap: "wrap"}}>
              {uploadedImages.map((isrc, ii) => <div style={{flex: "50%", width: "100%", paddingRight: ii % 2 === 0 ? "10px" : "", paddingLeft: ii % 2 === 1 ? "10px" : "", }}><img src={isrc} style={{width: "100%", maxWidth: "40vw", boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)", borderRadius: "5px"}} /></div>)}
              </div>
            </div>
            : null}

           {(currentPage.modalType === "vitamins supplements and probiotics" && currentEffectiveSubpage === 0) ? <div className="viewer main-column">
              <label className="btn" style={{width: "100%"}} for="image-upload"><img src="images/camera.png" style={{height: 24}} className="mx-2"/> Add a Picture</label>
              <input id="image-upload" type="file" style={{display: "none"}} accept="image/*" capture="environment" onChange={updateSpecial_medImg} />
              <div style={{display: "flex", flexWrap: "wrap"}}>
              {uploadedImages.map((isrc, ii) => <div style={{flex: "50%", width: "100%", paddingRight: ii % 2 === 0 ? "10px" : "", paddingLeft: ii % 2 === 1 ? "10px" : "", }}><img src={isrc} style={{width: "100%", maxWidth: "40vw", boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)", borderRadius: "5px"}} /></div>)}
              </div>
            </div>
            : null}

{(currentPage.modalType === "allergies" && currentEffectiveSubpage === 1) ? <>
            <h3>Your allergies:</h3>
              {ordinals(currentEffectiveSubloop + 1).map(li => <>
              <div style={{
                width: "calc(100% - 20px)",
                display: "grid",
                gridTemplateColumns: "[labels] 3fr [values] 4fr [annotations] 3fr [end]",
                gridTemplateAreas: `"header header header" "main main main"`,
                columnGap: "10px",
                rowGap: "5px",
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                margin: "10px"
              }}>
              <div style={{gridColumn: "labels / end"}}>{""}</div>
                <TextOnlyReportEntry 
                  label={`Allergy ${li+1}`}
                  usePrimaryColor={true}
                  customStyles={{paddingLeft: "15px"}}>
                  <button className="btn btn-inline float-right">Remove</button>
                  <button className="btn btn-inline float-right">Edit</button>
                </TextOnlyReportEntry>
                <AtomicAnswerReportEntry key={`${"allergen"}§${li}`} answerId={`${"allergen"}§${li}`} question={questionnaire.questions?.["allergen"]} answer={answers[`${"allergen"}§${li}`]}/>
                <AtomicAnswerReportEntry key={`${"reaction"}§${li}`} answerId={`${"reaction"}§${li}`} question={questionnaire.questions?.["reaction"]} answer={answers[`${"reaction"}§${li}`]}/>
                <AtomicAnswerReportEntry key={`${"allergy severity"}§${li}`} answerId={`${"allergy severity"}§${li}`} question={questionnaire.questions?.["allergy severity"]} answer={answers[`${"allergy severity"}§${li}`]}/>
              </div>
              </>)}
            <h3 className="mt-4">Do you have any other allergies?</h3>
            <QuestionResponse
              key={`special_has_more_hosps`}
              questionDefinition={MODAL_Q_CONFIGS.allergies.q_hasMore}
              answer={{}}
              updateAnswer={updateSpecial_hosps}
              hiddenChoices={[]}
            />
          </> : null}

          <div className="m-4" style={{position: "absolute", bottom: "5px", opacity: 0.5}}><u>{currentPage.modalType} {subPageNumber}/{MODAL_Q_CONFIGS[currentPage.modalType].subpages} loop:{currentEffectiveSubloop} pg:{currentEffectiveSubpage}</u></div>
        </div>
      </div>
    </div>
  : null}


    {showTrackingInfo ?
      <div style={{position: "absolute", left: "2em", bottom: "1em", color: "cadetblue", opacity: 0.75}}>
        <button className="btn btn-default btn-xs mt-0 mx-1" onClick={quitFn}>Quit</button>
        page: {pageNumber} ({`${currentPage?.type}`}) | loop: {`${loopIndex}`}/{`${loopLength}`} | subpage: {`${subPageNumber}`}~{`${currentEffectiveSubpage}`} | activeQ: {activeQuestionIndex}/{currentPage?.questions?.length} ({`${activeQuestionId}`}) | scroll: (-{activeQuestionIndex * 100}vh) | idle in {Math.floor((idleLogoutTimestamp() - Date.now())/1000)}s ({moment(idleLogoutTimestamp()).format("HH:mm:ss")}), last action was <u>{lastActionType}</u> at {moment(lastActionTimestamp.current).format("HH:mm:ss")}
      </div>
    : null}

    {(inactivityDialogActive) ?
      <div style={{position: "absolute", left: 0, top: 0, width: "100vw", height: "100vh", background: "rgba(256, 256, 256, 0.8)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 100}} onClick={() => setInactivityDialogActive(false)}>
        <div className="timer-dialog" style={{background: "rgb(31, 225, 145)", borderRadius: "20px", marginLeft: "20px", marginRight: "20px", maxWidth: "100vw", width: "500px", textAlign: "center", padding: "1em"}} onClick={e => e.stopPropagation()}>
          <h2>Are you still there?</h2>
          <p>Tap Yes to continue</p>
          <CountdownTimer timestamp={Date.now() + 60000} completionMessage={"Ending session..."}/>
          <button className="btn btn-light w-100" onClick={() => recordActivityForTimeout("manual_de_idle")}>Yes</button>
        </div>
      </div>
    : null}

    {(showQuitDialog) ?
      <div style={{position: "absolute", left: 0, top: 0, width: "100vw", height: "100vh", background: "rgba(256, 256, 256, 0.8)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 100}} onClick={() => setShowQuitDialog(false)}>
        <div className="timer-dialog" style={{background: "rgb(31, 225, 145)", borderRadius: "20px", marginLeft: "20px", marginRight: "20px", maxWidth: "100vw", width: "500px", textAlign: "center", padding: "1em"}} onClick={e => e.stopPropagation()}>
          <h2>Do you want to quit?</h2>
          <p>
            Both options will restart the kiosk mode, so that it is ready for
            the next patient.
          </p><p>
            Selecting "Save and Quit" will still create a report that can be
            viewed by staff. Selecting "Erase and Quit" will remove this 
            patient's data entirely, and should only be used when the collection
            was in error (such as a nonconsenting or nonqualifying study 
            participant) or is otherwise completely junk data.
          </p>
          <CTA onClick={() => providerQuit(true)} light={true}>Save and Quit</CTA>
          <CTA onClick={() => providerQuit(false)} style={{backgroundColor: "var(--salmon-red)"}}>ERASE and Quit</CTA>
        </div>
      </div>
    : null}
  </div>);
}
import React, { createRef, useState } from "react";
import { ImageWithFallback } from "../../../UI-old/ImageWithFallback";
import { JSObjectDump } from "../../../../utils";
import { isSelected, toggleChoice } from "../../../../models/choices";

export function ListChoiceCard({ item, handleClick, isSelected, style = {} }) {

  const subtextRef = createRef();
  const [expanded, setExpanded] = useState(false);

  function audioClick (event) {
    event.stopPropagation();
    event.preventDefault();
    // alert("aud")
  }

  function expandIfNeeded (event) {
    console.log({subtext: subtextRef.current});
    if (subtextRef.current?.scrollHeight > subtextRef.current?.clientHeight) {
      setExpanded(true);
      event.stopPropagation();
    } else if (expanded) {
      setExpanded(false);
      event.stopPropagation();
    }
  }

  return (
    <div
      className={`rect-img-card ${isSelected && "animated-fill"}`}
      key={item.label}
      onClick={() => handleClick(item, isSelected)}
      style={style}
    >
      <div className={`image-wrapper ${item.imgUrl ? "" : "no-image"}`}>
        {item.artComponentOverride 
          ? item.artComponentOverride
          : (item.imgUrl ? <ImageWithFallback src={`${item.imgUrl}`} alt={item.imgAlt} style={{height: "100%"}} /> : null)
        }
      </div>
      <div className="text-wrapper">
        <p className={item.description ? "with-subtext" : "no-subtext"}>{item.label}</p>
        {item.description ? <p className={`choice-subtext ${expanded ? "expanded" : ""}`} ref={subtextRef} onClick={expandIfNeeded}>{item.description}</p> : null}
      </div>
      <div className="icon-wrapper" onClick={audioClick}>
        <img src="/images/patient_redesign/speaker.svg" />
      </div>
    </div>
  );
}

export default function StackResponse({
  questionDefinition,
  answer,
  updateAnswer,
  hiddenChoices = [],
  showTrackingInfo = false
}) {
  function handleClick(item, isCurrentlySelected) {
    const updatedAnswerObj = toggleChoice(
      item,
      isCurrentlySelected,
      questionDefinition.choices,
      questionDefinition.isMulti,
      answer,
      {id: questionDefinition.id}
    );
    updateAnswer(updatedAnswerObj);
  }

  const responses = questionDefinition.choices.map((choice, index) => (
    <>
      {showTrackingInfo || !hiddenChoices.includes(index) || isSelected(index, questionDefinition, answer) ?  <ListChoiceCard
        key={choice.label}
        item={choice}
        handleClick={handleClick}
        isSelected={isSelected(index, questionDefinition, answer)}
        style={hiddenChoices.includes(index) ? {opacity: 0.5} : {}}
      /> : null}
      {showTrackingInfo ? 
        <>
        {choice.displayWhen ? <label>#{index} displayWhen: <JSObjectDump obj={choice.displayWhen}/></label> : null}
        {choice.skipWhen ? <label>#{index} skipWhen: <JSObjectDump obj={choice.skipWhen}/></label> : null}
        </>
      : null}
      </>
    ));
  
  return <div className="rect-img-card-container">{responses}</div>;
}

import React, { createRef, useEffect, useMemo, useState } from "react";
import { JSObjectDump, _switch, capitalizeByWord, ordinals, randomId, safeStringify, snakeToLowercase } from "../../../../utils";
import moment from "moment";
import { loopIndexedAnswerId } from "../../RedesignPrototypes";

export const SimpleLabel = ({label, question, style, answerId}) => {
  return <div style={{gridColumn: "labels", fontWeight: "bold", ...style}}>
    {label ?? question?.reportLabel ?? (question ? capitalizeByWord(snakeToLowercase(question?.id)) : (answerId ? `[${answerId}]` : ""))}
  </div>
}

export const AtomicAnswerReportEntry = ({answer, answerId, question, label, showEmpty}) => {
  const hasContent = !!answer && (answer.isMulti ? answer.values?.length > 0 : !(["", null, undefined].includes(answer.value?.value)));
  if (!hasContent) {
    if (showEmpty) {
      return <>
      <SimpleLabel label={label} question={question} answerId={answerId} />
      <div style={{gridColumn: "values / end", fontStyle: "italic"}}>--</div>
    </>;
    } else {
      console.log(`Skipping ${answerId}, no value`);
      return null;
    }
  }
  switch(question?.type) {
    case "date":
      return <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div style={{gridColumn: "values / end"}}>{moment(answer?.value?.value).format("YYYY-MM-DD")}</div>
        </>;
    default:
      if (question?.isMulti) {
      return <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div style={{gridColumn: "values / end"}}>{answer?.values?.map(v => <p>{v?.value}</p>)}</div>
        </>;
      }
      return <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div style={{gridColumn: "values / end"}}>{answer?.value?.value}</div>
        </>;
  }
}

export const TextOnlyReportEntry = ({text, label, noLabel, usePrimaryColor, customStyles, children}) => {
  if (noLabel) {
    return <div style={{gridColumn: "labels / end"}}>{children ?? text}</div>;
  }
  return <>
    <SimpleLabel label={label} style={{color: usePrimaryColor ? "#1fe191" : "auto", ...customStyles}}/>
    <div style={{gridColumn: "values / end"}}>{children ?? text}</div>
  </>
}

const debugStyles = {
  // background: "#D0FFDA",
  // border: "1px solid rgba(0,0,0,0.05)",
  // borderRadius: "12px",
  // padding: "12px"
};

const gridStyles = {
  display: "grid",
  gridTemplateColumns: "[labels] 3fr [values] 4fr [annotations] 3fr [end]",
  gridTemplateAreas: `"header header header" "main main main"`,
  columnGap: "10px", rowGap: "5px"
}

function EmergencyHardcodedReport (answers, structure, setEnabled) {
  const gridSetup = {
    display: "grid",
    gridTemplateColumns: "[left] 4fr [right] 4fr [end]",
    gridTemplateAreas: `"header header header" "main main main"`,
    columnGap: "10px", rowGap: "5px"
  }
  return (<div style={{padding: "5px"}}>
    <div style={{opacity: 0.3, position: "absolute", top: 5, left: 0, width: "100%", textAlign: "center"}} onClick={() => setEnabled(false)}>Toggle Custom Template</div>
    <h3>ED Report</h3>
    <div style={{...gridSetup}}>
      <div style={{gridColumn: "left", minHeight: "10rem", padding: "1rem", borderRadius: "0.5rem", background: "rgba(255, 200, 200, 0.25)"}}>Top Left</div>
      <div style={{gridColumn: "right", minHeight: "10rem", padding: "1rem", borderRadius: "0.5rem", background: "rgba(200, 255, 200, 0.25)"}}>Top Right</div>
      <div style={{gridColumn: "left / end", minHeight: "20rem", padding: "1rem", borderRadius: "0.5rem", background: "rgba(200, 200, 255, 0.25)"}}>Bottom</div>

    </div>
  </div>);
}

export const RedesignedReport = ({answers, user, questionnaireDefinition, reportType, submissionStatus, audience, acceptAnswer}) => {
  const [allowCustomTemplates, setAllowCustomTemplates] = useState(true);
  const [forceDisplayAll, setForceDisplayAll] = useState(null);
  function handleFDAChoice (event) {
    if (event.target.value === "answers") {
      setForceDisplayAll(event.target.value);
    } else if (event.target.value === "questions") {
      setForceDisplayAll(event.target.value);
    } else {
      setForceDisplayAll(null);
    }
  }

  useEffect(() => {
    if (!answers._reportID) {
      acceptAnswer({value: {value: randomId(5)}}, "_reportID");
    }
  }, [questionnaireDefinition]);

  const reportStructure = useMemo(() => {
    if (forceDisplayAll) {
      return ({
        title: `All-${forceDisplayAll} Dump`,
        mainContent: (forceDisplayAll === "questions" ? Object.keys(questionnaireDefinition.questions) : Object.keys(answers))
      })
    }
    if (!questionnaireDefinition
      || !Array.isArray(questionnaireDefinition.reports)
      || questionnaireDefinition.reports.length === 0) {
      return {
        title: "Load error",
        mainContent: []
      };
    }

    let foundReport = null;
    if (!reportType) {
      foundReport = questionnaireDefinition.reports.find(r => r.useForReview);
    } else {
      foundReport = questionnaireDefinition.reports.find(r => r.name === reportType);
    }

    if (foundReport) {
      return foundReport;
    } else {
      console.error(`Could not find report ${reportType || "designated for review"} among ${questionnaireDefinition.reports.length} options, using first entry`);
      return ({mainContent: [], ...questionnaireDefinition.reports[0]});
    }
  }, [questionnaireDefinition, reportType, forceDisplayAll]);

  const KNOWN_TEMPLATES = new Map([["emergency_A", EmergencyHardcodedReport]]);
  if (allowCustomTemplates && KNOWN_TEMPLATES.has(reportStructure?.template)) {
    return KNOWN_TEMPLATES.get(reportStructure?.template)(answers, reportStructure, setAllowCustomTemplates);
  } else {
    return (<div style={{padding: "5px", ...gridStyles, ...debugStyles}}>
      <div style={{opacity: 0.3, position: "absolute", top: 5, left: 0, width: "100%", textAlign: "center"}} onClick={() => setAllowCustomTemplates(true)}>Toggle Custom Template</div>
      <div style={{opacity: 0.3, position: "absolute", top: 30, left: 0, width: "100%", textAlign: "center"}}>
        <select style={{width: "320px", margin: "auto", border: "1px gray solid", borderRadius: 6, paddingLeft: 12}} onChange={handleFDAChoice}>
          <option value={null}>Use report as defined</option>
          <option value={'answers'}>Generate entries for all answers</option>
          <option value={'questions'}>Generate entries for all questions</option>
        </select>
      </div>
      <div style={{gridArea: "header", borderBottom: "1px solid rgba(0,0,0,0.1)", paddingBottom: "5px", marginBottom: "10px"}}>
        <h3>{reportStructure.title}</h3>
        {/* <em>{`FDA = ${forceDisplayAll}`}</em> */}
        {audience === "provider" ? <div style={{float: "right"}}><label style={{fontWeight: "bold", marginRight: "2em"}}>Report ID</label> <span style={{fontSize: "1.2rem", borderRadius: 16, padding: "4px 8px", background: "rgba(0,0,0,0.01)", boxShadow: "0 0 5px rgba(0,0,0,0.2)"}}>{answers?._reportID?.value?.value ?? "----"}</span></div> : null}
        <p><em>
          <span>by <u>{user.name ?? ((user.firstName ?? "") + " " + (user.lastName ?? ""))}</u></span>;{" "}
          {submissionStatus ? `submitted ${moment(submissionStatus.time).calendar()}` : "ready for submission"}
        </em></p>
      </div>
      {reportStructure.mainContent.map(e => typeof e === "string" ? ({type: "answer_atom", answerId: e}) : e).map(e => _switch(e.type,
          "text", <TextOnlyReportEntry {...e}/>,
          "answer_atom", <AtomicAnswerReportEntry {...e} question={questionnaireDefinition.questions?.[e.answerId]} answer={answers[e.answerId]} showEmpty={forceDisplayAll} />,
          <JSObjectDump obj={e}/>
        ))}
    </div>);
  }
} 

export const LoopSummaryPage = ({loopId, loopLength, page, questionnaire, answers, createEntry, removeEntry, jumpToEntry}) => {
  const [confirmingRemoval, setConfirmingRemoval] = useState(null);

  const loopDef = useMemo(() => {
    return questionnaire?.loops?.[loopId];
  }, [loopId, questionnaire]);

  const loopQuestions = useMemo(() => {
    if (!loopId || !Array.isArray(questionnaire?.pages)) return [];
    return questionnaire.pages.filter(p => p.loopId === loopId && p.type === "question").flatMap(p => p.questions);
  }, [loopId, questionnaire])

  const iterationsWithAnswers = useMemo(() => {
    const iterHasAnswer = new Set();
    loopQuestions.forEach(qId => {
      for (let i = 0; i < loopLength; i++) {
        const answer = answers[loopIndexedAnswerId(qId, i)];
        if (answer?.value?.value || answer?.values?.length) {
          iterHasAnswer.add(i);
        }
      }
    });
    return ordinals(loopLength).map(i => iterHasAnswer.has(i));
  }, [answers, loopQuestions, loopLength])

  const summaryImages = useMemo(() => {
    if (loopDef.summaryImageKey) {
      return ordinals(loopLength).map(i => {
        const aID = `${loopDef.summaryImageKey}§${i}`;
        if (aID in answers) {
          const index = answers[aID].value?.choiceIndex;
          if (Number.isSafeInteger(index)) {
            if (answers[aID].isExclusionary) {
              return questionnaire.questions[loopDef.summaryImageKey]?.exclusionaryChoices[index]?.imgUrl;
            }
            return questionnaire.questions[loopDef.summaryImageKey]?.choices[index]?.imgUrl;
          }
        }
        return null;
      });
    } else {
      return [];
    }
  }, [answers, loopDef, loopLength]);

  return (<div style={{width: "100%", ...gridStyles, ...debugStyles}}>
    <div style={{gridColumn: "labels / end"}}><h3>{page.text}</h3></div>
  {loopLength > 0 ? ordinals(loopLength).map(i =>
    <>
    <TextOnlyReportEntry 
      label={loopDef.unit ? loopDef.unit.replace("<N>", i+1) : `Entry ${i+1}`}
      usePrimaryColor={true}
      customStyles={{paddingLeft: "15px"}}>
      {loopDef.allowDeletion ? (confirmingRemoval === i ? 
        <button className="btn btn-danger btn-inline float-right" onClick={() => {removeEntry(i); setConfirmingRemoval(null)}}>Confirm Removal?</button> :
        <button className="btn btn-inline float-right" onClick={() => setConfirmingRemoval(i)}>Remove</button>)
      : null}
      {loopDef.allowJumps ? <button className="btn btn-inline float-right" onClick={() => jumpToEntry(i)}>Edit</button> : null}
    </TextOnlyReportEntry>
    {summaryImages?.[i] ? <img style={{gridColumn: "values", maxWidth: "200px"}} src={summaryImages?.[i]}/> : null}
    {iterationsWithAnswers[i] ? loopQuestions.map(qid => 
      <AtomicAnswerReportEntry key={`${qid}§${i}`} answerId={`${qid}§${i}`} question={questionnaire.questions?.[qid]} answer={answers[`${qid}§${i}`]}/>
    ) : <div><em>No answers in this loop yet</em></div>}
    </>
  ) : <TextOnlyReportEntry
        label={loopDef.unit ? `No ${loopDef.unit.replace(/<N>|#/g, "").toLocaleLowerCase()}` : `No entries`}
        usePrimaryColor={true}
        customStyles={{paddingLeft: "15px"}}/>}
  {loopDef.type === "user-extensible" ? 
    <button className="btn btn-primary" style={{gridColumn: "labels / end"}} onClick={createEntry}>{page?.addEntryLabel ?? "Add Another Entry"}</button>
  : null}
  <em style={{opacity: 0.5, gridColumn: "values / end"}}>(This loop is of type <tt>{loopDef.type}</tt>)</em>
</div>);
} 
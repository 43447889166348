import "./cards.css";

export const Card = ({stack = false, isSelected, ...props}) => (
  <div {...props} className={`card ${stack ? "stack-card" : "grid-card"} ${isSelected ? "selected" : ""} ${props.className ?? ""}`}>
    <div className="card-art">
      <img src={props.art}/>
    </div>
    <div className="card-text-container">
      <div className="card-title answer-text">
        {props.title}
      </div>
      <div className="card-description answer-text">
        {props.description}
      </div>
    </div>
  </div>
);

/*

{item.moreInfo ? <div className="icon-wrapper" style={{fontSize: "20px", borderRadius: "18px", background: "aliceblue", width: 36, height: 36}} onClick={e => {handleIconClick(); e.stopPropagation();}}>
        <span className="fa fa-info"></span>
      </div> : null}

      */
import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { default as alerts } from "./slices/alerts";
import { default as audio, audioMiddleware } from "./slices/audio";
import { default as questionnaire } from "./slices/questionnaire-old.js";
import { combineReducers } from "redux";


// const bindMiddleware = middleware => {
//   if (process.env.NODE_ENV !== 'production') {
//     const { composeWithDevTools } = require('redux-devtools-extension')
//     return composeWithDevTools(applyMiddleware(...middleware))
//   }
//   return applyMiddleware(...mi ddleware)
// }

const appReducer = combineReducers({
  alerts,
  audio,
  questionnaire,
});

export const ACTION_CLEAR_ALL = "!! clear all"

const rootReducer = ( state, action ) => {
  // master "reset" action to wipe state
  if ( action.type === ACTION_CLEAR_ALL) {
    state = undefined;
  }
  return appReducer(state, action)
}

/**
 * Run all actions that are needed when starting the Redux store, and any other
 * generic application initialization.
 *
 * @param {import("@reduxjs/toolkit").EnhancedStore} store
 * @returns {void}
 */
const defaultStartup = (store) => {
  // store.dispatch(verifyAuth()).then((userData) => {
  //   console.warn("UD", userData);
  // });
};

export const initStore = ( persistedState, startupFn = defaultStartup ) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,

    // this default in prod is just [thunk] and in development it is
    // [thunk, immutableStateInvariant, serializableStateInvariant]
    // see https://redux-toolkit.js.org/api/getDefaultMiddleware for details
    // getDefaultMiddleware => getDefaultMiddleware().concat([/* additions */])

    middleware: getDefaultMiddleware => [thunkMiddleware, audioMiddleware]
  });

  // dispatch initial events
  startupFn(store);

  return store;
}

import React from "react";
import "../questionnaire.css";
import { JSObjectDump, safeStringify } from "../../../../utils";
import { useSelector } from "react-redux";

function HighlightedKeywords ({text, keywordList, action}) {
  let segments = [text ?? ""];
  for (let [keyword, content] of Object.entries(keywordList)) {
    segments = segments.flatMap(segment => {
      if (typeof segment === "string") {
        if (segment.includes(keyword)) {
          const start = segment.indexOf(keyword);
          const end = start + keyword.length;
          return [
            segment.slice(0, start),
            {content, label: keyword},
            segment.slice(end)
          ];
        }
      }
      return [segment];
    });
  }
  return <>
    {segments.map(
      (segment, i) => typeof segment === "string" ?
        <span key={`plaintext:${i}`}>{segment}</span> : 
        <span key={`link:${segment.label}`} className="highlight-interactive-text" onClick={() => action(segment.label, segment.content)}>{segment.label}</span>
    )}
  </>
}

const GLOBAL_KEYWORDS = {"TEST STRING ": "test complete"}

const SHOW_FORMULAS_DEBUG = false;

function stringifySuperflat (obj,customReplacements) {
  const jsonlikeRemoved = safeStringify(obj, 0).replace(/[[\]"\n,{}:]/g, "");
  if (customReplacements) {
    return jsonlikeRemoved.replace(customReplacements, "");
  } else {
    return jsonlikeRemoved;
  }
}

export default function QuestionPrompt({text, subtext, contextualResponse, keywordList, id, keywordAction}) {
  const fullList = {...GLOBAL_KEYWORDS, ...keywordList};
  return (
    <header className="main-column">
      <div className="question">
        {/* What <span className="highlight-interactive-text">ice-cream flavor</span> is your favorite? */}
        {text ? 
          (Array.isArray(text) ? 
            (SHOW_FORMULAS_DEBUG ? <JSObjectDump obj={{textFormula: text}}/> : stringifySuperflat(text, /CONCAT|LOWERCASE|=|_string|\?(?=\w)/g))
          : <HighlightedKeywords text={text} keywordList={fullList} action={keywordAction}/>)
        : `Question ${id ?? "«unknown»"} has no text`}
        {("*" in fullList) ? <span className="bold-interactive-text" onClick={() => keywordAction("*", fullList["*"])}>
        {" "} Learn more.
        </span> : null}
        {contextualResponse ? <>{"\u2001"}<span className="contextual-label">{contextualResponse?.value?.value}</span></> : null}
      </div>
      {subtext ? <div className="text-muted mb-2 question-subtext">{subtext}</div> : null}
    </header>
  );
}

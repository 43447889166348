import { useState } from "react";
import { FaVolumeUp } from "react-icons/fa";

const PLAY_STATES = ["init", "ready", "playing", "NOT FOUND", "PLAYBACK ERROR"];
const PlayStateColor = {
  init: "gray",
  ready: "black",
  playing: "green",
  "NOT FOUND": "brown",
  "PLAYBACK ERROR": "red"
}
export function AudioButton ({asset}) {
  const [playState, setPlayState] = useState("ready")
  function clickAudio () {
    if (playState === "ready") {
      console.log("Simulating play...");
      setPlayState("playing");
    } else {
      setPlayState("ready");
    }
  }
  return (
    <div
      className={`chrome-btn centered-btn ${playState === "playing" ? "pulsing" : ""}`}
      style={{float: "right", margin: "20px", pointerEvents: "visible", color: PlayStateColor[playState] ?? "yellow"}}
      onClick={clickAudio}>
      {/* <img src="/images/patient_redesign/speaker.svg" style={{display: "block"}}/> */}
      <FaVolumeUp />
    </div>
  )
}
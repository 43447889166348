import { useState } from "react";
import { CTA } from "../../../UI/buttons/CTA";
import moment from "moment";
import "./debug.css";
import { oneline, randomId } from "../../../../utils";


function randTimestampInLastWeek () {
  return Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 7);
}
function randComplaint () {
  return [
    "Chest pain",
    "Trouble breathing",
    "Cough",
    "Vomiting",
    "Nausea",
    "Diarrhea",
    "Urinary problem",
    "Genital problem",
    "Headache",
    "Abdominal pain",
    "Pelvic pain",
    "Muscles, bone, or joint pain "
  ][Math.floor(Math.random()*12)];
}
function randSex () {
  return Math.random() > 0.5 ? "Female" : "Male";
}
function randAge () {
  return Math.floor((Math.random() + Math.random()) * 53);
}
function randEntry () {
  return ({
    date: randTimestampInLastWeek(),
    status: Math.random() > 0.8 ? 0 : 1,
    age: randAge(),
    sex: randSex(),
    cc: randComplaint(),
    id: randomId(4)
  });
}
const SAMPLE_DASHBOARD_DATA = [];
let recordCount = Math.floor(Math.random() * 8) + 8;
for (let i = 0; i < recordCount; i++) {
  SAMPLE_DASHBOARD_DATA.push(randEntry());
}

const SampleLogin = ({advance}) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  function fakeLogin () {
    console.log({password});
    if (password.startsWith("z")) {
      advance()
    } else {
      alert("Incorrect username and/or password! \n (DEBUG MODE: password needs to start with 'z' to succeed)");
    }
  }

  return (
    <div className="viewer main-columm" style={{ minHeight: "100vh", justifyContent: "center" }}>
      <div className="raised-box" style={{}}>
        <h4>Welcome to the <u>Valleywise Emergency Department</u> portal for LiteraSeed.</h4>
        <p>
          This area of the application is only intended for providers and staff of
          the above organization. Please log in below. If you are having difficulty
          logging in, please contact LiteraSeed directly.
        </p>
        <hr style={{marginBottom: "2em"}}/>
        <div>
          <label>Username/Email</label>
          <input type="text" placeholder="email"></input>
        </div>
        <div style={{marginTop: "0.5em"}}>
          <label>Password</label>
          <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)}></input>
        </div>
        <CTA label="Log In" style={{width: "100%", marginTop: "2em"}} light={false} onClick={fakeLogin}/>
      </div>
    </div>
    );
}

const SampleDashboard = ({advance}) => {

  const [tableData, setTableData] = useState(SAMPLE_DASHBOARD_DATA.slice().sort((a, b) => a.date - b.date));

  function sortTable (key, numeric = false) {
    let newTable = tableData.slice();
    if (numeric) {
      newTable.sort((a, b) => a[key] - b[key]);
    } else {
      newTable.sort((a, b) => `${a[key]}`.localeCompare(`${b[key]}`));
    }
    setTableData(newTable);
  }

  function reroll () {
    const newTable = [];
    const count = Math.floor(Math.random() * 8) + 8;
    for (let i = 0; i < count; i++) {
      newTable.push(randEntry());
    }
    setTableData(newTable);
  }

  async function copyToClipboard (text) {
    // const type = "text/plain";
    // const blob = new Blob([text], { type });
    // const data = [new ClipboardItem({ [type]: blob })];
    // await navigator.clipboard.write(data);
    
    await navigator.clipboard.writeText(text);
    alert(`Copied "${text}" to your clipboard!`)
  }

  function startKioskFlow () {
    const confirmed = 
      window.confirm(
        oneline`You are about to enter the kiosk flow. From here onward, the app
        can only be used to collect patient data until you log out and back in
        again. Are you ready to begin?`);
    if (confirmed) {
      advance();
    }
  }

  function viewReport (id) {
    alert(`You cannot view report ${id} because it is a fake stub generated for prototyping!`)
  }

  return (<div className="viewer" style={{ minHeight: "100vh", justifyContent: "center" }}>
    <div className="raised-box" style={{maxWidth: "1000px", margin: "3em auto"}}>
      <div className="flex-row">
        <div id="left-text" style={{width: "calc(100% - 320px)", paddingRight: "2em"}}>
          <p>
            This is the LiteraSeed provider dashboard, where you can review data
            submitted by patients to the study. To begin a patient flow, you may
            use the button at the right, however to prevent exposing data to
            patients you will have to log in again to return to this screen.
          </p>
        </div>
        <div style={{alignSelf: "center"}} className="flex-column flex-align-center">
          <CTA light={false} label="Launch Kiosk Mode" style={{minWidth: "300px", fontSize: "18px"}} onClick={startKioskFlow}/>
          <CTA label="Randomize Table" style={{background: "#ffd835", padding: "5px 10px", height: "40px", width: "175px", fontSize: "12px"}} onClick={reroll} />
        </div>
      </div>
      <h2 style={{marginLeft: "4em"}}>Patient Reports</h2>
      <table className="dashboard-table">
        <thead>
          <tr>
            <th onClick={() => sortTable('age', true)}>Age</th>
            <th onClick={() => sortTable('sex')}>Sex</th>
            <th onClick={() => sortTable('cc')}>Chief Complaint</th>
            <th colSpan="2" onClick={() => sortTable('date', true)}>Timestamp</th>
            <th onClick={() => sortTable('id')}>Report ID</th>
            <th onClick={() => sortTable('status', true)}>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData.map(row => <tr>
            <td className="text-center">{row.age}</td>
            <td>{row.sex}</td>
            <td style={{whiteSpace: "nowrap",overflow: "hidden", textOverflow: "ellipsis", maxWidth: "190px"}}>{row.cc}</td>
            <td>{moment(row.date).format("MMM D")}</td>
            <td>{moment(row.date).format("h:mma")}</td>
            <th className="monospace" onClick={() => copyToClipboard(row.id)}>{row.id}</th>
            <td>{row.status === 1 ? <strong>finished</strong> : <em>quit early</em>}</td>
            <td><button onClick={() => viewReport(row.id)}>View Report</button></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </div>);
}

export const DebugPage = ({debugType, ...props}) => {
  console.warn(props);
  console.warn(debugType);
  switch (debugType) {
    case "login":
      return <SampleLogin {...props}/>;
    case "dashboard":
      return <SampleDashboard {...props}/>;
    default:
      return <h3>Unknown debug page type {debugType}</h3>;
  }


};

import React from "react";
import { connect } from "react-redux";
import { PrototypeDemoBase } from "./components/Redesign/RedesignPrototypes";
import { GlobalAlerts } from "./components/UI-old/GlobalAlerts";
import "./styles/common.scss";

import "./styles/redesign/colors.css";
import "./styles/redesign/typography.css";
import "./styles/redesign/flow.css";
import "./styles/redesign/utility.css";
import "./styles/redesign/bootstrap-highlighter.css";

const App = () => {
  return (
  <>
    <PrototypeDemoBase/>
    <GlobalAlerts/>
  </>
  );
}

function mapStateToProps(state, ownProps) {
  return {

  };
}

export default connect(mapStateToProps)(App);

import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { adjacentPairs, safeStringify, spliceOutOf } from ".";

/**
 * Quick hook for getting access to search parameters.
 * NOTE: URLSearchParams objects do not support bracket or dot access. You must
 * instead use `paramObject.get("KEYNAME")`
 *
 * @returns URLSearchParams the parsed parametes
 */
export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Quick local state wrapper to create sets designed to be updated by appending
 * only. Instead of a setter fn, the 2nd returned value is an 'append' function
 * that takes the item or items (as an array) to be added.
 *
 * @returns [current set, append function]
 */
export function useAppendingSet() {
  const [baseSet, saveNewSet] = useState(new Set());
  const append = (newItem) => {
    if (Array.isArray(newItem)) {
      newItem.forEach(i => baseSet.append(i));
    } else {
      baseSet.add(newItem);
    }
    saveNewSet(new Set(baseSet));
    return baseSet.size;
  }
  return [baseSet, append];
}

/**
 * A version of useState for booleans that returns an object with pre-made
 * functions for setting to true / false, and for "toggling" the value.
 * Basically it's a flip-flop circuit in hook format.
 *
 * @param {boolean} init The initial value
 * @returns {{value: boolean, on: () => void, off: () => void, toggle: () => void}}
 */
export function useBooleanState(init) {
  const [value, setBool] = useState(!!init);
  return {
    value,
    on: () => setBool(true),
    off: () => setBool(false),
    toggle: () => setBool(!value)
  };
}

/**
 * Simplify creation and attachment of simple keyboard event handlers
 * @param {Array<string|function>} list Alternating entries of keys and associated callbacks to run
 * @param {string} eventName Event to listen for, by default "keyup"
 * @param {() => boolean} attachmentCondition Predicate to determine if listener should be attached each update
 */
export function useKeyboardEvents (list, eventName = "keyup", attachmentCondition, watchItems = [], onlyFirst = false) {
  if (list.length % 2 === 1) throw new Error("List must be alternating pairs of keys/predicates and handlers");
  // transform the left entry to always be a predicate fn
  const eventMatches = adjacentPairs(list).map(([keyOrFn, handler]) => {
    if (typeof keyOrFn === "function") return [keyOrFn, handler];
    if (typeof keyOrFn === "string") {
      let modifiers = keyOrFn.split(".");
      return [
        ev => modifiers.slice(0, -1).every(m => ev[`${m}Key`]) && ev.key === modifiers[modifiers.length - 1],
        handler
      ];
    }
    throw new Error(`Key comparisons should be predicates or strings, got ${keyOrFn} (${typeof keyOrFn})`);
  });
  const listener = keyboardEvent => {
    for (let [keyPredicate, handler] of eventMatches) {
      if (keyPredicate(keyboardEvent)) {
        handler(keyboardEvent);
        if (onlyFirst) {
          return;
        }
      }
    }
  };
  useEffect(() => {
    if (!attachmentCondition || attachmentCondition()) {
      // console.warn("attaching keyboard listener!");
      window.addEventListener(eventName, listener);
      return () => {
        // console.warn("removing keyboard listener!");
        window.removeEventListener(eventName, listener);
      }
    }
  }, watchItems);
}

/**
 * CollectionView was our manual sorting and filtering library before we chose
 * to use MUI DataGrids as a base
 */
// export const CVDefaults = {
//   StringSort: (a, b) => {
//     if (typeof a !== 'string')
//       return Number.MAX_SAFE_INTEGER;
//     if (typeof b !== 'string')
//       return Number.MIN_SAFE_INTEGER;
//     return a.localeCompare(b);
//   },
//   NumberSort: (a, b) => {
//     if (typeof a !== 'number')
//     return Number.MAX_SAFE_INTEGER;
//     if (typeof b !== 'number')
//     return Number.MIN_SAFE_INTEGER;
//     return a-b;
//   },
//   MomentSort: (a, b) => {
//     if (a === null || a === undefined) {
//       return -1;
//     }
//     if (b === null || b === undefined) {
//       return 1;
//     }
//     return moment(a).valueOf() - moment(b).valueOf();
//   },
//   LOG: (sorter) => (a, b, ctx) => {
//     let result = sorter(a, b, ctx);
//     console.log(`compared ${a} and ${b} with context ${ctx} and got ${result}`);
//     return result;
//   },
//   With: (sorter, selector) => (a, b, ctx) => sorter(selector(a, ctx), selector(b, ctx), ctx),
//   CNot: (sorter, invert) => (a, b, ctx) => invert === 1 ? sorter(b, a, ctx) : sorter(a, b, ctx),
// }

// export function useCollectionView(source, fields, filters, init) {
//   init = init || {};
//   const initialState = Object.assign({
//     sorted: 0,
//     sortingField: null,
//     sortingDirection: 0,
//     activeFilters: [],
//     source: source || []
//   }, {sorted: !!init.sortingField}, init);
//   function reapply (obj) {
//     let filtered = obj.source;
//     for(let f of obj.activeFilters) {
//       filtered = applyFilterTo(f, filtered)
//     }
//     obj.filtered = filtered;
//     if (obj.sorted) {
//       obj.view = applySortTo(obj.sortingField, obj.sortingDirection, filtered);
//     } else {
//       obj.view = filtered.slice();
//     }
//     return obj;
//   }
//   const [cv, setCV] = useState(reapply(initialState));
//   function applyFilterTo (filterNameOrTuple, arr) {
//     if (!Array.isArray(filterNameOrTuple)) {
//       filterNameOrTuple = [filterNameOrTuple, []];
//     }
//     const [filterName, filterArgs] = filterNameOrTuple;
//     if (!(filterName in filters)) {
//       throw new Error(`Could not find filter ${filterName} in Collection View`)
//     }
//     if (!Array.isArray(arr)) {
//       console.warn(`Could not apply filter to non-array: ${arr}`);
//       return [];
//     }
//     const filter = filters[filterName];
//     return arr.filter((value, index) => filter(value, index, ...(filterArgs || [])));
//   }
//   function applySortTo (fieldName, direction, arr) {
//     if (!(fieldName in fields)) {
//       throw new Error(`Could not find field ${fieldName} in Collection View`);
//     }
//     if (!Array.isArray(arr)) {
//       console.warn(`Could not apply sort to non-array: ${arr}`);
//       return [];
//     }
//     const field = fields[fieldName];
//     const sortFn = field.directionStates ? field.sort(direction) : CVDefaults.CNot(field.sort, direction);
//     return arr.slice().sort((a, b) => sortFn(a, b));
//   }
//   function sortViewBy (fieldName, direction) {
//     const sortedView = applySortTo(fieldName, direction, cv.filtered);
//     setCV({
//       ...cv,
//       sorted: true,
//       sortingField: fieldName,
//       sortingDirection: direction,
//       view: sortedView
//     });
//   }
//   function setActiveFilters (newActiveFilters) {
//     setCV(reapply({...cv, activeFilters: newActiveFilters}))
//   }
//   useEffect(() => {
//     setCV(reapply({...cv, source: source || []}));
//   }, [source]);
//   const toggler = (fieldName) => () => {
//     if (!(fieldName in fields)) {
//       throw new Error(`Could not find field ${fieldName} in Collection View`);
//     }
//     const field = fields[fieldName];
//     const directionStates = field.directionStates || 2;
//     if (fieldName === cv.sortingField) {
//       const nextDirection = (cv.sortingDirection + 1) % directionStates;
//       sortViewBy(fieldName, nextDirection);
//     } else {
//       sortViewBy(fieldName, 0);
//     }
//   }
//   const icon = (field, direction) => {
//     if (field?.directionStates) {
//       if (direction < 19)
//         return String.fromCodePoint(0x2490 + direction);
//       return `${direction}`;
//     }
//     return direction === 0 ? "↓" : "↑";
//   }
//   const activeIcon = () => icon(fields[cv.sortingField], cv.sortingDirection);
//   const sortLinker = (fieldName, label) =>
//     <><span className={`sortable ${cv.sortingField === fieldName ? "current-sort" : ""}`} onClick={toggler(fieldName)}>
//       {label || fieldName}
//     </span> {cv.sortingField === fieldName ?  activeIcon() : null}</>
//   return [cv.view, toggler, sortLinker, sortViewBy, setActiveFilters, cv];
// }

export function useSize (debugLogs) {
  const ref = useRef();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  useEffect(() => {
    const obs = new ResizeObserver((entries) => {
      if (debugLogs) {
        console.log({resize: entries});
        console.log("new width(s): " + entries.map(e => e.contentRect?.width).join(" "));
      }
      const entry = entries[0];
      if (entry.contentRect) {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
      }
    })
    obs.observe(ref.current)
    return () => {
      if (ref.current) obs.unobserve(ref.current)
    };
  }, []);
  return [ref, width, height];
}

export function useChangeDebugging (watchItems, label) {
  const cached = useRef({run: 0, values: []});
  useEffect(() => {
    let firstThisLoop = true;
    const entries = Object.entries(watchItems);
    for (let i = 0; i < entries.length; i++) {
      if (cached.current.values[i] !== entries[i][1]) {
        if (firstThisLoop) {
          console.log(`### CD ${label} ${cached.current.run}`);
          firstThisLoop = false;
        }
        console.log(` |  ${entries[i][0]}`);
        if (cached.current.run === 0) {
          console.log(` ⮑ ∅ → ${safeStringify(entries[i][1])}`);
        } else {
          console.log(` ⮑ ${safeStringify(cached.current.values[i])} → ${safeStringify(entries[i][1])}`);
        }
      }
      cached.current.values[i] = entries[i][1];
    }
    cached.current.run++;
  });
}
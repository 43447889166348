import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { determineSource } from "./DynamicImage";

// data URI for the 1x1 transparent GIF, but we'll use a local file for now
const TOPG = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

export const ImageWithFallback = ({src, style, alt, useGradientForEmpty = null, ...props}) => {
  const [fallback, setFallback] = useState(useGradientForEmpty);
  const bodyType = useSelector(s => s.questionnaire.bodyTypePreference);
  const source = useMemo(() => determineSource(src, bodyType), [src, bodyType]);

  useEffect(() => {
    setFallback(useGradientForEmpty);
  }, [src, bodyType])

  function imageFailed (event) {
    console.warn(`Couldn't display image '${source}'`, event);
    setFallback(true);
  }

  /**
   * Generate a pair of hex bytes within a given range
   */
  function randHexPair (min = 0, max = 256) {
    return (Math.floor(Math.random() * (max-min)) + min).toString(16);
  }
  // We use a very simple placeholder generation algorithm, where we make a
  // smooth CSS linear gradient between two random light (>75%) colors.
  const colorA = `#${randHexPair(192)}${randHexPair(192)}${randHexPair(192)}`;
  const colorB = `#${randHexPair(192)}${randHexPair(192)}${randHexPair(192)}`;
  const angle = `${Math.floor(Math.random() * 360)}deg`;
  const [gradient] = useState(`linear-gradient(${angle}, ${colorA} 0%, ${colorB} 100%)`);

  return <img
    src={fallback ? "/images/Transparent1x1.gif" : source}
    alt={fallback ? `gradient texture for a broken image (${alt})` : alt}
    onError={e => imageFailed(e)}
    style={fallback ? {background: gradient, minHeight: "50px", minWidth: "50px", ...style} : style}
    {...props}
    />
}
import { Answer } from "./answers";
import { IndexedString } from "./core-data-types";
import { QuestionDefinition, ResponseChoice } from "./questions";



/**
 * Function to evaluate if a specific choice should be displayed as selected,
 * based on whether that choice is already a part of the answers.
 **/
export function isSelected (choiceIndex : number, questionDefinition : QuestionDefinition, answer : Answer<IndexedString>) {
  if (answer.isExclusionary) return false;
  if (questionDefinition.isMulti) {
    return !!answer?.values?.find(value => value.choiceIndex === choiceIndex);
  } else {
    return answer?.value?.choiceIndex === choiceIndex;
  }
}

export function toggleChoice (item: ResponseChoice, isCurrentlySelected: boolean, choices: ResponseChoice[], isMulti : boolean, answer: Answer<any>, additionalProps = {}) {
  const choiceIndex = choices.findIndex(choice => choice.value === item.value);
  if (isMulti) {
    const newValues = Array.isArray(answer.values) ? answer.values.slice() : [];
    // or, using optional chaining and nullish coalescing
    // (left for learning purposes, we can use the top since it's very clear)
    // const newValues = answer.values?.slice() ?? [];

    // use object destructuring to trim out possible exclusionary answer
    const {value: oldValue, isExclusionary: _, ...answerPartial} = answer;

    if (isCurrentlySelected) {
      const removalIndex = answer.values.findIndex(value => value.choiceIndex === choiceIndex);
      newValues.splice(removalIndex, 1);
      return ({...answerPartial, isMulti, values: newValues});
    } else { // value isn't in answer
      newValues.push({ value: item.value, choiceIndex});
      return ({...answerPartial, isMulti, values: newValues});
    }
  } else {
    return ({
      isMulti: false,
      value: {
        value: item.value,
        choiceIndex
      },
      ...additionalProps // this will usually be used to set ID, or isExclusionary
    });
  }
}

export const hash = (data) => {

  let dataStr = data.toString();
  let hashVal, i, chr = 0;

  for (i = 0; i < dataStr.length; i++) {
    chr   = dataStr.charCodeAt(i);
    hashVal  = ((hashVal << 5) - hashVal) + chr;
    hashVal |= 0; // Convert to 32bit integer
  }
  return hashVal;
}

export function capitalizeFirstLetter(aString) {
  if (typeof aString === "string") {
    return aString.charAt(0).toUpperCase() + aString.slice(1);
  } else {
    // if it is not a string, pass it through so we don't trigger extra errors
    return aString;
  }
}

export function capitalizeByWord(aString) {
  if (typeof aString === "string") {
    return aString.toLocaleLowerCase().replace(/(?:^|\s|["'([{])+\S/g, c => c.toUpperCase());
  } else {
    // if it is not a string, pass it through so we don't trigger extra errors
    return aString;
  }
}

export function snakeCase (str) {
  if (typeof str === "string") {
    return str.toLocaleLowerCase().replace(/ /g, "_");
  }
  return str
}

export function snakeToLowercase (str) {
  if (typeof str === "string") {
    return str.toLocaleLowerCase().replace(/_/g, " ");
  }
  return str
}

export function equalCaseInsensitive (a, b, trim = false) {
  const maybeTrim = trim ? s => s.trim() : s => s;
  return (typeof a === "string" ? maybeTrim(a).toLocaleLowerCase() : a) === (typeof b === "string" ? maybeTrim(b).toLocaleLowerCase() : b);
}

export function shortOrdinal(num) {
  if (Math.floor(num / 10) % 10 === 1) {
    return `${num}th`;
  }
  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
}

export function NullsToBottom(a, b, valueOrCallback) {
  if (a === null || a === undefined) {
    return -1;
  }
  if (b === null || b === undefined) {
    return 1;
  }
  if (typeof valueOrCallback === "function") {
    return valueOrCallback(a, b);
  }
  return valueOrCallback;
}

export const successWithPayloadAction = type => payload => ({type, payload});
export const errorAction = type => error => ({type, error});

export function cloneWithIndicesAsValues (obj) {
  if (typeof obj !== 'object') return obj;
  return Object.fromEntries(Object.keys(obj).map((k, i) => [k,i]));
}

export function dot (path, object, missing = undefined) {
  let parts = path.split(".");
  for (let part of parts) {
    if (typeof object === "object" && object !== null && (part in object)) {
      object = object?.[part];
    } else {
      return missing;
    }
  }
  return object;
}
export function dotSet (path, object, value) {
  let parts = path.split(".");
  for (let part of parts.slice(0, -1)) {
    if (typeof object === "object" && object !== null && (part in object)) {
      object = object?.[part];
    } else {
      return false;
    }
  }
  if (typeof object === "object" && object !== null) {
    object[parts[parts.length - 1]] = value;
    return true;
  } else {
    return false;
  }
}

export function isValidDate (d) {
  return d instanceof Date && !Number.isNaN(d.valueOf());
}

export function parseSemver (s) {
  const annotationSeparator = s.search(/-|\+/);
  const versionCore =
    (annotationSeparator > -1 ? s.slice(0, annotationSeparator) : s)
      .split(".").map(n => Number.parseInt(n, 10));
  if (annotationSeparator > -1) {
    return versionCore.concat(s.slice(annotationSeparator));
  }
  return versionCore;
}

export function compareSemver (a, b) {
  a = parseSemver(a);
  b = parseSemver(b);
  for (let i = 0; i < 3; i++) {
    let diff = a[i] - b[i];
    if (diff !== 0) return diff;
  }
  // we don't have a canonical way to compare any build / prerelease labels,
  // so at this point just consider them equal
  return 0;
}

// "Reverse LookUp" helper -- quick method to swap keys and values
// This is only meant as a holdover until we have Typescript and can use better
// structures like reversible enums or just decent definition classes.
export function rlu (obj) {
  return Object.fromEntries(Object.entries(obj).map(([k,v]) => [v, k]));
}

// quick random ID with no guarantees about collisions
// TODO: upgrade to UUID
const BASE_32 = "0123456789abcdefhjkmnpqrstuvwxyz";
const BASE_32_NUM_BIASED = "01234567890123456789abcdefhjkmnpqrstuvwxyz";
export function randomId (size, alphabet = BASE_32_NUM_BIASED) {
  let arr = new Array(size);
  return arr.fill("").map(_ => alphabet[Math.floor(Math.random() * alphabet.length)]).join("").toLocaleUpperCase();
}

export function CountingSet () {
  const map = new Map();
  return {
    add: (item) => {
      if (map.has(item)) {
        map.set(item, map.get(item) + 1)
      } else {
        map.set(item, 1);
      }
    },
    get: (item) => {
      return map.get(item);
    },
    counts: () => Object.fromEntries(map.entries())
  }
};

export function _switch (value, ...cases) {
  // if (cases.length % 2 !== 0) throw new Error("_switch must have odd number of arguments!");
  for (let i = 0; i < cases.length - 1; i+= 2) {
    if (typeof cases[i] == 'function') {
      // if the case is a function, we run it and use the return value:
      if (cases[i](value)) {
        return cases[i+1];
      }
    } else {
      // otherwise we just compare with the value
      // TODO: should we have support for shallow comparisons of, say, arrays?
      if (cases[i] === value) {
        return cases[i+1];
      }
    }
  }
  // we didn't find a match, if we have an "extra" arg we treat it as the default
  if (cases.length % 2 !== 0) return cases[cases.length - 1];
  // otherwise leave it undefined
  return undefined;
}

/**
 * Add an item to an array at an index or at the first instance matching a
 * given predicate function.
 * @param {any} newItem item to be inserted
 * @param {*} arr array to insert item into
 * @param {any | (any, number) => boolean} indexOrCallback either a predicate
 *        function to run on array elements or an index in the array
 * @returns any[]
 */
export function spliceIntoAt (newItem, arr, indexOrCallback) {
  if (!Array.isArray(arr)) {
    if (arr === null || arr === undefined)
    {
      return [newItem];
    }
    throw new Error("Splice target was not an array!");
  }
  if (typeof indexOrCallback === 'function') {
    indexOrCallback = arr.findIndex(indexOrCallback);
  }
  if (indexOrCallback === -1) {
    return [...arr, newItem];
  }
  return [
    ...arr.slice(0, indexOrCallback),
    newItem,
    ...arr.slice(indexOrCallback + 1)
  ];
}

/**
 * Remove an item, if present, from an array, returning a new array object
 * @param {any} toRemove Item to be removed or callback to run
 * @param {any[]} arr Array to be removed from
 * @returns any[] new array without target item
 */
export function spliceOutOf (toRemoveOrCallback, arr) {
  if (!Array.isArray(arr)) return [];
  let i = ((typeof toRemoveOrCallback === 'function')
    ? arr.findIndex(toRemoveOrCallback)
    : arr.indexOf(toRemoveOrCallback));
  let out = arr.slice();
  if (i > -1) {
    out.splice(i, 1);
  }
  return out;
}

export function cleaveBefore(arr, index) {
  let left = Array.from(arr);
  let right = left.splice(index, arr.length - index);
  return [left, right];
}

export function deltaArrays (old, nu) {
  const oldSet = new Set(old);
  const newSet = new Set(nu);
  return [nu.filter(n => !oldSet.has(n)), old.filter(o => !newSet.has(o)), old.filter(o => newSet.has(o))];
}

export function adjacentPairs(arr, pairForLast = undefined) {
  return arr.filter((x, i) => i % 2 === 0)
  .map((left, i) => [
    left,
    (2*i + 1) >= arr.length ? pairForLast : arr[(2*i + 1)]
  ]);
}

export function ordinals (n) {
  if (n < 1) return [];
  return [...Array(n).keys()]
}

export function positiveModulo (x, m) {
  return ((x % m) + m) % m;
}

export function wraparoundAccess (arr, i) {
  return arr[positiveModulo(i, arr.length)];
}

// export function joinJSX (arr, separator) {
//   return arr.map((x, i) => <Fragment key={i}>{x}{i < arr.length - 1 ? separator : null}</Fragment>)
// }

export function debounce (fn, time) {
  let isFirst = true;
  let lastCall = null;
  let emitNeeded = false;
  let waitingArgs = null;
  let timerId = null;
  let trailing = () => {
    if (emitNeeded) {
      fn(...waitingArgs);
      emitNeeded = false;
      waitingArgs = null;
      timerId = window.setTimeout(trailing, time);
    } else {
      // nothing was left, so we've waited long enough to clear
      lastCall = null;
      isFirst = true;
    }
  };

  let leading = (...incomingArgs) => {
    lastCall = Date.now();
    if (isFirst) {
      // first call in this window, we emit on the leading edge
      isFirst = false;
      fn(...incomingArgs);
      timerId = window.setTimeout(trailing, time);
    } else {
      emitNeeded = true;
      waitingArgs = incomingArgs;
    }
  };

  // let cancel = () => {
  //   window.clearTimeout(timerId);
  //   lastCall = null;
  //   isFirst = true;
  //   emitNeeded = false;
  //   let timerId = null;
  // };

  return leading;
};

export function isObject (x, excludeArrays = false) {
  return typeof x === 'object' && x !== null && !(excludeArrays && Array.isArray(x));
}

export function assignOnly (source, target, propertyList, skipUndefined = false) {
  for (let prop of propertyList) {
    let defaultValue = undefined;
    if (Array.isArray(prop)) {
      if (prop.length !== 2) throw new Error(`Props should be strings or tuples of key,default`);
      [prop, defaultValue] = prop;
    }
    let toAssign = (prop in source) ? source[prop] : defaultValue;
    if (skipUndefined && toAssign === undefined) continue;
    target[prop] = toAssign;
  }
  return target;
}

export function deepAssignMulti (objectHandler, ...items) {
  if (items.length === 0) {
    throw new Error("Deep assign needs at least 1 object");
  }
  // we always use the first item as the canonical type
  if (Array.isArray(items[0])) {
    // ARRAY
    // TODO: do we need to provide an option for merged arrays?
    return items[0].map((_, key) => {
      let innersAtKey = items.map(i => i?.[key]).filter(i => i !== undefined && i !== null);
      if (innersAtKey.length === 0) return undefined;
      return deepAssignMulti(objectHandler, ...innersAtKey);
    });
  } else if (typeof items[0] === 'object' && items[0] !== null) {
    // OBJECT
    if (objectHandler) {
      const result = objectHandler(items);
      if (result !== undefined) {
        return result;
      }
    }
    let keys = new Set();
    // we iterate starting at the last item because we want to preserve the
    // oldest present order
    for (let i = items.length - 1; i >= 0; i--) {
      if (typeof items[i] === 'object' && items[i] !== null) {
        Object.keys(items[i]).forEach(key => keys.add(key));
      } else {
        console.warn(`skipping non-object item in slot ${i}: ${items[i]}`);
      }
    }
    // NOTE: JS Sets *do* iterate over their elements in insertion order!
    return Object.fromEntries(Array.from(keys).map(key => {
      let innersAtKey = items.map(i => i?.[key]).filter(i => i !== undefined && i !== null);
      if (innersAtKey.length === 0) return undefined;
      return [key, deepAssignMulti(objectHandler, ...innersAtKey)];
    }));
  } else {
    // VALUE
    return items[0];
  }
}

export function pluralizeUnit (item, unit, customPlural, numberReplacements = {}, customNullishMessage) {
  let count;
  if (Number.isFinite(item)) {
    count = item;
  } else if (Array.isArray(item)) {
    count = item.length;
  } else if (item instanceof Map || item instanceof Set) {
    count = item.size;
  } else if (typeof item === "object" && item !== null) {
    count = Object.keys(item).length;
  } else {
    if (customNullishMessage) return customNullishMessage;
    count = 0;
  }
  return `${count in numberReplacements ? numberReplacements[count] : count} ${count !== 1 ? (customPlural || (unit + "s")) : unit}`;
}

export function clamp (x, a, b) {
  return x < a ? a : (x > b ? b : x);
}

export function objectEntries (maybeObj) {
  return (isObject(maybeObj)) ? Object.entries(maybeObj) : [];
}

export function chunkedBy (arr, chunkSize) {
  if (chunkSize > arr.length) return arr.slice();
  const chunked = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunked.push(arr.slice(i, i+chunkSize));
  }
  return chunked;
}

export function deepCopy (obj) {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error("Cannot deepCopy, object is not JSONable", obj);
    if (Array.isArray(obj)) return [];
    return {};
  }
}

export function safeStringify (obj, padding = 2) {
  try {
    const seen = new WeakMap();
    return JSON.stringify(obj, (k, v) => {
      if (typeof v === "object" && v !== null) {
        if (seen.has(v)) {
          return `«repeat object; last key was '${seen.get(v)}'»`;
        }
        seen.set(v, k);
      } else if (typeof v === "function") {
        return `«function: ${v?.name}»`
      }
      return v;
    }, padding);
  } catch (e) {
    return `STRINGIFY ERROR: ${e}`;
  }
}

export function JSObjectDump ({obj, style, classes}) {
  return <pre
    className={classes ?? "p-2 mb-0"}
    style={{whiteSpace: "pre-wrap", background: "#E0E0FF", borderRadius: "8px", ...style}}>
      {safeStringify(obj)}
    </pre>;
}

export function removeNewlines (str, collapseSpaces = true) {
  if (collapseSpaces) {
    return str.replace(/(\s?)\s*\n\s*/g,"$1")
  } else {
    return str.replace(/\n/g, "");
  }
}
export function oneline (strings, ...keys) {
  let final = "";
  for (let i = 0; i < keys.length; i++) {
    final += `${strings[i]}${keys[i]}`;
  }
  final += strings[strings.length - 1];
  return removeNewlines(final);
}
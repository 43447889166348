import { useEffect, useRef, useState } from "react";
import { EnglishNames, Language, LanguageEndonym, SelectorAssets } from "../../../../constants/locales";


const SupportedLanguages = [
  Language.English,
  Language.Spanish,
  Language.Arabic,
  Language.Swahili,
  Language.Burmese,
  Language.Kinyarwanda,
  Language.Somali
];
const Playables = new Map();
console.log("Manual audio setup ====")
SupportedLanguages.forEach(l => {
  if (l in SelectorAssets && SelectorAssets[l].narrationFile) {
    const url = `/audio/${SelectorAssets[l].narrationFile}`;
    console.log(`${l} - ${url}`);
    Playables.set(l, new Audio(url));
  }
});


export const LanguageSelector = ({availableLanguages = SupportedLanguages, initialDelay = 3000, interAudioDelay = 3000, scroll = true }) => {
  const [fakeLanguage, setFakeLanguage] = useState(null);
  // we use a ref rather than useState because it simplifies how the closure
  // of a timeout can access the updated value in a functional component
  const loopActive = useRef(true);

  function pickLanguage (l) {
    setFakeLanguage(l);
    setCurrentHighlight(null);
    loopActive.current = false;
  }

  /**
   * @type 
   */
  const cardContainer = useRef();

  const [currentHighlight, setCurrentHighlight] = useState(null);

  function playFirstLanguage () {
    setTimeout(() => {
      if (!loopActive.current) {
        console.log("loopActive was false even on first language");
        return;
      }
      setCurrentHighlight(availableLanguages[0]);
      if (Playables.has(availableLanguages[0])) {
        Playables.get( availableLanguages[0]).play();
      }
      setTimeout(() => stepToNextLanguage(), interAudioDelay);
    }, initialDelay)
  }

  function stepToNextLanguage () {
    if (!loopActive.current) return;
    setCurrentHighlight(l => {

      const i = (availableLanguages.indexOf(l) + 1) % availableLanguages.length;
      if (scroll) {
        cardContainer.current.children[i]?.scrollIntoView({block: "center", behavior: "smooth"});
      }
      console.log({i, ref: cardContainer.current, loopActive: loopActive.current});

      if (Playables.has(availableLanguages[i])) {
        Playables.get( availableLanguages[i]).play();
      }
      return availableLanguages[i];
    });
    setTimeout(() => stepToNextLanguage(), interAudioDelay);
  }

  useEffect(() => {
    playFirstLanguage(true);
  }, [])


  return <div className="viewer main-columm pad-for-header" style={{minHeight: "100vh", justifyContent: "center"}}>
    <h3 className="question">What is your preferred language?</h3>
    {/* <p>{currentHighlight} {Playables.has(currentHighlight) ? <strong>PLAYING AUDIO</strong> : <em>no audio</em>}</p> */}
    <div className="rect-img-card-container" ref={cardContainer}>
      {availableLanguages.map(l => 
      <div
        className={`rect-img-card ${fakeLanguage === l ? "animated-fill" : ""} ${currentHighlight === l ? "highlight" : ""}`}
        key={l}
        onClick={() => pickLanguage(l)}
        style={{}}
      >
        <div className="image-wrapper"><span className="flag-as-image">{SelectorAssets[l]?.flags?.slice?.(0, 4)}</span></div>
        {l in SelectorAssets ?
          <div className="text-wrapper" style={{justifyContent: "center"}}>
          <p className="with-subtext">
            {LanguageEndonym[l]}
          </p>
          {l === Language.English ? null : <p className="choice-subtext">{EnglishNames[l]}</p>}
        </div> : 
        <p className="text-warning">Unknown language: {l}</p> 
        }
        <div className="icon-wrapper">
          <img src="/images/patient_redesign/speaker.svg" />
        </div>
      </div>)}
    </div>
  </div>;
}
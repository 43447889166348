import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { spliceOutOf } from "../../utils";

const initialState = {
  active: [],
  snacks: [],
  confirmation: null
};

const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.active.push({
        title: action.payload.title || "Alert",
        message: action.payload.message
      });
    },
    dismissAllAlerts: (state, action) => {
      state.active = [];
    },
    createSnack: (state, action) => {
      state.snacks.push({
        id: action.payload.id || nanoid(),
        message: action.payload.message,
        options: action.payload.options || {}
      });
    },
    dismissSnack: (state, action) => {
      const index = state.snacks.findIndex(action.payload.id);
      if (index > -1) {
        state.snacks.splice(index, 1);
      }
    },
    dismissAllSnacks: (state, action) => {
      state.snacks = [];
    }


    // playLocalAudio: (state, action) => {
    //   state.activeAudio.filename = action.payload;
    //   state.activeAudio.playing = true;
    //   const lookup = audioAdapter.getSelectors().selectById(state.audioSources, action.payload);
    //   if (lookup) {
    //     state.activeAudio.src = lookup.src;
    //   }
    // },
    // localAudioFinished: (state, action) => {
    //   if (state.activeAudio.filename === action.payload) {
    //     state.activeAudio.playing = false;
    //   } else {
    //     console.warn(`Tried to stop audio ${action.payload}, but wasn't active`);
    //   }
    // },
    // playGlobalAudio: (state, action) => {
    //   state.activeAudio.playing = false; // ?
    // },
    // audioStateChange: (state, action) => {
    //   audioAdapter.updateOne(state.audioSources, action.payload);
    // }
  },
  extraReducers: builder => {
    // builder.addCase(resolveAudioURLs.fulfilled, (state, action) => {
    //   audioAdapter.addMany(state.audioSources, action.payload);
    // });
  }
});


// export const playLocalAudio = audioSlice.actions.playLocalAudio;
// export const playGlobalAudio = audioSlice.actions.playGlobalAudio;

// export const audioSourceSelectors = audioAdapter.getSelectors(s => s.audio.audioSources);
// export const getAudioSource = audioSourceSelectors.selectById;

export default alertSlice.reducer;
export const alertActions = alertSlice.actions;
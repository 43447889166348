import { Switch } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from "react-icons/fa";
import { useKeyboardEvents } from "../../utils/hooks";
import "./AreaSelector/selector-styles.css";
import { capitalizeByWord, safeStringify } from "../../utils";
import { DynamicSVG } from "./DynamicSVG";

const HIGHLIGHT_BY_SVG_NODES = true;
const USE_DYNAMIC_SVG_EMBED = true;

const RegionShape = {
  Rectangle: "rect",
  Polygon: "poly",
  Circle: "circ",
  None: "NONE",
};

const ResolutionMethod = {
  Closest: "closest to region center",
  DefinitionOrder: "first defined region",
  SelectAll: "activate all",
};

const DEFAULT_REGION_DEF = [];
const PRE_VIEW_DEFS = [
  // {
  //   id: "head_front",
  //   shape: RegionShape.Circle,
  //   center: [217, 107],
  //   radius: 60,
  //   value: "Head"
  // },
  {
    clickShape: {
      shape: "circ",
      center: [220, 102],
      radius: 43,
    },
    shape: "poly",
    asCubicBezier: true,
    points: [
      [190, 110],
      [187, 84],
      [194, 67],
      [208, 58],
      [222, 52],
      [238, 54],
      [249, 68],
      [259, 87],
      [262, 90],
      [275, 104],
      [280, 108],
      [274, 113],
      [267, 117],
      [272, 125],
      [270, 130],
      [246, 136],
      [230, 142],
      [224, 145],
      [202, 141],
      [197, 135],
      [197, 135],
      [191, 125],
      [185, 115],
      [190, 110],
      [190, 110],
    ],
    id: "head_front",
    value: "Head",
  },
  {
    shape: "poly",
    points: [
      [205, 158],
      [210, 150],
      [211, 149],
      [203, 142],
      [212, 139],
      [240, 133],
      [266, 136],
      [257, 145],
      [256, 149],
      [262, 158],
      [248, 164],
      [219, 164],
      [206, 160],
    ],
    id: "neck_front",
    value: "Neck",
    asCubicBezier: true,
  },
  {
    clickShape: {
      shape: "poly",
      points: [
        [166, 192],
        [171, 159],
        [279, 159],
        [288, 193],
      ],
    },
    shape: "poly",
    points: [
      [171, 192],
      [176, 167],
      [176, 167],
      [197, 162],
      [226, 160],
      [226, 160],
      [263, 162],
      [276, 164],
      [277, 164],
      [285, 192],
      [286, 193],
      [172, 197],
      [172, 197],
      [172, 197],
      [171, 192],
      [171, 192],
    ],
    asCubicBezier: true,
    value: "Shoulders",
    id: "shoulders_front",
  },
  {
    shape: "poly",
    points: [
      [188, 181],
      [268, 180],
      [271, 206],
      [259, 243],
      [197, 243],
      [189, 218],
    ],
    value: "Chest",
    id: "chest_front",
  },
  {
    shape: "poly",
    points: [
      [196, 241],
      [262, 241],
      [258, 264],
      [273, 300],
      [184, 301],
      [197, 270],
    ],
    value: "Abdomen",
    id: "abdomen_front",
  },
  {
    shape: "poly",
    points: [
      [169, 189],
      [191, 197],
      [188, 263],
      [168, 320],
      [147, 313],
      [154, 276],
      [162, 251],
    ],
    value: "Right Arm",
    id: "right_arm_front",
  },
  {
    shape: "poly",
    points: [
      [286, 189],
      [264, 197],
      [267, 263],
      [287, 320],
      [308, 313],
      [301, 276],
      [293, 251],
    ],
    value: "Left Arm",
    id: "left_arm_front",
  },
  // {
  //   "shape": "circ",
  //   "center": [149, 341],
  //   "radius": 26,
  //   "value": "Right Hand",
  //   "id": "right_hand_front"
  // },
  // {
  //   "shape": "circ",
  //   "center": [306, 341],
  //   "radius": 26,
  //   "value": "Left Hand",
  //   "id": "left_hand_front"
  // },
  {
    shape: "poly",
    points: [
      [182, 299],
      [274, 300],
      [279, 325],
      [228, 343],
      [178, 324],
    ],
    value: "Pelvis / Groin",
    id: "pelvis_front",
  },
  {
    shape: "poly",
    points: [
      [176, 331],
      [228, 337],
      [228, 588],
      [205, 584],
      [190, 511],
      [189, 462],
      [174, 352],
    ],
    value: "Right Leg",
    id: "right_leg_front",
  },
  {
    shape: "poly",
    points: [
      [280, 331],
      [229, 337],
      [228, 588],
      [251, 584],
      [266, 511],
      [267, 462],
      [282, 352],
    ],
    value: "Left Leg",
    id: "left_leg_front",
  },
  // {
  //   "shape": "poly",
  //   "points": [[199, 577], [228, 576], [228, 623], [192, 620], [190, 609], [200, 587]
  //   ],
  //   "value": "Right Foot",
  //   "id": "right_foot_front"
  // },
  // {
  //   "shape": "poly",
  //   "points": [[256,577],[227,576],[227,623],[263,620],[265,609],[255,587]],
  //   "value": "Left Foot",
  //   "id": "left_foot_front"
  // },
  {
    shape: "rect",
    anchor: [345, 37],
    size: [215, 622],
    id: "back",
    disabled: true,
    value: "Back (not yet enabled)",
  },
];

const VIEWS = {
  "front vs back": {
    canSelectEntire: false,
    type: "bottom cards",
    imgUrl: "images/area_selector/female_whole_body_covered.svg",
    // imgComponent: await import("./AreaSelector/images/area_selector/female_whole_body_covered.svg"),
    overallChoice: null,
    choices: [
      {
        label: "Front",
        value: "Front of Body",
        viewId: "body front",
        highlightRegion: "front",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Back",
        value: "Back of Body",
        viewId: "body back",
        highlightRegion: "back",
        clickableRegion: null,
        lineAnchor: null,
      },
    ],
  },
  "body front": {
    imgUrl: "images/area_selector/female_front_covered.svg",
    // imgComponent: await import("./AreaSelector/images/area_selector/female_front_covered.svg"),
    choices: [
      {
        label: "Head",
        value: "Head",
        viewId: "head",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [206, 48],
      },
      {
        label: "Neck",
        value: "Neck",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [205, 107],
      },
      {
        label: "Chest",
        value: "Chest",
        viewId: "chest",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [200, 169],
      },
      {
        label: "Abdomen",
        value: "Abdomen",
        viewId: "abdomen",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [200, 252],
      },
      {
        label: "Arms",
        value: "Arms",
        viewId: "arms",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [274, 261],
      },
      {
        label: "Hips & Pelvis",
        value: "Pelvis",
        viewId: "pelvis",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [228, 305],
      },
      {
        label: "Legs",
        value: "Legs",
        viewId: "legs",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [230, 449],
      },
    ],
  },
  "body back": {
    imgUrl: "images/area_selector/female_whole_back_covered.svg",
    choices: [
      {
        label: "Head",
        value: "Head",
        viewId: "head",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Neck",
        value: "Neck",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Upper back",
        value: "Upper back",
        viewId: "upper back",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Middle back",
        value: "Middle back",
        viewId: "middle back",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Lower back",
        value: "Lower back",
        viewId: "lower back",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Buttocks",
        value: "Buttocks",
        viewId: "buttocks",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      }
    ],
  },
  head: {
    imgUrl: "images/area_selector/female_head_neck.svg",
    choices: [
      {
        label: "Top",
        value: "Top of head",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [292, 35],
      },
      {
        label: "Forehead",
        value: "Forehead",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [239, 131],
      },
      {
        label: "Temple",
        value: "Temple",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [343, 184],
      },
      {
        label: "Eyes",
        value: "Eyes",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [262, 228],
      },
      {
        label: "Nose",
        value: "Nose",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [227, 270],
      },
      {
        label: "Ears",
        value: "Ears",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [405, 247],
      },
      {
        label: "Mouth",
        value: "Mouth",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [236, 319],
      },
      {
        label: "Neck",
        value: "Neck",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: [311, 444],
      },
    ],
  },
  chest: {
    imgUrl: "images/area_selector/female_shoulders_chest_covered.svg",
    choices: [
      {
        label: "Collarbone",
        value: "Collarbone",
        highlightRegion: "Collarbone-2",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Upper Chest / Pectorals",
        value: "Upper Chest / Pectorals",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Breasts",
        value: "Breasts",
        highlightRegion: "Breasts",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Ribs",
        value: "Ribs",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Sternum",
        value: "Sternum",
        highlightRegion: "Sternum",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Lower Chest",
        value: "Lower Chest",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Diaphragm",
        value: "Diaphragm",
        highlightRegion: "Diaphragm",
        clickableRegion: null,
        lineAnchor: null,
      },
    ],
  },

  abdomen: {
    imgUrl: "images/area_selector/female_abs_covered.svg",
    choices: [
      {
        label: "Upper Sides",
        value: "Upper Sides",
        highlightRegion: "Upper Right Ab",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Upper Center",
        value: "Upper Center",
        highlightRegion: "Upper Center Ab",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Mid Sides",
        value: "Mid Sides",
        highlightRegion: "Middle Right Ab",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Mid Center",
        value: "Mid Center",
        highlightRegion: "Middle Center Ab",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Lower Sides",
        value: "Lower Sides",
        highlightRegion: "Lower Left Ab",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Lower Center",
        value: "Lower Center",
        highlightRegion: "Lower Center Ab",
        clickableRegion: null,
        lineAnchor: null,
      },
    ],
  },

  "head & neck back": {
    imgUrl: "images/area_selector/female_back_body_covered.svg",
    choices: [
      {
        label: "Neck Muscles",
        value: "Neck Muscles",
        highlightRegion: "Neck",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Cervical Vertebrae (Neck Spine)",
        value: "Cervical Vertebrae",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      }      
    ],
  },

  "upper back": {
    imgUrl: "images/area_selector/female_back_body_covered.svg",
    choices: [
      {
        label: "Upper Right Back Muscles",
        value: "Upper Right Back Muscles",
        highlightRegion: "Upper Back",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Upper Left Back Muscles",
        value: "Upper Left Back Muscles",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Thoracic Vertebrae (Spine)",
        value: "Thoracic Vertebrae",
        highlightRegion: "Lower Back",
        clickableRegion: null,
        lineAnchor: null,
      }
    ],
  },
  "middle back": {
    imgUrl: "images/area_selector/female_back_body_covered.svg",
    choices: [
      {
        label: "Middle Right Back Muscles",
        value: "Middle Right Back Muscles",
        highlightRegion: "Mid Back",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Middle Left Back Muscles",
        value: "Middle Left Back Muscles",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },      
      {
        label: "Thoracic Vertebrae (Spine)",
        value: "Thoracic Vertebrae",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      }
    ],
  },

  "lower back": {
    imgUrl: "images/area_selector/female_back_body_covered.svg",
    choices: [
      {
        label: "Lower Right Back Muscles",
        value: "Lower Right Back Muscles",
        highlightRegion: "Lower Back",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Lower Left Back Muscles",
        value: "Lower Left Back Muscles",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Lumbar Vertebrae (Spine)",
        value: "Lumbar Vertebrae",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      }
    ],
  },
  buttocks: {
    imgUrl: "images/area_selector/female_back_body_covered.svg",
    choices: [
      {
        label: "Gluteal Muscles",
        value: "Gluteal Muscles",
        highlightRegion: "Buttocks",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Sacrum (base of the spine)",
        value: "Sacrum",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Coccyx (tailbone)",
        value: "Coccyx",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      }
    ],
  },

  arms: {
    imgUrl: "images/area_selector/female_arms_covered.svg",
    choices: [
      {
        label: "Shoulders",
        value: "Shoulders",
        highlightRegion: "Shoulders",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Biceps",
        value: "Biceps",
        highlightRegion: "Biceps",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Triceps",
        value: "Triceps",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Elbow",
        value: "Elbow",
        highlightRegion: "Elbows",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Forearms",
        value: "Forearms",
        highlightRegion: "Forearms",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Hand",
        value: "Hand",
        viewId: "hands",
        highlightRegion: "Hands",
        clickableRegion: null,
        lineAnchor: null,
      },
    ],
  },

  hands: {
    imgUrl: "images/area_selector/front_hand.svg",
    choices: [
      {
        label: "Wrist",
        value: "Wrist",
        highlightRegion: "Wrist",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Palm",
        value: "Palm",
        highlightRegion: "Palm",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Thumb",
        value: "Thumb",
        highlightRegion: "Thumb",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Index Finger",
        value: "Index Finger",
        highlightRegion: "Index Finger",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Middle Finger",
        value: "Middle Finger",
        highlightRegion: "Middle Finer",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Ring Finger",
        value: "Ring Finger",
        highlightRegion: "Ring Finger",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Pinky Finger",
        value: "Pinky Finger",
        highlightRegion: "Pinky Finger",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Back of Hand",
        value: "Back of Hand",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
    ],
  },

  pelvis: {
    imgUrl: "images/area_selector/female_genitals_buttocks_covered.svg",
    choices: [
      {
        label: "Hips & pelvis",
        value: "Hips & pelvis",
        highlightRegion: "Front Hip and Pelvis",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Buttocks",
        value: "Buttocks",
        highlightRegion: "Buttocks",
        clickableRegion: null,
        lineAnchor: null,
      }
    ],
  },

  legs: {
    imgUrl: "images/area_selector/female_legs_covered.svg",
    choices: [
      {
        label: "Thighs",
        value: "Thighs",
        highlightRegion: "Thighs",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Quadriceps",
        value: "Quadriceps",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Hamstrings",
        value: "Hamstrings",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Knees",
        value: "Knees",
        highlightRegion: "Knees",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Lower Legs",
        value: "Lower Legs",
        highlightRegion: "Lower Legs",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Calves",
        value: "Calves",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Shin",
        value: "Shin",
        highlightRegion: null,
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Foot",
        value: "Foot",
        viewId: "feet",
        highlightRegion: "Feet",
        clickableRegion: null,
        lineAnchor: null,
      },
    ],
  },

  feet: {
    imgUrl: "images/area_selector/foot.svg",
    choices: [
      {
        label: "Ankle",
        value: "Ankle",
        highlightRegion: "Ankle",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Top of Foot",
        value: "Top of Foot",
        highlightRegion: "Top of Foot",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Arch",
        value: "Arch",
        highlightRegion: "Arch",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Ball",
        value: "Ball",
        highlightRegion: "Ball",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Big toe",
        value: "Big toe",
        highlightRegion: "Big Toe",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Second toe",
        value: "Second toe",
        highlightRegion: "Second Toe",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Middle toe",
        value: "Middle toe",
        highlightRegion: "Middle Toe",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Fore toe",
        value: "Fore toe",
        highlightRegion: "Fore Toe",
        clickableRegion: null,
        lineAnchor: null,
      },
      {
        label: "Pinky toe",
        value: "Pinky toe",
        highlightRegion: "Pinky Toe",
        clickableRegion: null,
        lineAnchor: null,
      },
    ],
  },
};

function depthFirstViewsOf(view, seen = new Set()) {
  if (!view) return [];
  if (!Array.isArray(view.choices)) return [];
  return view.choices
    .filter((c) => c.viewId && !seen.has(c.viewId))
    .map((c) => {
      seen.add(c.viewId);
      return [c.viewId, ...depthFirstViewsOf(VIEWS[c.viewId], seen)];
    });
}

console.warn("===== AREA SELECTOR SETUP =====");
const DEFAULT_VIEW_ROOT = "front vs back";
const VIEW_ORDER = [
  DEFAULT_VIEW_ROOT,
  depthFirstViewsOf(VIEWS[DEFAULT_VIEW_ROOT]),
].flat(50);
console.warn({ VIEW_ORDER });

const VALUES_FOR_VIEW = {};
const VIEWS_FOR_VALUE = {};
Object.entries(VIEWS).forEach(([vName, v]) =>
  v.choices?.forEach((c) => {
    if (c.viewId) {
      if (!c.value) {
        console.error(
          `No value (${safeStringify(c.value)}) for view '${
            c.viewId
          }' (in '${vName}')`
        );
        return;
      }
      if (VALUES_FOR_VIEW[c.viewId] && VALUES_FOR_VIEW[c.viewId] !== c.value) {
        console.error(
          `Found conflicting value '${c.value}' != '${
            VALUES_FOR_VIEW[c.viewId]
          }' for view '${c.viewId}' (in '${vName}')`
        );
      } else {
        VALUES_FOR_VIEW[c.viewId] = c.value;
      }
      if (VIEWS_FOR_VALUE[c.value] && VIEWS_FOR_VALUE[c.value] !== c.viewId) {
        console.error(
          `Found conflicting view '${c.viewId}' != '${
            VIEWS_FOR_VALUE[c.value]
          }' for value '${c.value}' (in '${vName}')`
        );
      } else {
        VIEWS_FOR_VALUE[c.value] = c.viewId;
      }
    }
  })
);
console.warn({ VALUES_FOR_VIEW, VIEWS_FOR_VALUE });

function AreaSelector({
  answer,
  questionDefinition,
  updateAnswer,
  complete,
  goBack,
  showTrackingInfo
}) {
  const [lastClick, setLastClick] = useState(null);
  const [activeRegions, setActiveRegions] = useState(["head"]);
  const [debug_showAllRegions, setShowAllRegions] = useState(false);
  const [debug_labelRegions, setLabelAllRegions] = useState(false);
  const [debug_svgBackground, setUseSVGBackground] = useState(false);
  const [debug_useCustomRegion, setUseCustomRegion] = useState(false);
  const [drawConnectiveLines, setDrawConnectiveLines] = useState(true);
  const [regionDef, setRegionDef] = useState(DEFAULT_REGION_DEF);
  const [regionDefText, setRegionDefText] = useState(
    JSON.stringify(DEFAULT_REGION_DEF, undefined, 2)
  );
  const [buildMode, setBuildMode] = useState(false);
  const [buildModeCubic, setBuildModeCubic] = useState(false);
  const [buildModePoints, setBuildModePoints] = useState([]);
  const [buildModeOutput, setBuildModeOutput] = useState("");
  const [resolutionMethod, setResolutionMethod] = useState(
    ResolutionMethod.Closest
  );
  const svgRef = useRef(null);
  const [showCustomBox, setShowCustomBox] = useState(false);
  const [showDebug, setShowDebug] = useState(false);
  const [lastSelectedFrom, setLastSelectedFrom] = useState({});

  useKeyboardEvents(["F3", () => setShowDebug(!showDebug)]);

  const rootView = questionDefinition.initialView ?? DEFAULT_VIEW_ROOT;
  const [mostRecentVisitables, setMostRecentVisitables] = useState([rootView]);
  const [viewId, setViewId] = useState(
    questionDefinition.initialView ?? rootView
  );
  const view = useMemo(() => {
    return VIEWS[viewId] ?? { notFound: true };
  }, [viewId]);

  useEffect(() => {
    if (Array.isArray(answer?.values)) {
      setActiveRegions(answer.values.map((v) => v.value));
    } else {
      setActiveRegions([]);
    }
  }, [answer]);

  function snakeCase (str) {
    if (typeof str === 'string') {
      return str.split(' ').join('_')
    }
    return str;
  }

  const SVG_SELECTION_TYPE = 2;
  useEffect(() => {
    /** @type {SVGAElement | null} */
    const svgEl = svgRef.current;
    if (svgEl === null) {
      console.warn("empty ref");
      return;
    }
    // console.warn(`ref running [${activeRegions.join(", ")}]`);
    if (HIGHLIGHT_BY_SVG_NODES) {
      if (SVG_SELECTION_TYPE == 1) {
        document
          .querySelectorAll("#highlight_base [data-region]")
          .forEach((e, k, p) => {
            if (activeRegions.includes(e.dataset.region)) {
              console.log(`found ${e.dataset.region} region at ${k}`);
              e.classList.add("highlight-on");
            } else {
              e.classList.remove("highlight-on");
            }
          });
      } else if (SVG_SELECTION_TYPE == 2) {
        document
          .querySelectorAll("svg#highlight_base g")
          .forEach((e, k, p) => {
            if (activeChoicesInCurrentView.some(c => snakeCase(c.highlightRegion) === e.id)) {
              console.log(`found ${e.id} region at ${k}`);
              e.classList.add("highlight-on");
            } else {
              e.classList.remove("highlight-on");
            }
          });
      } else {
        console.error(`Unknown selection type: ${SVG_SELECTION_TYPE}`);
      }
    }
  });

  function submitRegionsAsAnswer(regions) {
    updateAnswer({
      id: questionDefinition.id,
      isMulti: true,
      values: regions.map((r) => ({ value: r })),
    });
  }

  const activeChoicesInCurrentView = useMemo(
    () =>
      activeRegions
        .map((value) => {
          if (!Array.isArray(view.choices)) return null;
          const index = view.choices.findIndex((r) => r.value === value);
          if (index < 0) return null;
          console.log({ index });
          return { ...view.choices[index], index };
        })
        .filter((r) => r),
    [activeRegions, regionDef, viewId]
  );

  function enableCustomRegion(enable) {
    if (enable) {
      try {
        setRegionDef(JSON.parse(regionDefText));
      } catch (err) {
        alert("Textbox was not valid JSON, rejected");
      }
    } else {
      setRegionDef(DEFAULT_REGION_DEF);
    }
    setUseCustomRegion(enable);
  }

  function applyRegionText(event) {
    setRegionDefText(event.target.value);
  }

  function resetData() {
    submitRegionsAsAnswer([]);
    setLastClick(null);
  }

  function linkPointOf(region) {
    if (region.lineAnchor) {
      return region.lineAnchor;
    } else {
      return [Math.random() * rightPanelOffset, Math.random() * totalWidth];
    }
    // if (Array.isArray(region.linkingPoint)) return region.linkingPoint;
    // const eventRegion = region;
    // switch (eventRegion.shape) {
    //   case (RegionShape.Circle):
    //     return region.center;
    //   case (RegionShape.Rectangle):
    //     return [region.anchor[0] + region.size[0]/2, region.anchor[1] + region.size[1]/2];
    //   case (RegionShape.Polygon):
    //     let sumX = 0;
    //     let sumY = 0
    //     let minY = Number.MAX_SAFE_INTEGER;
    //     let maxY = Number.MIN_SAFE_INTEGER;
    //     for (let [px, py] of eventRegion.points) {
    //       sumX += px;
    //       sumY += py;
    //       if (py > maxY) {
    //         maxY = py;
    //       }
    //       if (py < minY) {
    //         minY = py;
    //       }
    //     }
    //     return [sumX / eventRegion.points.length, sumY / eventRegion.points.length];
    //   default:
    //     return [0, 0];
    // }
  }

  const totalWidth = 675;
  const rightPanelOffset = 420;
  const panelItemHeight = 49;
  const totalPanelHeight = useMemo(() => {
    return ((view.choices?.length ?? 0) + 1) * panelItemHeight;
  }, [view]);

  function LinkLineOf({ region }) {
    const p1 = linkPointOf(region);
    const p2 = [
      rightPanelOffset,
      region.index * panelItemHeight + panelItemHeight / 2,
    ];
    return (
      <g>
        <circle cx={p1[0]} cy={p1[1]} r={6} fill="#1FE191" />
        <line
          x1={p1[0]}
          y1={p1[1]}
          x2={p2[0]}
          y2={p2[1]}
          fill="transparent"
          stroke="#1FE191"
          strokeWidth={4}
          strokeOpacity={0.8}
        />
        <circle cx={p2[0]} cy={p2[1]} r={6} fill="#1FE191" />
      </g>
    );
  }

  /**
   *
   * @param {*} region
   * @param {number} x
   * @param {number} y
   * @returns {false | number}
   */
  function clickIsInside(region, x, y, rawX, rawY) {
    const MIN = 0.1;
    // possible alternative method -- this would probably work best if we don't iterate over regions
    // and just have one master findTarget method, but requires regions to always be drawn on SVG
    // but rendered invisibly (this would honestly be a better approach for simplicity though!)
    // const hits = svgRef.current.getIntersectionList(SVGRect(rawX - 1, rawY - 1, 2, 2), null);

    // regions may define a clickShape object to define their *clickable* region as opposed to
    // their visualized region; this object should have all the same shape types as the outer region
    const eventRegion = "clickShape" in region ? region.clickShape : region;
    switch (eventRegion.shape) {
      case RegionShape.Circle:
        const distance = Math.sqrt(
          (x - eventRegion.center[0]) ** 2 + (y - eventRegion.center[1]) ** 2
        );
        if (distance > eventRegion.radius) {
          return false;
        } else {
          return Math.max(1 - distance / eventRegion.radius, MIN);
        }
      case RegionShape.Rectangle:
        const amounts = [x - eventRegion.anchor[0], y - eventRegion.anchor[1]];
        if (amounts.every((a, i) => a > 0 && a < eventRegion.size[i])) {
          return Math.max(
            1 -
              2 *
                Math.max(
                  Math.abs(0.5 - amounts[0] / eventRegion.size[0]),
                  Math.abs(0.5 - amounts[1] / eventRegion.size[1])
                ),
            MIN
          );
        } else {
          return false;
        }
      case RegionShape.None:
        // you can never click a None region
        return false;
      case RegionShape.Polygon:
        let sumX = 0;
        let sumY = 0;
        let minY = Number.MAX_SAFE_INTEGER;
        let maxY = Number.MIN_SAFE_INTEGER;
        for (let [px, py] of eventRegion.points) {
          sumX += px;
          sumY += py;
          if (py > maxY) {
            maxY = py;
          }
          if (py < minY) {
            minY = py;
          }
        }
        const weightedCenter = [
          sumX / eventRegion.points.length,
          sumY / eventRegion.points.length,
        ];
        const edges = eventRegion.points.map(([x1, y1], i, arr) => {
          const x2 = arr[(i + 1) % arr.length][0];
          const y2 = arr[(i + 1) % arr.length][1];
          if (x2 > x) {
            return [x1, y1, x2, y2];
          } else {
            return [x2, y2, x1, y1];
          }
        });
        const vertIntercepts = edges.map(([x1, y1, x2, y2]) => {
          if (x1 <= x && x <= x2) {
            return ((x - x1) / (x2 - x1)) * (y2 - y1) + y1;
          }
          return null;
        });
        const edgeCrossings = vertIntercepts.filter(
          (v) => Number.isFinite(v) && v > y
        );
        if (edgeCrossings.length % 2 === 1) {
          // return Math.max(1/Math.sqrt((x - weightedCenter[0])**2 + (y - weightedCenter[1])**2), MIN);
          let closestDist = Number.MAX_SAFE_INTEGER;
          for (let crossing of vertIntercepts) {
            if (
              Number.isFinite(crossing) &&
              Math.abs(crossing - y) < closestDist
            ) {
              closestDist = Math.abs(crossing - y);
            }
          }
          return Math.max((2 * closestDist) / (maxY - minY), MIN);
        }
        return false;
      default:
        throw new Error(
          "Unknown region shape " +
            eventRegion.shape +
            " in region " +
            region.id
        );
    }
  }

  function applyResolutionMethod(viableRegions) {
    if (!Array.isArray(viableRegions) || viableRegions.length === 0) {
      console.warn(
        `Received empty or non-array viable region list`,
        viableRegions
      );
      return [];
    }
    switch (resolutionMethod) {
      case ResolutionMethod.Closest:
        return [
          viableRegions.reduce(
            (prev, current) => (prev.weight > current.weight ? prev : current),
            { id: "N/A", weight: -1 }
          ),
        ];
      case ResolutionMethod.DefinitionOrder:
        return [viableRegions[0]]; // this assumes order has been preserved, which may not be guaranteed...
      case ResolutionMethod.SelectAll:
        return viableRegions.slice();
      default:
        throw new Error("Unknown resolution method " + resolutionMethod);
    }
  }

  const FORCE_INTEGER_BUILD_COORDS = true;
  function buildModeClick(event, rx, ry) {
    if (FORCE_INTEGER_BUILD_COORDS) {
      rx = Math.round(rx);
      ry = Math.round(ry);
    }
    switch (buildMode) {
      case RegionShape.Rectangle:
        if (buildModePoints.length === 0) {
          setBuildModePoints([[rx, ry]]);
          setBuildModeOutput(
            `Upper-left is at [${rx}, ${ry}]. Click again to complete...`
          );
        } else {
          const [x1, y1] = buildModePoints[0];
          const size = [rx - x1, ry - y1];
          const id = window.prompt("Enter an ID for this region");
          setBuildModePoints([]);
          setBuildMode(false);
          setBuildModeOutput(
            JSON.stringify(
              {
                shape: RegionShape.Rectangle,
                anchor: [x1, y1],
                size,
                id,
              },
              undefined,
              2
            )
          );
        }
        return;
      case RegionShape.Circle:
        if (buildModePoints.length === 0) {
          setBuildModePoints([[rx, ry]]);
          setBuildModeOutput(
            `Center is at [${rx}, ${ry}]. Click again to complete...`
          );
        } else {
          const [cx, cy] = buildModePoints[0];
          let radius = Math.sqrt((rx - cx) ** 2 + (ry - cy) ** 2);
          if (FORCE_INTEGER_BUILD_COORDS) {
            radius = Math.round(radius);
          }
          const id = window.prompt("Enter an ID for this region");
          setBuildModePoints([]);
          setBuildMode(false);
          setBuildModeOutput(
            JSON.stringify(
              {
                shape: RegionShape.Circle,
                center: [cx, cy],
                radius,
                id,
              },
              undefined,
              2
            )
          );
        }
        return;
      case RegionShape.Polygon:
        setBuildModePoints(buildModePoints.concat([[rx, ry]]));
        const count = buildModePoints.length + 1;
        setBuildModeOutput(
          `Polygon has ${count} points. ${
            count >= 3
              ? "Click 'Finish' to close shape"
              : "Please add at least 3 before finishing."
          }`
        );
        return;
      default:
        setBuildModeOutput(`Cannot support build mode '${buildMode}'`);
    }
  }

  function cancelBuild() {
    setBuildMode(false);
    setBuildModePoints([]);
    setBuildModeOutput("cleared");
  }

  const CLOSE_BEZIER_WITH_SMOOTH = true;
  function closeBuildPolygon() {
    if (
      buildMode !== RegionShape.Polygon ||
      buildModePoints.length < (buildModeCubic ? 7 : 3)
    ) {
      return alert("Not yet a complete polygon!");
    }
    const id = window.prompt("Enter an ID for this region");
    setBuildModePoints([]);
    setBuildMode(false);
    const output = {
      shape: RegionShape.Polygon,
      points: buildModePoints.slice(),
      id,
    };
    if (buildModeCubic) {
      output.asCubicBezier = true;
      if (CLOSE_BEZIER_WITH_SMOOTH) {
        const c1 = buildModePoints[1];
        const p1 = buildModePoints[0];
        const pL = buildModePoints[buildModePoints.length - 1];
        const cL = buildModePoints[buildModePoints.length - 2];
        const smoothOriginControl = [2 * p1[0] - c1[0], 2 * p1[1] - c1[1]];
        const smoothLastControl = [2 * pL[0] - cL[0], 2 * pL[1] - cL[1]];
        output.points.push(smoothLastControl);
        output.points.push(smoothOriginControl);
        output.points.push(p1.slice());
      }
    }
    setBuildModeOutput(JSON.stringify(output, undefined, 2));
  }

  // This only works if we have invisible but displayed nodes; at present
  // the tagged highlight nodes are display:none when off, which means they
  // won't ever be selected for click events...
  function getAncestorRegionData(target) {
    const regions = new Set();
    while (target instanceof SVGElement) {
      if (target.dataset.region) {
        regions.add(target.dataset.region);
      }
      target = target.parentElement;
    }
    return Array.from(regions);
  }

  const ALWAYS_RELATIVE_TO_SVG_ROOT = true;
  /**
   *
   * @param {MouseEvent & {target: HTMLImageElement}} event
   */
  function handleClick(event) {
    const targetBox = event.target.getBoundingClientRect();
    const outerSVGBox = svgRef.current.getBoundingClientRect();
    const relativeX =
      event.clientX -
      (ALWAYS_RELATIVE_TO_SVG_ROOT ? outerSVGBox.left : targetBox.left);
    const relativeY =
      event.clientY -
      (ALWAYS_RELATIVE_TO_SVG_ROOT ? outerSVGBox.top : targetBox.top);
    console.log({ relativeX, relativeY });
    if (!!buildMode) {
      return buildModeClick(event, relativeX, relativeY);
    }
    const ratioX = relativeX / targetBox.width;
    const ratioY = relativeY / targetBox.height;
    // HIGHLIGHT_BY_SVG_NODES ? getAncestorRegionData(event.target) :
    const clickedRegions = regionDef
      .map((r) => ({
        id: r.id,
        value: r.value,
        weight: clickIsInside(
          r,
          relativeX,
          relativeY,
          event.clientX,
          event.clientY
        ),
      }))
      .filter((r) => !!r.weight);
    let actionsTaken = [];
    let selectedFrom = {};
    if (clickedRegions.length > 0) {
      const targets = applyResolutionMethod(clickedRegions).map((r) => r.id);
      let newActiveRegions = activeRegions.slice();
      for (let target of targets) {
        const i = newActiveRegions.indexOf(target);
        if (i > -1) {
          actionsTaken.push(`remove ${target}`);
          newActiveRegions.splice(i, 1);
        } else {
          actionsTaken.push(`add ${target}`);
          newActiveRegions.push(target);
        }
        selectedFrom[target] = viewId;
      }

      // note there is no way to "select" the current view's 'entire' region
      // from image click so we only handle its removal
      const indexOfCurrentView = newActiveRegions.indexOf(
        VALUES_FOR_VIEW[viewId]
      );
      if (indexOfCurrentView > -1) {
        actionsTaken.push(
          `remove ${VALUES_FOR_VIEW[viewId]} (due to subselect)`
        );
        newActiveRegions.splice(indexOfCurrentView, 1);
      }

      setLastSelectedFrom({ ...lastSelectedFrom, ...selectedFrom });
      submitRegionsAsAnswer(newActiveRegions);
    }
    console.warn({ actionsTaken });
    setLastClick({
      ...event,
      relativeX,
      relativeY,
      ratioX,
      ratioY,
      regions: clickedRegions,
      actionsTaken,
    });
  }

  function toggleRegionDirectly(region, representsCurrentView = false) {
    const newActiveRegions = activeRegions.slice();
    const actionsTaken = [];

    if (representsCurrentView) {
      // note we only support setting the current view's choice ON,
      // deselecting it this way makes the interaction weird because we could
      // remove a navigational branch in an unclear way
      if (Array.isArray(view.choices)) {
        view.choices.forEach((c) => {
          const i = newActiveRegions.indexOf(c.value);
          if (i > -1) {
            actionsTaken.push(`remove ${c.value}`);
            newActiveRegions.splice(i, 1);
          }
        });
      }

      if (newActiveRegions.includes(region)) {
        // do nothing lol
      } else {
        actionsTaken.push(`add ${region}`);
        newActiveRegions.push(region);
      }
    } else {
      const i = newActiveRegions.indexOf(region);
      if (i > -1) {
        actionsTaken.push(`remove ${region}`);
        newActiveRegions.splice(i, 1);
      } else {
        actionsTaken.push(`add ${region}`);
        newActiveRegions.push(region);
      }
      // remove
      const indexOfCurrentView = newActiveRegions.indexOf(
        VALUES_FOR_VIEW[viewId]
      );
      if (indexOfCurrentView > -1) {
        actionsTaken.push(
          `remove ${VALUES_FOR_VIEW[viewId]} (due to subselect)`
        );
        newActiveRegions.splice(indexOfCurrentView, 1);
      }
    }

    setLastSelectedFrom({ ...lastSelectedFrom, [region]: viewId });
    submitRegionsAsAnswer(newActiveRegions);
    setLastClick({
      event: "DIRECT_ANSWER_TOGGLE",
      regions: [region],
      actionsTaken,
    });
  }

  function getViewsForVisit() {
    const viewsToShow = new Set([rootView]);
    activeRegions.forEach((v) => {
      // is the region itself a view?
      if (v in VIEWS_FOR_VALUE) {
        viewsToShow.add(VIEWS_FOR_VALUE[v]);
      }
      // what was the last view this value was selected from?
      if (v in lastSelectedFrom) {
        viewsToShow.add(lastSelectedFrom[v]);
      } else {
        console.warn(`Value ${v} did not have last-selected-from data!`);
      }
    });
    const visitable = VIEW_ORDER.filter((v) => viewsToShow.has(v));
    console.log({ visitable });
    setMostRecentVisitables(visitable);
    return visitable;
  }

  function goToNextView() {
    const absoluteViewIndex = VIEW_ORDER.indexOf(viewId);
    if (absoluteViewIndex < 0) {
      throw new Error(
        `Current view '${viewId}' not present in static VIEW_ORDER`
      );
    }
    const visitable = getViewsForVisit();
    for (let i = absoluteViewIndex + 1; i < VIEW_ORDER.length; i++) {
      if (visitable.includes(VIEW_ORDER[i])) {
        setViewId(VIEW_ORDER[i]);
        return;
      }
    }
    // if here there weren't any more pages!
    complete();
  }
  function goToPreviousView() {
    const absoluteViewIndex = VIEW_ORDER.indexOf(viewId);
    if (absoluteViewIndex < 0) {
      throw new Error(
        `Current view '${viewId}' not present in static VIEW_ORDER`
      );
    }
    const visitable = getViewsForVisit();
    for (let i = absoluteViewIndex - 1; i >= 0; i--) {
      if (visitable.includes(VIEW_ORDER[i])) {
        setViewId(VIEW_ORDER[i]);
        return;
      }
    }
    // if here there weren't any more pages!
    goBack();
  }

  function HighlightedRegion({ region, mode, color }) {
    const active = activeRegions.includes(region.id);
    const finalColor = color || (active ? "green" : "red");
    switch (region.shape) {
      case RegionShape.Circle:
        return (
          <g>
            <circle
              id={region.id}
              cx={region.center[0]}
              cy={region.center[1]}
              r={region.radius}
              fill={finalColor}
              fillOpacity="0.2"
              stroke={finalColor}
              strokeWidth="2"
            />
            {debug_labelRegions ? (
              <text
                x={region.center[0]}
                y={region.center[1]}
                textAnchor="middle"
                fill={finalColor}
              >
                {region.id}
              </text>
            ) : null}
          </g>
        );
      case RegionShape.Rectangle:
        return (
          <g>
            <rect
              id={region.id}
              x={region.anchor[0]}
              y={region.anchor[1]}
              width={region.size[0]}
              height={region.size[1]}
              fill={finalColor}
              fillOpacity="0.2"
              stroke={finalColor}
              strokeWidth="2"
            />
            {debug_labelRegions ? (
              <text
                x={region.anchor[0] + 0.5 * region.size[0]}
                y={region.anchor[1] + 0.5 * region.size[1]}
                textAnchor="middle"
                fill={finalColor}
              >
                {region.id}
              </text>
            ) : null}
          </g>
        );
      case RegionShape.Polygon:
        if (region.asCubicBezier) {
          if (region.points.length % 3 !== 1) {
            alert(
              "Expected points array for cubic bezier poly to satisfy mod(len, 3) = 1"
            );
          }
          let pathString = `M ${region.points[0][0]} ${region.points[0][1]} `;
          region.points.slice(1).forEach((pair, i) => {
            if (i % 3 === 0) {
              pathString += `C ${pair[0]} ${pair[1]}, `;
            } else {
              pathString += `${pair[0]} ${pair[1]}${i % 3 === 1 ? "," : ""} `;
            }
          });
          const weightedCenter = region.points
            .reduce(
              (prev, current, i) => [
                prev[0] + current[0],
                prev[1] + current[1],
              ],
              [0, 0]
            )
            .map((c) => c / region.points.length);
          return (
            <g>
              <path
                id={region.id}
                d={pathString}
                fill={finalColor}
                fillOpacity="0.2"
                stroke={finalColor}
                strokeWidth="2"
              />
              {debug_labelRegions ? (
                <text
                  x={weightedCenter[0]}
                  y={weightedCenter[1]}
                  textAnchor="middle"
                  fill={finalColor}
                >
                  {region.id}
                </text>
              ) : null}
            </g>
          );
        } else {
          const pointString = region.points
            .map((pair) => `${pair[0]},${pair[1]}`)
            .join(" ");
          const weightedCenter = region.points
            .reduce(
              (prev, current, i) => [
                prev[0] + current[0],
                prev[1] + current[1],
              ],
              [0, 0]
            )
            .map((c) => c / region.points.length);
          return (
            <g>
              <polygon
                id={region.id}
                points={pointString}
                fill={finalColor}
                fillOpacity="0.2"
                stroke={finalColor}
                strokeWidth="2"
              />
              {debug_labelRegions ? (
                <text
                  x={weightedCenter[0]}
                  y={weightedCenter[1]}
                  textAnchor="middle"
                  fill={finalColor}
                >
                  {region.id}
                </text>
              ) : null}
            </g>
          );
        }
      default:
        return null;
      // return <text x="10" y={20 + Math.random() * 40} fill="cyan">could not draw shape {region.id}</text>;
    }
  }

  return (
    <div style={{}}>
      {showDebug ? (
        <div
          className="main-column"
          style={{
            opacity: "70%",
            backgroundColor: "#DDEEFF",
            padding: "6px",
            borderRadius: "4px",
            margin: "auto",
          }}
        >
          <h5 style={{ fontSize: "1.5rem" }}>Debug Options</h5>
          <div>
            <label>Show All Regions </label>
            <Switch
              checked={debug_showAllRegions}
              onChange={(e, v) => setShowAllRegions(v)}
            />
          </div>
          <div>
            <label>Show Region IDs </label>
            <Switch
              checked={debug_labelRegions}
              onChange={(e, v) => setLabelAllRegions(v)}
            />
          </div>
          <div>
            <label>Show Clickable </label>
            <Switch
              checked={debug_svgBackground}
              onChange={(e, v) => setUseSVGBackground(v)}
            />
          </div>
          <div>
            <label>Draw Relation Lines </label>
            <Switch
              checked={drawConnectiveLines}
              onChange={(e, v) => setDrawConnectiveLines(v)}
            />
          </div>
          <div>
            <label>Custom Region Def</label>
            <Switch
              checked={debug_useCustomRegion}
              onChange={(e, v) => enableCustomRegion(v)}
            />
            {showCustomBox ? (
              <FaCompressArrowsAlt onClick={() => setShowCustomBox(false)} />
            ) : (
              <FaExpandArrowsAlt onClick={() => setShowCustomBox(true)} />
            )}
            {showCustomBox ? (
              <>
                <br />
                <textarea
                  rows="8"
                  style={{ width: "90%", marginLeft: "5%" }}
                  value={regionDefText}
                  onChange={applyRegionText}
                />
              </>
            ) : null}
          </div>
          <div className="form-inline">
            <label>Click Resolution Method: </label>
            <select
              className="ml-4 form-control"
              onChange={(e) => setResolutionMethod(e.target.value)}
            >
              {Object.values(ResolutionMethod).map((method) => (
                <option key={method}>{method}</option>
              ))}
            </select>
          </div>
          <div>
            <span className="btn btn-primary" onClick={resetData}>
              Reset Active
            </span>
            <label className="ml-4">Create Shape: </label>
            <span
              className={`mx-1 btn btn-warning ${
                buildMode ? "disabled" : ""
              } btn-sm`}
              onClick={() => setBuildMode(RegionShape.Rectangle)}
            >
              Rectangle
            </span>
            <span
              className={`mx-1 btn btn-warning ${
                buildMode ? "disabled" : ""
              } btn-sm`}
              onClick={() => setBuildMode(RegionShape.Circle)}
            >
              Circle
            </span>
            {buildMode === RegionShape.Polygon ? (
              <span
                className="mx-1 btn btn-success btn-sm"
                onClick={() => closeBuildPolygon()}
              >
                Finish Poly
              </span>
            ) : (
              <>
                <span
                  className="mx-1 btn btn-warning btn-sm"
                  onClick={() => {
                    setBuildMode(RegionShape.Polygon);
                    setBuildModeCubic(false);
                  }}
                >
                  Polygon
                </span>
                <span
                  className="mx-1 btn btn-warning btn-sm"
                  onClick={() => {
                    setBuildMode(RegionShape.Polygon);
                    setBuildModeCubic(true);
                  }}
                >
                  Smooth Polygon
                </span>
              </>
            )}
            <span
              className={`mx-1 btn btn-danger ${
                !buildMode ? "disabled" : ""
              } btn-sm`}
              onClick={() => cancelBuild()}
            >
              Cancel
            </span>
            <em className="ml-4">Current: {buildMode || "none"}</em>
            <div>{buildModeOutput ? <pre>{buildModeOutput}</pre> : null}</div>
          </div>
          <hr />
          <p style={{ minHeight: "100px" }}>
            <strong>Selected Count: </strong>{" "}
            <span className="badge badge-light mr-4">
              {activeRegions.length}
            </span>{" "}
            <strong>Active List: </strong> [{activeRegions.join("; ")}]
            <br />
            <strong>Last Click:</strong>{" "}
            {lastClick ? (
              <>
                {`[${lastClick.relativeX}px, ${
                  lastClick.relativeY
                }px] ≅ [${Math.round(lastClick.ratioX * 100)}%, ${Math.round(
                  lastClick.ratioY * 100
                )}%]`}
                {lastClick.actionsTaken.map((action) => (
                  <span
                    className={`ml-1 badge ${
                      action.startsWith("add")
                        ? "badge-success"
                        : "badge-warning"
                    }`}
                  >
                    {action}
                  </span>
                ))}
                <ul>
                  {lastClick.regions.map((r) => (
                    <li>
                      <em>{r.id}</em> clicked with weight {r.weight}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              "no click data"
            )}
          </p>
        </div>
      ) : null}
      <div
        className="main-column"
        style={{ margin: "-10px auto 0px auto", paddingTop: 0 }}
      >
        {showTrackingInfo ? <><p>
          <small>
            <span class="badge badge-success mr-2">answer:</span>
            {safeStringify(activeRegions)}
          </small>
        </p>
        {/* <p><small>{safeStringify(lastSelectedFrom)}</small></p> */}
        <small>
          <span class="badge badge-dark mr-3">views:</span>
          {Object.entries(VIEWS).map(([viewName, viewData], i) => (
            <>
              {i === 0 ? "" : " • "}
              <a
                onClick={() => setViewId(viewName)}
                style={{
                  fontWeight: viewName === viewId ? "bold" : "",
                  textDecoration: mostRecentVisitables.includes(viewName)
                    ? "dotted underline"
                    : "",
                }}
              >
                {viewName}
              </a>
            </>
          ))}
          {viewId in VIEWS ? null : (
            <>
              {" "}
              •{" "}
              <span
                className="text-danger"
                title="This view ID was not found in the configuration."
              >
                {viewId} <i className="fa fa-puzzle-piece" />
              </span>
            </>
          )}
        </small>
        </> : null}
        <div
          className="m-1"
          style={{
            position: "relative",
            width: "100%",
            height: `${Math.max(totalWidth, regionDef.length * 50)}px`,
            border: "",
          }}
        >
          <svg
            ref={svgRef}
            width={totalWidth}
            height={Math.max(totalWidth, regionDef.length * 50)}
            style={{
              position: "absolute",
              left: "0px",
              top: "0px",
              background: debug_svgBackground
                ? "rgba(100, 150, 250, 0.2)"
                : "transparent",
            }}
          >
            {/* <clipPath id="cut">
            <rect x="100" y="50" width="450" height="580" />
          </clipPath> */}
            <g id="highlight_base" /* clip-path="url(#cut)" */>
              {/* <BodyImage width={totalWidth} height={totalWidth} onClick={handleClick}/> */}
              {view.imgUrl ? (
                USE_DYNAMIC_SVG_EMBED ?
                <DynamicSVG
                  prefix={"History/AreaSelector/"}
                  src={view.imgUrl}
                  id="highlight_base"
                  height={totalWidth}
                  width={totalWidth}
                  onClick={handleClick}
                  style={{pointerEvents: "all"}}
                /> 
                :
                <image
                  id="highlight_base"
                  href={view.imgUrl}
                  height={totalWidth}
                  width={totalWidth}
                  onClick={handleClick}
                  pointerEvents={"all"}
                />
              ) : (
                <g>
                  <rect
                    x="0"
                    y="0"
                    width={totalWidth}
                    height={totalWidth}
                    fill="lavender"
                  />
                  <text
                    x={rightPanelOffset / 2}
                    y="30"
                    textAnchor="middle"
                    fill="red"
                  >
                    {view?.notFound
                      ? `View '${viewId}' not found!`
                      : `View '${viewId}' has no imgUrl!`}
                  </text>
                </g>
              )}
            </g>
            <g pointerEvents={"none"}>
              {debug_showAllRegions
                ? regionDef.map((r) => <HighlightedRegion region={r} />)
                : HIGHLIGHT_BY_SVG_NODES
                ? null
                : activeChoicesInCurrentView.map((r) => (
                    <HighlightedRegion region={r} color="green" />
                  ))}
              {debug_showAllRegions
                ? regionDef
                    .filter((r) => !!r.clickShape)
                    .map((r) => (
                      <HighlightedRegion
                        region={r.clickShape}
                        color="magenta"
                      />
                    ))
                : null}
              {buildModePoints.length > 0 ? (
                <circle
                  cx={buildModePoints[0][0]}
                  cy={buildModePoints[0][1]}
                  r={3}
                  fill="magenta"
                />
              ) : null}
              {buildMode === RegionShape.Polygon &&
              !buildModeCubic &&
              buildModePoints.length > 1 ? (
                <g>
                  <polyline
                    points={buildModePoints
                      .map((pair) => pair.join(","))
                      .join(" ")}
                    stroke="magenta"
                    fill="none"
                  />
                  <polyline
                    points={`${
                      buildModePoints[buildModePoints.length - 1][0]
                    },${buildModePoints[buildModePoints.length - 1][1]} ${
                      buildModePoints[0][0]
                    },${buildModePoints[0][1]}`}
                    stroke="magenta"
                    strokeDasharray={"3"}
                    fill="none"
                  />
                </g>
              ) : null}
              {buildMode === RegionShape.Polygon &&
              buildModeCubic &&
              buildModePoints.length > 1 ? (
                <g>
                  <path
                    d={
                      `M ${buildModePoints[0][0]} ${buildModePoints[0][1]} ` +
                      buildModePoints
                        .slice(1)
                        .map(
                          (pair, i) =>
                            `${i % 3 === 0 ? "C " : ""}${pair.join(" ")}${
                              i % 3 < 2 ? "," : ""
                            }`
                        )
                        .join(" ")
                    }
                    stroke="magenta"
                    fill="none"
                  />
                </g>
              ) : null}
            </g>

            {view?.type === "bottom cards" ? (
              <g
                id="responses-bottom"
                transform={`translate(0,${totalWidth - panelItemHeight})`}
              >
                <foreignObject width={totalWidth} height={panelItemHeight}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      gap: 20,
                    }}
                  >
                    {view.choices?.map((c) => (
                      <div
                        className={`card ${c.disabled ? "card-dark" : ""} ${
                          activeRegions.includes(c.value) ? "selected" : ""
                        }`}
                        key={c.value}
                        style={{ flexGrow: 1, textAlign: "center" }}
                      >
                        <div
                          className={`card-body`}
                          style={{ padding: "10px 20px" }}
                          onClick={() => toggleRegionDirectly(c.value)}
                        >
                          {c.label || c.value}
                        </div>
                      </div>
                    ))}
                    {view.canSelectEntire !== false ? (
                      <div
                        className={`card ${
                          activeRegions.includes(VALUES_FOR_VIEW[viewId])
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div
                          className={`card-body`}
                          style={{ padding: "10px 20px" }}
                          onClick={() =>
                            toggleRegionDirectly(VALUES_FOR_VIEW[viewId], true)
                          }
                        >
                          Entire {capitalizeByWord(viewId)}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </foreignObject>
              </g>
            ) : (
              <g id="responses" transform={`translate(${rightPanelOffset} ,0)`}>
                <foreignObject
                  width={totalWidth - rightPanelOffset}
                  height={totalPanelHeight}
                >
                  <div
                    className=""
                    style={{ maxHeight: "", overflowY: "default" }}
                  >
                    {view.choices?.map((c) => (
                      <div
                        className={`card ${c.disabled ? "card-dark" : ""} ${
                          activeRegions.includes(c.value) ? "selected" : ""
                        }`}
                        key={c.value}
                      >
                        <div
                          className={`card-body`}
                          style={{ padding: "10px 20px" }}
                          onClick={() => toggleRegionDirectly(c.value)}
                        >
                          {c.label || c.value}
                        </div>
                      </div>
                    ))}
                    {view.canSelectEntire !== false ? (
                      <div
                        className={`card ${
                          activeRegions.includes(VALUES_FOR_VIEW[viewId])
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div
                          className={`card-body`}
                          style={{ padding: "10px 20px" }}
                          onClick={() =>
                            toggleRegionDirectly(VALUES_FOR_VIEW[viewId], true)
                          }
                        >
                          Entire {capitalizeByWord(viewId)}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </foreignObject>
              </g>
            )}
            <g
              id="advance"
              transform={`translate(${totalWidth - 100}, ${Math.max(
                totalWidth - 100,
                totalPanelHeight
              )})`}
            >
              <foreignObject width="100" height="100">
                <div
                  className={`chrome-btn chrome-btn-lg centered-btn float-bottom-right`}
                  /* "1px solid rgba(0, 0, 0, 0.1)" */
                  style={{
                    pointerEvents: "fill",
                    transition: "opacity 1s linear, bottom 1s linear",
                    // opacity: nextButtonSettings.visible ? 1 : 0,
                  }}
                  onClick={() => goToNextView()}
                  // onMouseDown={waitForLongPress}
                  // onMouseUp={cancelLongPress}
                  // onContextMenu={handleMobileContextMenu}
                >
                  <img
                    src="/images/patient_redesign/arrow-right.svg"
                    style={{ filter: "invert()" }}
                  />
                </div>
              </foreignObject>
            </g>
            <g
              id="retreat"
              transform={`translate(0, ${Math.max(
                totalWidth - 100,
                totalPanelHeight
              )})`}
            >
              <foreignObject width="100" height="100">
                <div
                  className={`chrome-btn chrome-btn-lg centered-btn`}
                  style={{ margin: "20px" }}
                  onClick={() => goToPreviousView()}
                  // onMouseDown={waitForLongPress}
                  // onMouseUp={cancelLongPress}
                  // onContextMenu={handleMobileContextMenu}
                >
                  <img src="/images/patient_redesign/chevron.svg" />
                </div>
              </foreignObject>
            </g>

            {view?.type !== "bottom cards" ? (
              <g>
                {drawConnectiveLines
                  ? activeChoicesInCurrentView
                      .filter((r) => r.lineAnchor)
                      .map((r) => <LinkLineOf region={r} key={r.id} />)
                  : null}
              </g>
            ) : null}
          </svg>
          <em>{buildModePoints.map((pair) => pair.join(",")).join(" ")}</em>
        </div>
        {/* <h5 style={{fontSize: "1.5rem"}}>Answers Selected</h5> */}
        {/* <ul className="list">
        {activeRegions.length > 0 ? 
        activeRegions.map(id => <li key={id}><em>{id}:</em> {regionDef.find(r => r.id === id)?.value}</li>)
        : <em>No regions selected</em>}
      </ul> */}
      </div>
    </div>
  );
}

export default AreaSelector;

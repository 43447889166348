import { useMemo, useState } from "react";
import { loopIndexedAnswerId } from "../../RedesignPrototypes";
import SingleQuestion from "../Partials/SingleQuestion";
import { assignOnly, safeStringify, shortOrdinal } from "../../../../utils";
import { EMPTY_ANSWER } from "../constants_to_move";


export const QuestionPage = ({questions, answers, questionnaire, activeQuestionId, questionHidden, hiddenChoices, loopId, loopIndex, openModal, acceptAnswer, completeQuestion, navigateBack, ...props}) => {
  const [manuallyShown, setManuallyShown] = useState([]);
  const questionHiddenLocal = useMemo(() => {
    let hiddenMap = {};
    questions.forEach(qID => {
      if (questionHidden[qID] && !manuallyShown.includes(qID)) {
        hiddenMap[qID] = true;
      }
    });
    return hiddenMap;
  }, [questionHidden, manuallyShown])

  return (     <div className="" style={{height: "100vh", width: "100%", overflowY: "hidden", paddingBottom: "33dvh"}}>
    {questions?.map((qID, i) => 
      <div
        key={qID}
        style={{width: "100%", height: "100vh"}}>
        {questionHiddenLocal[qID] ? 
          <div className="viewer" style={{height: "100vh", justifyContent: "center"}}>
          <h3 className="text-center text-warning">
            "{qID}" hidden because {questionHiddenLocal[qID]}
            <button className="btn btn-sm btn-primary" onClick={e => setManuallyShown([...manuallyShown, qID])}>Force Display</button>
          </h3>
          <pre className="p-2 mb-0" style={{whiteSpace: "pre-wrap", background: "#E0E0FF", borderRadius: "8px"}}>{safeStringify(assignOnly(questionnaire.questions[qID], {id: qID}, ["displayWhen", "skipWhen"]))}</pre>
        </div>
        :
        <SingleQuestion
          key={loopIndexedAnswerId(qID, loopIndex)}
          page={props}
          index={i}
          isActive={activeQuestionId === qID}
          question={questionnaire.questions[qID]}
          textOverride={loopId ? {text: questionnaire.questions[qID].text.replace("<multiplex_ordinal>", shortOrdinal(loopIndex+1))} : null}
          answer={answers[loopIndexedAnswerId(qID, loopIndex)] ?? EMPTY_ANSWER}
          answerId={loopIndexedAnswerId(qID, loopIndex)}
          submitAnswerFor={acceptAnswer}
          complete={completeQuestion}
          goBack={navigateBack}
          // containerRef={questionRefs[i]}
          hiddenChoices={hiddenChoices[qID]}
          showTrackingInfo={false}
          keywordAction={(label, content) => openModal({type: "markdown", content})}
          displayInfoModal={(choice) => openModal({type: "markdown", content: choice.moreInfo, imgUrl: choice.imgUrl, title: choice.label ?? choice.value})}
          contextualResponse={questionnaire.questions[qID].contextualResponse ? answers[questionnaire.questions[qID].contextualResponse] : null}/>
          }
      </div>
    )}
  </div>);
}
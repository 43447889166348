import React, { useEffect } from "react";
import QuestionResponse from "../Partials/QuestionResponse";
import QuestionPrompt from "../Partials/QuestionPrompt";
import { useChangeDebugging } from "../../../../utils/hooks";
import { safeStringify } from "../../../../utils";

export default function SingleQuestion ({page, question, answer, answerId, isActive, submitAnswerFor, complete, goBack, containerRef, keywordAction, displayInfoModal, hiddenChoices, textOverride, topPadding = true, contextualResponse, showTrackingInfo, containerStyles}) {
  // useChangeDebugging({page, question, answer, answerId, isActive, submitAnswerFor, complete, containerRef, keywordAction}, `<InPageQuestion ${answerId}>`)
  useChangeDebugging({contextualResponse});

  function updateAnswer(newAnswer) {
    submitAnswerFor(newAnswer, answerId ?? question.id);
  }

  if (question) {
    return (
      <div
        /* note that this is normally 100vh tall but gets overridden when inside a modal, see CSS */
        className={`viewer question-with-inner-scroll ${(topPadding && (question.type != "anatomical area")) ? "pad-for-header" : null}`}
        style={{opacity: isActive ? 1.0 : 0.9, transition: "opacity 0.5s linear", ...containerStyles}} 
        // ref={containerRef}
        >
        {/* <tt className="debug-only text-danger">{`${answerId}`}</tt> */}
        <QuestionPrompt
          text={textOverride?.text ?? question.text}
          subtext={textOverride?.subtext ?? question.subtext}
          id={question.id}
          keywordList={question.keywordList}
          keywordAction={keywordAction}
          contextualResponse={contextualResponse} />
        <div className="question-scroller" style={{}}>
          <QuestionResponse
            key={`input:${answerId}`}
            questionDefinition={question}
            answer={answer}
            complete={complete}
            goBack={goBack}
            updateAnswer={updateAnswer}
            hiddenChoices={hiddenChoices}
            showTrackingInfo={showTrackingInfo}
            displayInfoModal={displayInfoModal}
            />
        </div>
      </div>
    );
  } else {
    return <div className="viewer pad-for-header"><em className="text-muted">Question {answerId} not found.</em></div>;
  }
}


// export default class InPageQuestion extends React.Component {
//   // useEffect((i => {
//   //   console.log({this.props.answer});
//   // }), [this.props.answer])

//   updateAnswer(newAnswer) {
//     this.props.submitAnswerFor(newAnswer, this.props.question.id);
//   }

//   render () {
//       return (
//       <div
//         className="viewer"
//         style={{minHeight: "100%", paddingTop: "128px", opacity: this.props.isActive ? 1.0 : 0.9, transition: "opacity 0.5s linear"}} 
//         // ref={containerRef}
//         >
//         {/* <Chrome /> */}
//         <QuestionText text={this.props.question.text} subtext={this.props.question.subtext} id={this.props.question.id} keywordList={this.props.question.keywordList} keywordAction={this.props.keywordAction} />
//         <QuestionInput
//           questionDefinition={this.props.question}
//           answer={this.props.answer}
//           updateAnswer={this.props.updateAnswer}
//         />
//       </div>
//     );
//     }
// }
